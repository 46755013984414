import React, { useEffect, useState, useRef, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CustomToast } from '../../../components/Toast/CustomToast';
import useDebounce from '../../../hooks/useDebounce';
import { axiosInstance, UserAuthentication, Centre } from '../../../services/api';
import { Pagination } from 'react-bootstrap';
import { AuthContext } from '../../../contexts/AuthContext';
const Utilisateurs = () => {
    const { authData } = useContext(AuthContext);

    const [utilisateurs, setUtilisateurs] = useState([]);
    const [centres, setCentres] = useState([]);
    const [ajouterUtilisateur, setAjouterUtilisateur] = useState(false);
    const [modifierUtilisateur, setModifierUtilisateur] = useState(false);
    const [filtredUtilisateurs, setFiltredUtilisateurs] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [idUtilisateurDelete, setIdUtilisateurDelete] = useState('');
    const [search, setSearch] = useState('');
    const debouncedSearchText = useDebounce(search, 300);

    const formik1 = useFormik({
        initialValues: {
            statut: 'actif',
            nom: '',
            prenom: '',
            email: '',
            numTel: '',
            idCentre: '',
            username: '',
            password: '',
            adresse: '',
            role: '',
        },
        validationSchema: Yup.object({
            nom: Yup.string()
                .required("Champ obligatoire"),
            prenom: Yup.string()
                .required("Champ obligatoire"),
            statut: Yup.string()
                .required("Champ obligatoire"),
            idCentre: Yup.string()
                .required("Champ obligatoire"),
            email: Yup.string()
                .email("Le champ doit contenir un e-mail valide")
                .max(255, "Le champ doit contenir au maximum 255 caractères"),
            username: Yup.string().test('username-validation', 'Invalid username', function (value) {
                if (!value) {
                    return this.createError({ message: 'Champ obligatoire' });
                }
                if (value.length < 8 || value.length > 255) {
                    return this.createError({ message: 'Le champ doit contenir entre 8 et 255 caractères' });
                }

                return true;
            }),
            password: Yup.string().test('password-validation', 'Invalid password', function (value) {
                if (!value) {
                    return this.createError({ message: 'Champ obligatoire' });
                }
                if (value.length < 8 || value.length > 16) {
                    return this.createError({ message: 'Le champ doit contenir entre 8 et 16 caractères' });
                }

                return true;
            }),
            role: Yup.string().test('role-validation', 'Invalid role', function (value) {
                if (!value) {
                    return this.createError({ message: 'Champ obligatoire' });
                }

                return true;
            }),
        }),
        onSubmit: async (values) => {
            try {
                //console.log(values);
                const postData = {
                    statut: values.statut,
                    nom: values.nom,
                    prenom: values.prenom,
                    email: values.email,
                    numTel: values.numTel,
                    idCentre: values.idCentre,
                    username: values.username,
                    password: values.password,
                    adresse: values.adresse,
                    role: values.role,
                    idUtilisateurCreation: authData.idUtilisateur,
                    idUtilisateurCRUD: authData.idUtilisateur,
                };
                const response = await axiosInstance.post(`${UserAuthentication}/create`, postData);
                CustomToast("Le compte a été créé avec succès", 'success');
                setAjouterUtilisateur(false);
                getUtilisateurs(1, pageSize);
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Another user exists with the same username and email") {
                    CustomToast("Another user exists with the same username and email", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Another user exists with the same username") {
                    CustomToast("Another user exists with the same username", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Another user exists with the same email") {
                    CustomToast("Another user exists with the same email", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    const handleResetPassword = () => {
        formik2.setFieldValue('resetPassword', !formik2.values.resetPassword);
    };

    const formik2 = useFormik({
        initialValues: {
            idUtilisateurUpdate: '',
            statutUpdate: '',
            nomUpdate: '',
            prenomUpdate: '',
            emailUpdate: '',
            numTelUpdate: '',
            idCentreUpdate: '',
            usernameUpdate: '',
            newPassword: '',
            resetPassword: false,
            adresseUpdate: '',
            roleUpdate: '',
        },
        validationSchema: Yup.object({
            nomUpdate: Yup.string()
                .required("Champ obligatoire"),
            prenomUpdate: Yup.string()
                .required("Champ obligatoire"),
            idCentreUpdate: Yup.string()
                .required("Champ obligatoire"),
            emailUpdate: Yup.string()
                .email("Le champ doit contenir un e-mail valide")
                .max(255, "Le champ doit contenir au maximum 255 caractères"),
            resetPassword: Yup.bool(),
            usernameUpdate: Yup.string().test('username-validation', 'Invalid username', function (value) {
                if (!value) {
                    return this.createError({ message: 'Champ obligatoire' });
                }
                if (value.length < 8 || value.length > 255) {
                    return this.createError({ message: 'Le champ doit contenir entre 8 et 255 caractères' });
                }

                return true;
            }),
            newPassword: Yup.string().test('password-validation', 'Invalid password', function (value) {
                const resetPassword = this.resolve(Yup.ref('resetPassword'));

                if (resetPassword) {
                    if (!value) {
                        return this.createError({ message: 'Champ obligatoire' });
                    }
                    if (value.length < 8 || value.length > 16) {
                        return this.createError({ message: 'Le champ doit contenir entre 8 et 16 caractères' });
                    }
                }

                return true;
            }),
            roleUpdate: Yup.string().test('role-validation', 'Invalid role', function (value) {
                if (!value) {
                    return this.createError({ message: 'Champ obligatoire' });
                }

                return true;
            }),
        }),
        onSubmit: async (values) => {
            try {
                //console.log(values);

                const postData = {
                    idUtilisateur: values.idUtilisateurUpdate,
                    statut: values.statutUpdate,
                    nom: values.nomUpdate,
                    prenom: values.prenomUpdate,
                    email: values.emailUpdate,
                    numTel: values.numTelUpdate,
                    idCentre: values.idCentreUpdate,
                    username: values.usernameUpdate,
                    newPassword: values.newPassword,
                    adresse: values.adresseUpdate,
                    role: values.roleUpdate,
                    resetPassword: values.resetPassword,
                    idUtilisateurCreation: authData.idUtilisateur,
                    idUtilisateurCRUD: authData.idUtilisateur,
                };
                const response = await axiosInstance.put(`${UserAuthentication}`, postData);
                if (authData.idUtilisateur === values.idUtilisateurUpdate && response.status === 200) {
                    // const request = {
                    //     accessToken: authData.token,
                    //     refreshToken: authData.tokenRefresh,
                    // };
                    const request = {
                        refreshToken: authData.idUtilisateur,
                    };
                    const responseAuth = await axiosInstance.post(`${UserAuthentication}/Refresh`, request);
                    let authInfo = JSON.stringify(responseAuth.data);
                    localStorage.setItem('authData', authInfo);
                }
                CustomToast("Le compte a été modifié avec succès", 'success');
                setModifierUtilisateur(false);
                getUtilisateurs(1, pageSize);
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Another user exists with the same username and email") {
                    CustomToast("Another user exists with the same username and email", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Another user exists with the same username") {
                    CustomToast("Another user exists with the same username", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Another user exists with the same email") {
                    CustomToast("Another user exists with the same email", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    //Table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredUtilisateurs].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredUtilisateurs(sortedTable);
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
    };

    const getStartIndex = () => {
        return (pageNumber - 1) * pageSize + 1;
    };

    const getEndIndex = () => {
        const endIndex = pageNumber * pageSize;
        return endIndex > totalItems ? totalItems : endIndex;
    };

    const AjouterUtilisateur = (value) => {
        try {
            setAjouterUtilisateur(value);
            formik1.resetForm();
            getCentres();
        }
        catch (error) {
            console.log(error);
        }
    }

    const ModifierUtilisateur = (value, idUtilisateur, statut, nom, prenom, email, numTel, idCentre, username, adresse, role) => {
        try {
            setModifierUtilisateur(value);
            formik2.resetForm();
            if (value) {
                formik2.setValues((prevValues) => ({
                    ...prevValues,
                    idUtilisateurUpdate: idUtilisateur || '',
                    statutUpdate: statut || 'actif',
                    nomUpdate: nom || '',
                    prenomUpdate: prenom || '',
                    emailUpdate: email || '',
                    numTelUpdate: numTel || '',
                    idCentreUpdate: idCentre || '',
                    usernameUpdate: username || '',
                    resetPassword: false,
                    adresseUpdate: adresse || '',
                    roleUpdate: role || '',
                }));
                getCentres();
            }

        }
        catch (error) {
            console.log(error);
        }
    }

    const SupprimerUtilisateur = async (idUtilisateur) => {
        try {
            await axiosInstance.delete(`${UserAuthentication}/${idUtilisateur}`);
            CustomToast("Le compte a été supprimé avec succès", 'success');
            getUtilisateurs(1, pageSize);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }

            console.log(error);
        }
    }

    const getCentres = async () => {
        try {
            const response = await axiosInstance.get(`${Centre}`);
            const resp = await response.data;
            setCentres(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getUtilisateurs = async (page, newPerPage) => {
        try {
            setLoading(true);

            const queryParams = new URLSearchParams({
                pageNumber: page,
                pageSize: newPerPage
            });

            if (debouncedSearchText != null) {
                queryParams.append("text", debouncedSearchText);
            }

            const response = await axiosInstance.get(`${UserAuthentication}/GetUtilisateurs?${queryParams}`);
            const resp = await response.data;
            setUtilisateurs(resp.data);
            setFiltredUtilisateurs(resp.data);
            setPageNumber(resp.pageNumber);
            setPageSize(resp.pageSize);
            setTotalItems(resp.totalItems);
            setTotalPages(resp.totalPages);
            setLoading(false);
        }
        catch (err) {
            console.log(err);
            setLoading(false);
        }
    }

    // useEffect(() => {
    //     //Load Data

    //     getUtilisateurs(pageNumber, pageSize);
    //     return () => {
    //         // Perform any necessary cleanup (e.g., cancel pending requests)
    //     };
    // }, [authData, pageNumber, pageSize]);

    // useEffect(() => {
    //     //Load Data

    //     getUtilisateurs(1, pageSize);
    //     return () => {
    //         // Perform any necessary cleanup (e.g., cancel pending requests)
    //     };
    // }, [debouncedSearchText]);

    useEffect(() => {
        //Load Data
        getUtilisateurs(debouncedSearchText ? 1 : pageNumber, pageSize);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, debouncedSearchText, pageNumber, pageSize]);

    return (
        <>
            <div className='row mt-1 mb-1'>
                <div className='col-xs-12 col-md-12'>
                    <div className="card">
                        <div className="card-body">
                            <div className='row justify-content-center align-items-center'>
                                {authData?.role !== "visiteur" && <div className='col-xs-2 col-md-2'>
                                    <Link to="/utilisateurs" className="btn btn-light form-control" replace>
                                        <i className="fa fa-users"> Utilisateurs</i>
                                    </Link>
                                </div>}
                                <div className='col-xs-2 col-md-2'>
                                    <Link to="/centres" className="btn btn-light form-control" replace>
                                        <i className="fa fa-home"> Centres</i>
                                    </Link>
                                </div>
                                <div className='col-xs-2 col-md-2'>
                                    <Link to="/zones" className="btn btn-light form-control" replace>
                                        <i className="fa fa-bullseye"> Zones</i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row mt-1 mb-1'>
                <div className='col-xs-12 col-md-12'>
                    <div className="card">
                        <div className="card-header">Utilisateurs</div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-xs-2 col-md-2 mt-5 mb-4 text-gred offset-xs-2 offset-md-2">
                                    <div className="search">
                                        <form className="form-inline">
                                            <input
                                                className="form-control mr-sm-2"
                                                type="search" placeholder="Search"
                                                aria-label="Rechercher"
                                                value={search}
                                                onChange={(e) => setSearch(e.target.value)}
                                            />
                                        </form>
                                    </div>
                                </div>
                                <div className="col-xs-1 col-md-1 offset-xs-5 offset-md-5 mt-5 mb-4 text-gred">
                                    <button className="btn btn-success form-control" type="button" onClick={() => AjouterUtilisateur(true)}>
                                        <i className="fa fa-plus"></i>
                                    </button>
                                </div>
                            </div>
                            <div className='row justify-content-center align-items-center g-2'>
                                <div className="col-xs-12 col-md-8">
                                    <div className="table-responsive">
                                        <table className="table table-hover table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Nom & prénom</th>
                                                    <th>Username</th>
                                                    <th>Email</th>
                                                    <th>Rôle</th>
                                                    <th>Centre</th>
                                                    <th colSpan={2} className='text-center'>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {utilisateurs.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            {item.nom + ' ' + item.prenom}
                                                        </td>
                                                        <td>
                                                            {item.username}
                                                        </td>
                                                        <td>
                                                            {item.email}
                                                        </td>
                                                        <td>
                                                            {item.role === "admin" ? "Administrateur" : item.role === "super admin" ? "Super administrateur" : item.role === "operateur" ? "Opérateur" : "Visiteur"}
                                                        </td>
                                                        <td>
                                                            {item.libelleCentre}
                                                        </td>
                                                        <td className='text-center'>
                                                            <Link to="#" style={{ textDecoration: "none" }} onClick={() => ModifierUtilisateur(true, item.idUtilisateur, item.statut, item.nom, item.prenom, item.email, item.numTel, item.idCentre, item.username, item.adresse, item.role)}>
                                                                <span className="fa fa-pencil text-warning fa-lg"></span>
                                                            </Link>
                                                        </td>
                                                        <td className='text-center'>
                                                            <Link to="#" style={{ textDecoration: "none" }} onClick={() => SupprimerUtilisateur(item.idUtilisateur)}>
                                                                <span className="fa fa-trash text-danger fa-lg"></span>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="d-flex justify-content-between mt-3">
                                        <div>
                                            {totalPages >= 1 && (
                                                <Pagination className="pagination-sm">
                                                    <Pagination.Prev
                                                        onClick={() => handlePageChange(pageNumber - 1)}
                                                        disabled={pageNumber === 1}
                                                    >
                                                        <span aria-hidden="true">&laquo;</span>
                                                    </Pagination.Prev>
                                                    {Array.from({ length: totalPages }).map((_, index) => (
                                                        <Pagination.Item
                                                            key={index}
                                                            active={index + 1 === pageNumber}
                                                            onClick={() => handlePageChange(index + 1)}
                                                        >
                                                            {index + 1}
                                                        </Pagination.Item>
                                                    ))}
                                                    <Pagination.Next
                                                        onClick={() => handlePageChange(pageNumber + 1)}
                                                        disabled={pageNumber === totalPages}
                                                    >
                                                        <span aria-hidden="true">&raquo;</span>
                                                    </Pagination.Next>
                                                </Pagination>
                                            )}
                                        </div>

                                        <div className="mb-3">
                                            <p>
                                                Affichage de {getStartIndex()} à {getEndIndex()} sur {totalItems} entrées
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {ajouterUtilisateur && <><div className="modal" id="ajouterUtilisateur" style={{ display: 'block' }} data-bs-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true" tabIndex={-1}>
                <form onSubmit={formik1.handleSubmit}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Ajouter utilisateur</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => setAjouterUtilisateur(false)}></button>
                            </div>

                            <div className="modal-body">
                                <div className='row mt-0 mb-0'>
                                    <div className="col">
                                        <label className="form-label">Nom<span className='text-danger fs-6'>*</span></label>
                                        <input
                                            type="text"
                                            name="nom"
                                            id="nom"
                                            className={`form-control ${formik1.touched.nom && formik1.errors?.nom ? 'border-danger' : ''}`}
                                            placeholder=""
                                            value={formik1.values.nom}
                                            onChange={formik1.handleChange}
                                        />
                                        {formik1.touched.nom && formik1.errors.nom ? (
                                            <small className="text-danger">{formik1.errors.nom}</small>
                                        ) : null}
                                    </div>
                                    <div className="col">
                                        <label className="form-label">Prénom<span className='text-danger fs-6'>*</span></label>
                                        <input
                                            type="text"
                                            name="prenom"
                                            id="prenom"
                                            className={`form-control ${formik1.touched.prenom && formik1.errors?.prenom ? 'border-danger' : ''}`}
                                            placeholder=""
                                            value={formik1.values.prenom}
                                            onChange={formik1.handleChange}
                                        />
                                        {formik1.touched.prenom && formik1.errors.prenom ? (
                                            <small className="text-danger">{formik1.errors.prenom}</small>
                                        ) : null}
                                    </div>
                                </div>
                                <div className='row mt-0 mb-0'>
                                    <div className="col">
                                        <label className="form-label">Centre<span className='text-danger fs-6'>*</span></label>
                                        <select
                                            id="idCentre"
                                            name="idCentre"
                                            className={`form-control ${formik1.touched.idCentre && formik1.errors?.idCentre ? 'border-danger' : ''}`}
                                            value={formik1.values.idCentre}
                                            onChange={formik1.handleChange}
                                        >
                                            <option value="">Veuillez sélectionner</option>
                                            {centres.map((centre) => (
                                                <option key={uuidv4()} value={centre.idCentre}>{centre.libelle}</option>
                                            ))}
                                        </select>
                                        {formik1.touched.idCentre && formik1.errors.idCentre ? (
                                            <small className="text-danger">{formik1.errors.idCentre}</small>
                                        ) : null}
                                    </div>
                                    <div className="col">
                                        <label className="form-label">N° téléphone</label>
                                        <input
                                            type="text"
                                            name="numTel"
                                            id="numTel"
                                            className={`form-control ${formik1.touched.numTel && formik1.errors?.numTel ? 'border-danger' : ''}`}
                                            placeholder=""
                                            value={formik1.values.numTel}
                                            onChange={formik1.handleChange}
                                        />
                                        {formik1.touched.numTel && formik1.errors.numTel ? (
                                            <small className="text-danger">{formik1.errors.numTel}</small>
                                        ) : null}
                                    </div>
                                </div>
                                <div className='row mt-0 mb-0'>
                                    <div className="col">
                                        <label className="form-label">Email</label>
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            className={`form-control ${formik1.touched.email && formik1.errors?.email ? 'border-danger' : ''}`}
                                            placeholder=""
                                            value={formik1.values.email}
                                            onChange={formik1.handleChange}
                                        />
                                        {formik1.touched.email && formik1.errors.email ? (
                                            <small className="text-danger">{formik1.errors.email}</small>
                                        ) : null}
                                    </div>
                                    <div className="col">
                                        <label className="form-label">Adresse</label>
                                        <input
                                            type="text"
                                            name="adresse"
                                            id="adresse"
                                            className={`form-control`}
                                            placeholder=""
                                            value={formik1.values.adresse}
                                            onChange={formik1.handleChange}
                                        />
                                    </div>
                                </div>

                                <div className='row mt-0 mb-0'>
                                    <div className="col">
                                        <label className="form-label">Username<span className='text-danger fs-6'>*</span></label>
                                        <input
                                            type="text"
                                            name="username"
                                            id="username"
                                            className={`form-control ${formik1.touched.username && formik1.errors?.username ? 'border-danger' : ''}`}
                                            placeholder=""
                                            value={formik1.values.username}
                                            onChange={formik1.handleChange}
                                        />
                                        {formik1.touched.username && formik1.errors.username ? (
                                            <small className="text-danger">{formik1.errors.username}</small>
                                        ) : null}
                                    </div>
                                    <div className="col">
                                        <label className="form-label">Mot de passe<span className='text-danger fs-6'>*</span></label>
                                        <input
                                            type="password"
                                            name="password"
                                            id="password"
                                            className={`form-control ${formik1.touched.password && formik1.errors?.password ? 'border-danger' : ''}`}
                                            placeholder=""
                                            value={formik1.values.password}
                                            onChange={formik1.handleChange}
                                        />
                                        {formik1.touched.password && formik1.errors.password ? (
                                            <small className="text-danger">{formik1.errors.password}</small>
                                        ) : null}
                                    </div>
                                    <div className="col">
                                        <label className="form-label">Rôle<span className='text-danger fs-6'>*</span></label>
                                        <select
                                            id="role"
                                            name="role"
                                            className={`form-control ${formik1.touched.role && formik1.errors?.role ? 'border-danger' : ''}`}
                                            value={formik1.values.role}
                                            onChange={formik1.handleChange}
                                        >
                                            <option value="" defaultValue={true}>Sélectionnez un rôle</option>
                                            {authData.role === "super admin" && <option value="super admin">Super administrateur</option>}
                                            <option value="admin">Administrateur</option>
                                            <option value="operateur">Opérateur</option>
                                            <option value="visiteur">Visiteur</option>
                                        </select>
                                        {formik1.touched.role && formik1.errors.role ? (
                                            <small className="text-danger">{formik1.errors.role}</small>
                                        ) : null}
                                    </div>
                                </div>
                                <div className='row mt-0 mb-0'>
                                    <div className="col">
                                        <label className="form-label">Statut</label>
                                        <select
                                            id="statut"
                                            name="statut"
                                            className={`form-control ${formik1.touched.statut && formik1.errors?.statut ? 'border-danger' : ''}`}
                                            value={formik1.values.statut}
                                            onChange={formik1.handleChange}
                                        >
                                            <option value="actif" defaultValue={true}>Actif</option>
                                            <option value="inactif">Inactif</option>
                                        </select>
                                        {formik1.touched.statut && formik1.errors.statut ? (
                                            <small className="text-danger">{formik1.errors.statut}</small>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => setAjouterUtilisateur(false)}>Annuler</button>
                                <button type="submit" className="btn btn-success" data-bs-dismiss="modal">Enregistrer</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
                <div className="modal-backdrop show"></div></>
            }
            {modifierUtilisateur && <><div className="modal" id="modifierUtilisateur" style={{ display: 'block' }} data-bs-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true" tabIndex={-1}>
                <form onSubmit={formik2.handleSubmit}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Modifier utilisateur</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => ModifierUtilisateur(false, '', '', '', '', '', '', '', '', '', '')}></button>
                            </div>

                            <div className="modal-body">
                                <div className='row mt-0 mb-0'>
                                    <div className="col">
                                        <label className="form-label">Nom<span className='text-danger fs-6'>*</span></label>
                                        <input
                                            type="text"
                                            name="nomUpdate"
                                            id="nomUpdate"
                                            className={`form-control ${formik2.touched.nomUpdate && formik2.errors?.nomUpdate ? 'border-danger' : ''}`}
                                            placeholder=""
                                            value={formik2.values.nomUpdate}
                                            onChange={formik2.handleChange}
                                        />
                                        {formik2.touched.nomUpdate && formik2.errors.nomUpdate ? (
                                            <small className="text-danger">{formik2.errors.nomUpdate}</small>
                                        ) : null}
                                    </div>
                                    <div className="col">
                                        <label className="form-label">Prénom<span className='text-danger fs-6'>*</span></label>
                                        <input
                                            type="text"
                                            name="prenomUpdate"
                                            id="prenomUpdate"
                                            className={`form-control ${formik2.touched.prenomUpdate && formik2.errors?.prenomUpdate ? 'border-danger' : ''}`}
                                            placeholder=""
                                            value={formik2.values.prenomUpdate}
                                            onChange={formik2.handleChange}
                                        />
                                        {formik2.touched.prenomUpdate && formik2.errors.prenomUpdate ? (
                                            <small className="text-danger">{formik2.errors.prenomUpdate}</small>
                                        ) : null}
                                    </div>
                                </div>
                                <div className='row mt-0 mb-0'>
                                    <div className="col">
                                        <label className="form-label">Centre<span className='text-danger fs-6'>*</span></label>
                                        <select
                                            id="idCentreUpdate"
                                            name="idCentreUpdate"
                                            className={`form-control ${formik2.touched.idCentreUpdate && formik2.errors?.idCentreUpdate ? 'border-danger' : ''}`}
                                            value={formik2.values.idCentreUpdate}
                                            onChange={formik2.handleChange}
                                        >
                                            <option value="">Veuillez sélectionner</option>
                                            {centres.map((centre) => (
                                                <option key={uuidv4()} value={centre.idCentre}>{centre.libelle}</option>
                                            ))}
                                        </select>
                                        {formik2.touched.idCentreUpdate && formik2.errors.idCentreUpdate ? (
                                            <small className="text-danger">{formik2.errors.idCentreUpdate}</small>
                                        ) : null}
                                    </div>
                                    <div className="col">
                                        <label className="form-label">N° téléphone</label>
                                        <input
                                            type="text"
                                            name="numTelUpdate"
                                            id="numTelUpdate"
                                            className={`form-control ${formik2.touched.numTelUpdate && formik2.errors?.numTelUpdate ? 'border-danger' : ''}`}
                                            placeholder=""
                                            value={formik2.values.numTelUpdate}
                                            onChange={formik2.handleChange}
                                        />
                                        {formik2.touched.numTelUpdate && formik2.errors.numTelUpdate ? (
                                            <small className="text-danger">{formik2.errors.numTelUpdate}</small>
                                        ) : null}
                                    </div>
                                </div>
                                <div className='row mt-0 mb-0'>
                                    <div className="col">
                                        <label className="form-label">Email</label>
                                        <input
                                            type="email"
                                            name="emailUpdate"
                                            id="emailUpdate"
                                            className={`form-control ${formik2.touched.emailUpdate && formik2.errors?.emailUpdate ? 'border-danger' : ''}`}
                                            placeholder=""
                                            value={formik2.values.emailUpdate}
                                            onChange={formik2.handleChange}
                                        />
                                        {formik2.touched.emailUpdate && formik2.errors.emailUpdate ? (
                                            <small className="text-danger">{formik2.errors.emailUpdate}</small>
                                        ) : null}
                                    </div>
                                    <div className="col">
                                        <label className="form-label">Adresse</label>
                                        <input
                                            type="text"
                                            name="adresseUpdate"
                                            id="adresseUpdate"
                                            className={`form-control`}
                                            placeholder=""
                                            value={formik2.values.adresseUpdate}
                                            onChange={formik2.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className='row mt-0 mb-0'>
                                    <div className="col">
                                        <label className="form-label">Username<span className='text-danger fs-6'>*</span></label>
                                        <input
                                            type="text"
                                            name="usernameUpdate"
                                            id="usernameUpdate"
                                            className={`form-control ${formik2.touched.usernameUpdate && formik2.errors?.usernameUpdate ? 'border-danger' : ''}`}
                                            placeholder=""
                                            value={formik2.values.usernameUpdate}
                                            onChange={formik2.handleChange}
                                        />
                                        {formik2.touched.usernameUpdate && formik2.errors.usernameUpdate ? (
                                            <small className="text-danger">{formik2.errors.usernameUpdate}</small>
                                        ) : null}
                                    </div>
                                    {(formik2.values.resetPassword) && <div className="col">
                                        <label className="form-label">Mot de passe<span className='text-danger fs-6'>*</span></label>
                                        <input
                                            type="password"
                                            name="newPassword"
                                            id="newPassword"
                                            className={`form-control ${formik2.touched.newPassword && formik2.errors?.newPassword ? 'border-danger' : ''}`}
                                            placeholder=""
                                            value={formik2.values.newPassword}
                                            onChange={formik2.handleChange}
                                        />
                                        {formik2.touched.newPassword && formik2.errors.newPassword ? (
                                            <small className="text-danger">{formik2.errors.newPassword}</small>
                                        ) : null}
                                    </div>}
                                    {(!formik2.values.resetPassword) && <div className="col">
                                        <Link to={`#`} style={{ fontSize: '1rem' }} onClick={() => handleResetPassword()}>
                                            Changer mot de passe
                                        </Link>
                                    </div>}
                                    <div className="col">
                                        <label className="form-label">Rôle<span className='text-danger fs-6'>*</span></label>
                                        <select
                                            id="roleUpdate"
                                            name="roleUpdate"
                                            className={`form-control ${formik2.touched.roleUpdate && formik2.errors?.roleUpdate ? 'border-danger' : ''}`}
                                            value={formik2.values.roleUpdate}
                                            onChange={formik2.handleChange}
                                        >
                                            <option value="" defaultValue={true}>Sélectionnez un rôle</option>
                                            {authData?.role === "super admin" && <option value="super admin">Super administrateur</option>}
                                            <option value="admin">Administrateur</option>
                                            <option value="operateur">Opérateur</option>
                                            <option value="visiteur">Visiteur</option>
                                        </select>
                                        {formik2.touched.roleUpdate && formik2.errors.roleUpdate ? (
                                            <small className="text-danger">{formik2.errors.roleUpdate}</small>
                                        ) : null}
                                    </div>
                                </div>
                                <div className='row mt-0 mb-0'>
                                    <div className="col">
                                        <label className="form-label">Statut</label>
                                        <select
                                            id="statutUpdate"
                                            name="statutUpdate"
                                            className={`form-control ${formik2.touched.statutUpdate && formik2.errors?.statutUpdate ? 'border-danger' : ''}`}
                                            value={formik2.values.statutUpdate}
                                            onChange={formik2.handleChange}
                                        >
                                            <option value="actif" defaultValue={true}>Actif</option>
                                            <option value="inactif">Inactif</option>
                                        </select>
                                        {formik2.touched.statutUpdate && formik2.errors.statutUpdate ? (
                                            <small className="text-danger">{formik2.errors.statutUpdate}</small>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => ModifierUtilisateur(false, '', '', '', '', '', '', '', '', '', '')}>Annuler</button>
                                <button type="submit" className="btn btn-success" data-bs-dismiss="modal">Enregistrer</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
                <div className="modal-backdrop show"></div></>
            }
        </>
    );
}

export default Utilisateurs;
