import React, { useEffect, useState, useRef, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CustomToast } from '../../../components/Toast/CustomToast';
import useDebounce from '../../../hooks/useDebounce';
import { axiosInstance, UserAuthentication, Centre, Zone } from '../../../services/api';
import { Pagination } from 'react-bootstrap';
import { AuthContext } from '../../../contexts/AuthContext';
import { format, formatDistance, formatRelative, subDays } from 'date-fns'
import { degrees, grayscale, PDFDocument, PDFFont, rgb, StandardFonts } from 'pdf-lib';
import qrCode from 'qrcode';
//import bwipjs from 'bwip-js';
import JsBarcode from 'jsbarcode';

const Zones = () => {
    const { authData } = useContext(AuthContext);
    const navigate = useNavigate();

    const [zones, setZones] = useState([]);
    const [centres, setCentres] = useState([]);
    const [boxs, setBoxs] = useState([]);
    const [etagers, setEtagers] = useState([]);
    const [ajouterZone, setAjouterZone] = useState(false);
    const [imprimerZone, setImprimerZone] = useState(false);
    const [supprimerZone, setSupprimerZone] = useState(false);
    // const [idZoneImprimer, setIdZoneImprimer] = useState("");
    // const [selectedZone, setSelectedZone] = useState("");
    const [filtredZones, setFiltredZones] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState('');
    const debouncedSearchText = useDebounce(search, 300);

    const formik1 = useFormik({
        initialValues: {
            idCentre: '',
            libelle: '',
            nbrBox: '',
            nbrEtageres: '',
            nbrBoites: '',
            zoneDediee: '',
            statutZone: 'inactif'
        },
        validationSchema: Yup.object({
            idCentre: Yup.string()
                .required("Champ obligatoire"),
            libelle: Yup.string()
                .required("Champ obligatoire"),
            nbrBox: Yup.number()
                .required('Champ obligatoire')
                .min(1, "Le champ ne peut pas être négatif"),
            nbrEtageres: Yup.number()
                .required('Champ obligatoire')
                .min(1, "Le champ ne peut pas être négatif"),
            nbrBoites: Yup.number()
                .required('Champ obligatoire')
                .min(1, "Le champ ne peut pas être négatif"),
        }),
        onSubmit: async (values) => {
            try {
                //console.log(values);
                const postData = {
                    idCentre: values.idCentre,
                    libelle: values.libelle,
                    nbrBox: values.nbrBox,
                    nbrEtageres: values.nbrEtageres,
                    nbrBoites: values.nbrBoites,
                    zoneDediee: values.zoneDediee,
                    statutZone: values.statutZone,
                    idUtilisateurCRUD: authData.idUtilisateur,
                };
                const response = await axiosInstance.post(`${Zone}`, postData);
                CustomToast("La zonne a été créée avec succès", 'success');
                setAjouterZone(false);
                //console.log(response.data.idZone)
                if(values.zoneDediee==="boutique"){
                    if (response?.status === 200) {
                        navigate(`/zoneboutique/${response.data.idZone}`);
                    }
                }else{
                    getZones(1, pageSize);
                }
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Zone exist") {
                    CustomToast("Zone existe", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    //Table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredZones].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredZones(sortedTable);
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
    };

    const getStartIndex = () => {
        return (pageNumber - 1) * pageSize + 1;
    };

    const getEndIndex = () => {
        const endIndex = pageNumber * pageSize;
        return endIndex > totalItems ? totalItems : endIndex;
    };

    const AjouterZone = (value) => {
        try {
            setAjouterZone(value);
            if (value) {
                formik1.resetForm();
                getCentres();
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const getZones = async (page, newPerPage) => {
        try {
            setLoading(true);

            const queryParams = new URLSearchParams({
                pageNumber: page,
                pageSize: newPerPage
            });

            if (debouncedSearchText != null) {
                queryParams.append("text", debouncedSearchText);
            }

            if (authData?.role != null && authData?.role !== "super admin") {
                queryParams.append("idCentre", authData.idCentre);
            }

            const response = await axiosInstance.get(`${Zone}/GetZones?${queryParams}`);
            const resp = await response.data;
            setZones(resp.data);
            setFiltredZones(resp.data);
            setPageNumber(resp.pageNumber);
            setPageSize(resp.pageSize);
            setTotalItems(resp.totalItems);
            setTotalPages(resp.totalPages);
            setLoading(false);
        }
        catch (err) {
            console.log(err);
            setLoading(false);
        }
    }

    const getCentres = async () => {
        try {
            const response = await axiosInstance.get(`${Centre}`);
            const resp = await response.data;
            setCentres(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    //Modifier une zone
    const [updateZone, setUpdateZone] = useState(false);
    const [idZoneUpdate, setIdZoneUpdate] = useState('');

    const formik2 = useFormik({
        initialValues: {
            libelleUpdate: '',
            nbrBoxUpdate: '',
            nbrEtageresUpdate: '',
            nbrBoitesUpdate: '',
            zoneDedieeUpdate: '',
            statutZoneUpdate: 'inactif'
        },
        validationSchema: Yup.object({
            libelleUpdate: Yup.string()
                .required("Champ obligatoire"),
            // nbrBoxUpdate: Yup.number()
            //     .required('Champ obligatoire')
            //     .min(1, "Le champ ne peut pas être négatif"),
            // nbrEtageresUpdate: Yup.number()
            //     .required('Champ obligatoire')
            //     .min(1, "Le champ ne peut pas être négatif"),
            // nbrBoitesUpdate: Yup.number()
            //     .required('Champ obligatoire')
            //     .min(1, "Le champ ne peut pas être négatif"),
            // statutZoneUpdate: Yup.string()
            //     .required("Champ obligatoire"),
        }),
        onSubmit: async (values) => {
            try {
                //console.log(values);
                const postData = {
                    idZone: idZoneUpdate,
                    libelle: values.libelleUpdate,
                    nbrBox: values.nbrBoxUpdate|'0',
                    nbrEtageres: values.nbrEtageresUpdate|'0',
                    nbrBoites: values.nbrBoitesUpdate|'0',
                    statutZone: values.statutZoneUpdate,
                    idUtilisateurCRUD: authData.idUtilisateur,
                };
                //console.log(postData);
                const response = await axiosInstance.put(`${Zone}`, postData);
                CustomToast("La zonne a été modifiée avec succès", 'success');
                setUpdateZone(false);
                setIdZoneUpdate('');
                getZones(1, pageSize);
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Zone exist") {
                    CustomToast("Zone existe", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    const ModifierZone = (value, idZone, libelle, statut) => {
        try {
            setUpdateZone(value);
            setIdZoneUpdate(idZone);
            if (value) {
                formik2.setValues((prevValues) => ({
                    ...prevValues,
                    libelleUpdate: libelle,
                    statutZoneUpdate: statut || 'inactif'
                }));
            }
        }
        catch (error) {
            console.log(error);
        }
    }
    //Imprimer etiquettes
    // const [zonesPrint, setZonesPrint] = useState([]);
    const [boxPrint, setBoxPrint] = useState([]);
    const [etageresPrint, setEtageresPrint] = useState([]);
    const [boitesPrint, setBoitesPrint] = useState([]);
    const [selectedIdCentre, setSelectedIdCentre] = useState('');
    const [selectedZone, setSelectedZone] = useState('');
    const [selectedIdZone, setSelectedIdZone] = useState('');
    const [selectedBox, setSelectedBox] = useState('');
    const [selectedEtagere, setSelectedEtagere] = useState('');
    const [selectedBoite, setSelectedBoite] = useState('');

    //Supprimer emplacements
    const [boxDelete, setBoxDelete] = useState([]);
    const [etageresDelete, setEtageresDelete] = useState([]);
    const [boitesDelete, setBoitesDelete] = useState([]);
    const [selectedIdCentreDelete, setSelectedIdCentreDelete] = useState('');
    const [selectedZoneDelete, setSelectedZoneDelete] = useState('');
    const [selectedIdZoneDelete, setSelectedIdZoneDelete] = useState('');
    const [selectedBoxDelete, setSelectedBoxDelete] = useState('');
    const [selectedEtagereDelete, setSelectedEtagereDelete] = useState('');
    const [selectedBoiteDelete, setSelectedBoiteDelete] = useState('');

    const SupprimerZone = (value, idZone, libelle, idCentre) => {
        try {
            setSupprimerZone(value);
            setSelectedIdCentreDelete(idCentre);
            setSelectedIdZoneDelete(idZone);
            setSelectedZoneDelete(libelle);
            setSelectedBoxDelete('');
            setSelectedEtagereDelete('');
            setSelectedBoiteDelete('');
            //setBoxDelete([]);
            setEtageresDelete([]);
            setBoitesDelete([])
            if (value) {
                // getZone(idCentre)
                GetBoxDeleteByIdZone(idZone);
            }
            else {
                setBoxDelete([]);
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const DeleteZone = async () => {
        try {
            await axiosInstance.delete(`${Zone}/DeleteZone/${selectedIdZoneDelete}`);
            CustomToast("La zone a étée supprimée avec succès", 'success');
            getZones(1, pageSize);
            setSupprimerZone(false);
        }
        catch (err) {
            // Handle error
            if (!err?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (err.response?.status === 400 && err.response?.data === "Zone is already used. Cannot delete.") {
                CustomToast("La zone est déjà utilisée. Impossible de suprimer.", 'error');
            } else if (err.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }

            console.log(err);
        }
    }

    const GetBoxDelete = async () => {
        try {
            const queryParams = new URLSearchParams({});

            if (selectedIdZoneDelete != null && selectedIdZoneDelete !== "") {
                queryParams.append("idZone", selectedIdZoneDelete);
            } else {
                return;
            }

            const response = await axiosInstance.get(`${Zone}/GetBox?${queryParams}`);
            const resp = await response.data;
            setBoxDelete(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const GetBoxDeleteByIdZone = async (idZone) => {
        try {
            const queryParams = new URLSearchParams({});

            if (idZone != null && idZone !== "") {
                queryParams.append("idZone", idZone);
            } else {
                return;
            }

            const response = await axiosInstance.get(`${Zone}/GetBox?${queryParams}`);
            const resp = await response.data;
            setBoxDelete(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const DeleteBox = async () => {
        try {
            await axiosInstance.delete(`${Zone}/DeleteBox/${selectedBoxDelete}`);
            CustomToast("La box a étée supprimée avec succès", 'success');
            //getZones(1, pageSize);
            //setSupprimerZone(false);
            GetBoxDelete();
        }
        catch (err) {
            // Handle error
            if (!err?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (err.response?.status === 400 && err.response?.data === "Box is already used. Cannot delete.") {
                CustomToast("La box est déjà utilisée. Impossible de suprimer.", 'error');
            } else if (err.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }

            console.log(err);
        }
    }

    const GetEtagereDelete = async () => {
        try {
            const queryParams = new URLSearchParams({});

            if (selectedBoxDelete != null && selectedBoxDelete !== "") {
                queryParams.append("idBox", selectedBoxDelete);
            } else {
                return;
            }

            const response = await axiosInstance.get(`${Zone}/GetEtageres?${queryParams}`);
            const resp = await response.data;
            setEtageresDelete(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const DeleteEtagere = async () => {
        try {
            await axiosInstance.delete(`${Zone}/DeleteEtagere/${selectedEtagereDelete}`);
            CustomToast("L'étagère a été supprimé avec succès", 'success');
            //getZones(1, pageSize);
            //setSupprimerZone(false);
            GetEtagereDelete();
        }
        catch (err) {
            // Handle error
            if (!err?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (err.response?.status === 400 && err.response?.data === "Etagère is already used. Cannot delete.") {
                CustomToast("L'étagère est déjà utilisé. Impossible de suprimer.", 'error');
            } else if (err.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }

            console.log(err);
        }
    }

    const GetBoiteDelete = async () => {
        try {
            const queryParams = new URLSearchParams({});

            if (selectedEtagereDelete != null && selectedEtagereDelete !== "") {
                queryParams.append("idEtagere", selectedEtagereDelete);
            } else {
                return;
            }

            const response = await axiosInstance.get(`${Zone}/GetBoites?${queryParams}`);
            const resp = await response.data;
            setBoitesDelete(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const DeleteBoite = async () => {
        try {
            await axiosInstance.delete(`${Zone}/DeleteBoite/${selectedBoiteDelete}`);
            CustomToast("La boite a été supprimée avec succès", 'success');
            //getZones(1, pageSize);
            //setSupprimerZone(false);
            GetBoiteDelete();
        }
        catch (err) {
            // Handle error
            if (!err?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (err.response?.status === 400 && err.response?.data === "Boite is already used. Cannot delete.") {
                CustomToast("La boite est déjà utilisée. Impossible de suprimer.", 'error');
            } else if (err.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }

            console.log(err);
        }
    }

    const handleBoxChangeDelete = (event) => {
        GetEtagereDelete(event.target.value)
        setSelectedBoxDelete(event.target.value);
    };

    const handleEtagereChangeDelete = (event) => {
        setSelectedEtagereDelete(event.target.value);
    };

    const handleBoiteChangeDelete = (event) => {
        setSelectedBoiteDelete(event.target.value);
    };

    const ImprimerZone = (value, idZone, libelle, idCentre) => {
        try {
            setImprimerZone(value);
            setSelectedIdCentre(idCentre);
            setSelectedIdZone(idZone);
            setSelectedZone(libelle);
            setSelectedBox('');
            setSelectedEtagere('');
            setSelectedBoite('');
            //setBoxPrint([]);
            setEtageresPrint([]);
            if (value) {
                // getZone(idCentre)
                GetBox();
            } else {
                setBoxPrint([]);
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const PrintZone = async () => {
        try {
            const queryParams = new URLSearchParams({});

            if (selectedIdCentre != null && selectedIdCentre !== "") {
                queryParams.append("idCentre", selectedIdCentre);
            }

            if (selectedIdZone != null) {
                queryParams.append("idZone", selectedIdZone);
            }

            const response = await axiosInstance.get(`${Zone}/GetZone?${queryParams}`);
            const resp = await response.data;
            //Genrer pdf
            createQRCodeZones(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const GetBox = async () => {
        try {
            const queryParams = new URLSearchParams({});

            if (selectedIdZone != null && selectedIdZone !== "") {
                queryParams.append("idZone", selectedIdZone);
            } else {
                return;
            }

            const response = await axiosInstance.get(`${Zone}/GetBox?${queryParams}`);
            const resp = await response.data;
            setBoxPrint(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const PrintBox = async () => {
        try {
            const queryParams = new URLSearchParams({});

            if (selectedIdZone != null && selectedIdZone !== "") {
                queryParams.append("idZone", selectedIdZone);
            } else {
                return;
            }

            if (selectedBox != null) {
                queryParams.append("idBox", selectedBox);
            }

            const response = await axiosInstance.get(`${Zone}/PrintBox?${queryParams}`);
            const resp = await response.data;
            //Genrer pdf
            createQRCodeBox(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const GetEtagere = async () => {
        try {
            const queryParams = new URLSearchParams({});

            if (selectedBox != null && selectedBox !== "") {
                queryParams.append("idBox", selectedBox);
            } else {
                return;
            }

            const response = await axiosInstance.get(`${Zone}/GetEtageres?${queryParams}`);
            const resp = await response.data;
            setEtageresPrint(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const PrintEtagere = async () => {
        try {
            const queryParams = new URLSearchParams({});

            if (selectedIdZone != null && selectedIdZone !== "") {
                queryParams.append("idZone", selectedIdZone);
            } else {
                return;
            }

            if (selectedBox != null) {
                queryParams.append("idBox", selectedBox);
            }

            if (selectedEtagere != null) {
                queryParams.append("idEtagere", selectedEtagere);
            }

            const response = await axiosInstance.get(`${Zone}/PrintEtageres?${queryParams}`);
            const resp = await response.data;
            //Genrer pdf
            createQRCodeEtageres(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const GetBoite = async () => {
        try {
            const queryParams = new URLSearchParams({});

            if (selectedEtagere != null && selectedEtagere !== "") {
                queryParams.append("idEtagere", selectedEtagere);
            } else {
                return;
            }

            const response = await axiosInstance.get(`${Zone}/GetBoites?${queryParams}`);
            const resp = await response.data;
            setBoitesPrint(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const PrintBoite = async () => {
        try {
            const queryParams = new URLSearchParams({});

            if (selectedIdZone != null && selectedIdZone !== "") {
                queryParams.append("idZone", selectedIdZone);
            } else {
                return;
            }

            if (selectedEtagere != null) {
                queryParams.append("idEtagere", selectedEtagere);
            }

            if (selectedBoite != null) {
                queryParams.append("idBoite", selectedBoite);
            }

            const response = await axiosInstance.get(`${Zone}/PrintBoites?${queryParams}`);
            const resp = await response.data;
            //Genrer pdf
            createQRCodeBoites(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    // const handleZoneChange = (event) => {
    //     GetBox(event.target.value)
    //     setSelectedZone(event.target.value);
    //   };

    const handleBoxChange = (event) => {
        GetEtagere(event.target.value)
        setSelectedBox(event.target.value);
    };

    const handleEtagereChange = (event) => {
        setSelectedEtagere(event.target.value);
    };

    const handleBoiteChange = (event) => {
        setSelectedBoite(event.target.value);
    };

    //Générer étiquette

    const createQRCodeZones = async (qrCodeDataArray, textSup = "") => {//qrCodeText les données sont séparés par ','
        // Créer un nouvel objet PDFDocument
        const pdfDoc = await PDFDocument.create();
        const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman)

        for (const qrCodeData of qrCodeDataArray) {
            // Générer le QR code pour chaque objet dans le tableau
            const qrCodeOptions = {
                type: 'image/png',
                rendererOpts: {
                    quality: 0.5,
                },
                color: {
                    dark: '#000', // Blue dots
                    light: '#0000', // Transparent background
                },
                errorCorrectionLevel: 'H',
                margin: 0,
            };

            //const qrCodeText = `${qrCodeData.nom}, ${qrCodeData.prenom}, ${qrCodeData.age}`;
            const qrCodeText = `${qrCodeData.libelle}`;
            const qrCodeDataUrl = await qrCode.toDataURL(qrCodeText, qrCodeOptions);
            const qrCodeBytes = Uint8Array.from(atob(qrCodeDataUrl.split(',')[1]), (c) => c.charCodeAt(0));

            // Créer une nouvelle page au format 3,2 cm × 1,5 cm
            const page = pdfDoc.addPage([210, 297]);

            // Ajouter l'image du QR code à la page PDF
            const image = await pdfDoc.embedPng(qrCodeBytes);

            page.drawImage(image, {
                x: 90,
                y: 250,
                width: 20,
                height: 20,
            });

            page.drawText(qrCodeText, {//Inserer un text
                x: 80,
                y: 200,
                size: 25,
                color: rgb(0, 0, 0),
                //rotate: degrees(-90),
            });
        }


        // Enregistrez le PDF dans un nouveau tableau de bytes
        const modifiedPdfBytes = await pdfDoc.save();

        // Créer un objet Blob à partir des bytes du PDF
        const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });

        // Créer une URL de données pour le Blob
        const pdfUrl = URL.createObjectURL(blob);

        // Ouvrir le PDF dans une nouvelle fenêtre ou un nouvel onglet
        window.open(pdfUrl, '_blank');

    };

    const createQRCodeBox = async (qrCodeDataArray, textSup = "") => {//qrCodeText les données sont séparés par ','
        // Créer un nouvel objet PDFDocument
        const pdfDoc = await PDFDocument.create();
        const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman)

        for (const qrCodeData of qrCodeDataArray) {
            // Générer le QR code pour chaque objet dans le tableau
            const qrCodeOptions = {
                type: 'image/png',
                rendererOpts: {
                    quality: 0.5,
                },
                color: {
                    dark: '#000', // Blue dots
                    light: '#0000', // Transparent background
                },
                errorCorrectionLevel: 'H',
                margin: 0,
            };

            const qrCodeText = `${qrCodeData.libelleZone} / ${qrCodeData.libelle}`;
            const qrCodeDataUrl = await qrCode.toDataURL(qrCodeText, qrCodeOptions);
            const qrCodeBytes = Uint8Array.from(atob(qrCodeDataUrl.split(',')[1]), (c) => c.charCodeAt(0));

            // Créer une nouvelle page au format 3,2 cm × 1,5 cm
            //const page = pdfDoc.addPage([50, 30]);
            const page = pdfDoc.addPage([60, 40]);

            // Ajouter l'image du QR code à la page PDF
            const image = await pdfDoc.embedPng(qrCodeBytes);

            // page.drawImage(image, {
            //   x: 1,
            //   y: 1,
            //   width: 30,
            //   height: 13,
            // });

            page.drawImage(image, {
                x: 5,
                y: 15,
                width: 20,
                height: 20,
            });

            // page.drawText(qrCodeText, {//Inserer un text
            //     x: 5,
            //     y: 10,
            //     size: 4,
            //     color: rgb(0, 0, 0),
            //     //rotate: degrees(-90),
            // });

            page.drawText(qrCodeData.libelleZone, {
                x: 30,
                y: 30,
                size: 7.5,
                color: rgb(0, 0, 0),
            });

            page.drawText(qrCodeData.libelle, {
                x: 30,
                y: 22,
                size: 8.5,
                color: rgb(0, 0, 0),
            });
        }

        // Enregistrez le PDF dans un nouveau tableau de bytes
        const modifiedPdfBytes = await pdfDoc.save();

        // Créer un objet Blob à partir des bytes du PDF
        const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });

        // Créer une URL de données pour le Blob
        const pdfUrl = URL.createObjectURL(blob);

        // Ouvrir le PDF dans une nouvelle fenêtre ou un nouvel onglet
        window.open(pdfUrl, '_blank');

    };

    const createQRCodeEtageres = async (qrCodeDataArray, textSup = "") => {//qrCodeText les données sont séparés par ','
        // Créer un nouvel objet PDFDocument
        const pdfDoc = await PDFDocument.create();
        const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman)

        for (const qrCodeData of qrCodeDataArray) {
            // Générer le QR code pour chaque objet dans le tableau
            const qrCodeOptions = {
                type: 'image/png',
                rendererOpts: {
                    quality: 0.5,
                },
                color: {
                    dark: '#000', // Blue dots
                    light: '#0000', // Transparent background
                },
                errorCorrectionLevel: 'H',
                margin: 0,
            };

            const qrCodeText = `${qrCodeData.libelleZone} / ${qrCodeData.libelleBox} / ${qrCodeData.libelle}`;
            const qrCodeDataUrl = await qrCode.toDataURL(qrCodeText, qrCodeOptions);
            const qrCodeBytes = Uint8Array.from(atob(qrCodeDataUrl.split(',')[1]), (c) => c.charCodeAt(0));

            // Créer une nouvelle page au format 3,2 cm × 1,5 cm
            //const page = pdfDoc.addPage([50, 30]);
            const page = pdfDoc.addPage([60, 40]);
            // Ajouter l'image du QR code à la page PDF
            const image = await pdfDoc.embedPng(qrCodeBytes);

            page.drawImage(image, {
                x: 5,
                y: 15,
                width: 20,
                height: 20,
            });

            // page.drawText(qrCodeText, {//Inserer un text
            //     x: 5,
            //     y: 10,
            //     size: 4,
            //     color: rgb(0, 0, 0),
            //     //rotate: degrees(-90),
            // });

            page.drawText(qrCodeData.libelleZone, {
                x: 30,
                y: 30,
                size: 7.5,
                color: rgb(0, 0, 0),
            });

            page.drawText(qrCodeData.libelleBox, {
                x: 30,
                y: 22,
                size: 8.5,
                color: rgb(0, 0, 0),
            });

            page.drawText(qrCodeData.libelle, {
                x: 30,
                y: 14,
                size: 8.5,
                color: rgb(0, 0, 0),
            });
        }

        // Enregistrez le PDF dans un nouveau tableau de bytes
        const modifiedPdfBytes = await pdfDoc.save();

        // Créer un objet Blob à partir des bytes du PDF
        const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });

        // Créer une URL de données pour le Blob
        const pdfUrl = URL.createObjectURL(blob);

        // Ouvrir le PDF dans une nouvelle fenêtre ou un nouvel onglet
        window.open(pdfUrl, '_blank');

    };

    const createQRCodeBoites = async (qrCodeDataArray, textSup = "") => {//qrCodeText les données sont séparés par ','
        // Créer un nouvel objet PDFDocument
        const pdfDoc = await PDFDocument.create();
        const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman)

        for (const qrCodeData of qrCodeDataArray) {
            // Générer le QR code pour chaque objet dans le tableau
            const qrCodeOptions = {
                type: 'image/png',
                rendererOpts: {
                    quality: 0.5,
                },
                color: {
                    dark: '#000', // Blue dots
                    light: '#0000', // Transparent background
                },
                errorCorrectionLevel: 'H',
                margin: 0,
            };

            const qrCodeText = `${qrCodeData.libelleZone} / ${qrCodeData.libelleBox} / ${qrCodeData.libelleEtagere} / ${qrCodeData.libelle}`;
            const qrCodeDataUrl = await qrCode.toDataURL(qrCodeText, qrCodeOptions);
            const qrCodeBytes = Uint8Array.from(atob(qrCodeDataUrl.split(',')[1]), (c) => c.charCodeAt(0));

            // Créer une nouvelle page au format 3,2 cm × 1,5 cm
            //const page = pdfDoc.addPage([50, 30]);
            const page = pdfDoc.addPage([60, 40]);
            // Ajouter l'image du QR code à la page PDF
            const image = await pdfDoc.embedPng(qrCodeBytes);

            // // page.drawImage(image, {
            // //   x: 1,
            // //   y: 1,
            // //   width: 30,
            // //   height: 13,
            // // });

            // page.drawImage(image, {
            //     x: 15,
            //     y: 3,
            //     width: 20,
            //     height: 20,
            // });

            // page.drawText(qrCodeText, {//Inserer un text
            //     x: 20,
            //     y: 1,
            //     size: 1.5,
            //     color: rgb(0, 0, 0),
            //     //rotate: degrees(-90),
            // });

            page.drawImage(image, {
                x: 5,
                y: 15,
                width: 20,
                height: 20,
            });

            // page.drawText(qrCodeText, {//Inserer un text
            //     x: 5,
            //     y: 10,
            //     size: 4,
            //     color: rgb(0, 0, 0),
            //     //rotate: degrees(-90),
            // });

            page.drawText(qrCodeData.libelleZone, {
                x: 30,
                y: 30,
                size: 7.5,
                color: rgb(0, 0, 0),
            });

            page.drawText(qrCodeData.libelleBox, {
                x: 30,
                y: 22,
                size: 8.5,
                color: rgb(0, 0, 0),
            });

            page.drawText(qrCodeData.libelleEtagere, {
                x: 30,
                y: 14,
                size: 8.5,
                color: rgb(0, 0, 0),
            });

            page.drawText(qrCodeData.libelle, {
                x: 30,
                y: 6,
                size: 8.5,
                color: rgb(0, 0, 0),
            });
        }

        // Enregistrez le PDF dans un nouveau tableau de bytes
        const modifiedPdfBytes = await pdfDoc.save();

        // Créer un objet Blob à partir des bytes du PDF
        const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });

        // Créer une URL de données pour le Blob
        const pdfUrl = URL.createObjectURL(blob);

        // Ouvrir le PDF dans une nouvelle fenêtre ou un nouvel onglet
        window.open(pdfUrl, '_blank');

    };

    useEffect(() => {
        //Load Data
        //console.log("Effect is running");

        getZones(debouncedSearchText ? 1 : pageNumber, pageSize);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
            //console.log("Cleanup");
        };
    }, [authData, debouncedSearchText, pageNumber, pageSize]);

    useEffect(() => {
        //Load Data
        GetBox();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
            //console.log("Cleanup");
        };
    }, [selectedIdZone]);

    useEffect(() => {
        //Load Data
        GetEtagere();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
            //console.log("Cleanup");
        };
    }, [selectedBox]);

    useEffect(() => {
        //Load Data
        GetBoite();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
            //console.log("Cleanup");
        };
    }, [selectedEtagere]);

    //// Supprimer
    useEffect(() => {
        //Load Data
        GetBoxDelete();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
            //console.log("Cleanup");
        };
    }, [selectedIdZoneDelete]);

    useEffect(() => {
        //Load Data
        GetEtagereDelete();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
            //console.log("Cleanup");
        };
    }, [selectedBoxDelete]);

    useEffect(() => {
        //Load Data
        GetBoiteDelete();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
            //console.log("Cleanup");
        };
    }, [selectedEtagereDelete]);

    return (
        <>
            <div className='row mt-1 mb-1 sticky-top'>
                <div className='col-xs-12 col-md-12'>
                    <div className="card">
                        <div className="card-body">
                            <div className='row justify-content-center align-items-center'>
                            {authData?.role !== "visiteur" && <div className='col-xs-2 col-md-2'>
                                    <Link to="/utilisateurs" className="btn btn-light form-control" replace>
                                        <i className="fa fa-users"> Utilisateurs</i>
                                    </Link>
                                </div>}
                                <div className='col-xs-2 col-md-2'>
                                    <Link to="/centres" className="btn btn-light form-control" replace>
                                        <i className="fa fa-home"> Centres</i>
                                    </Link>
                                </div>
                                <div className='col-xs-2 col-md-2'>
                                    <Link to="/zones" className="btn btn-light form-control" replace>
                                        <i className="fa fa-bullseye"> Zones</i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row mt-1 mb-1'>
                <div className='col-xs-12 col-md-12'>
                    <div className="card">
                        <div className="card-header">Zones</div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-xs-2 col-md-2 mt-5 mb-4 text-gred offset-xs-2 offset-md-2">
                                    <div className="search">
                                        <form className="form-inline">
                                            <input
                                                className="form-control mr-sm-2"
                                                type="search" placeholder="Search"
                                                aria-label="Rechercher"
                                                value={search}
                                                onChange={(e) => setSearch(e.target.value)}
                                            />
                                        </form>
                                    </div>
                                </div>
                                <div className="col-xs-1 col-md-1 offset-xs-5 offset-md-5 mt-5 mb-4 text-gred">
                                    <button className="btn btn-success form-control" type="button" onClick={() => AjouterZone(true)}>
                                        <i className="fa fa-plus"></i>
                                    </button>
                                </div>
                            </div>
                            <div className='row justify-content-center align-items-center g-2'>
                                <div className="col-xs-12 col-md-8">
                                    <div className="table-responsive">
                                        <table className="table table-hover table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Centre</th>
                                                    <th>Zone</th>
                                                    <th>Zone dédiée</th>
                                                    <th>Statut</th>
                                                    <th colSpan={3} className='text-center'>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {zones.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            {item.libelleCentre}
                                                        </td>
                                                        <td>
                                                            {item.libelle}
                                                        </td>
                                                        <td>
                                                            {item.zoneDediee}
                                                        </td>
                                                        <td>
                                                            {item.statutZone === "inactif" ? "Inactif" : "Actif"}
                                                        </td>
                                                        <td className='text-center'>
                                                            <Link to="#" style={{ textDecoration: "none" }} onClick={() => ModifierZone(true, item.idZone, item.libelle, item.statutZone)}>
                                                                <span className="fa fa-pencil text-success fa-lg"></span>
                                                            </Link>
                                                        </td>
                                                        <td className='text-center'>
                                                            <Link to="#" style={{ textDecoration: "none" }} onClick={() => ImprimerZone(true, item.idZone, item.libelle, item.idCentre)}>
                                                                <span className="fa fa-print text-warning fa-lg"></span>
                                                            </Link>
                                                        </td>
                                                        <td className='text-center'>
                                                            <Link to="#" style={{ textDecoration: "none" }} onClick={() => SupprimerZone(true, item.idZone, item.libelle, item.idCentre)}>
                                                                <span className="fa fa-trash text-danger fa-lg"></span>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="d-flex justify-content-between mt-3">
                                        <div>
                                            {totalPages >= 1 && (
                                                <Pagination>
                                                    <Pagination.Prev
                                                        onClick={() => handlePageChange(pageNumber - 1)}
                                                        disabled={pageNumber === 1}
                                                    >
                                                        <span aria-hidden="true">&laquo;</span>
                                                    </Pagination.Prev>
                                                    {Array.from({ length: totalPages }).map((_, index) => (
                                                        <Pagination.Item
                                                            key={index}
                                                            active={index + 1 === pageNumber}
                                                            onClick={() => handlePageChange(index + 1)}
                                                        >
                                                            {index + 1}
                                                        </Pagination.Item>
                                                    ))}
                                                    <Pagination.Next
                                                        onClick={() => handlePageChange(pageNumber + 1)}
                                                        disabled={pageNumber === totalPages}
                                                    >
                                                        <span aria-hidden="true">&raquo;</span>
                                                    </Pagination.Next>
                                                </Pagination>
                                            )}
                                        </div>

                                        <div className="mb-3">
                                            <p>
                                                Affichage de {getStartIndex()} à {getEndIndex()} sur {totalItems} entrées
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {ajouterZone && <><div className="modal" id="ajouterZone" style={{ display: 'block' }} data-bs-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true" tabIndex={-1}>
                <form onSubmit={formik1.handleSubmit}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Ajouter une zone</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => AjouterZone(false)}></button>
                            </div>
                            <div className="modal-body">
                                <div className='row mt-0 mb-0'>
                                    <div className="col">
                                        <label className="form-label">Centre<span className='text-danger fs-6'>*</span></label>
                                        <select
                                            id="idCentre"
                                            name="idCentre"
                                            className={`form-control ${formik1.touched.idCentre && formik1.errors?.idCentre ? 'border-danger' : ''}`}
                                            value={formik1.values.idCentre}
                                            onChange={formik1.handleChange}
                                        >
                                            <option value="">Veuillez sélectionner</option>
                                            {centres.map((centre) => (
                                                <option key={uuidv4()} value={centre.idCentre}>{centre.libelle}</option>
                                            ))}
                                        </select>
                                        {formik1.touched.idCentre && formik1.errors.idCentre ? (
                                            <small className="text-danger">{formik1.errors.idCentre}</small>
                                        ) : null}
                                    </div>
                                    <div className="col">
                                        <label className="form-label">Libellé zone<span className='text-danger fs-6'>*</span></label>
                                        <input
                                            type="text"
                                            name="libelle"
                                            id="libelle"
                                            className={`form-control ${formik1.touched.libelle && formik1.errors?.libelle ? 'border-danger' : ''}`}
                                            placeholder=""
                                            value={formik1.values.libelle}
                                            onChange={formik1.handleChange}
                                        />
                                        {formik1.touched.libelle && formik1.errors.libelle ? (
                                            <small className="text-danger">{formik1.errors.libelle}</small>
                                        ) : null}
                                    </div>
                                    <div className="col">
                                        <label className="form-label">Zone dédiée</label>
                                        <select
                                            id="zoneDediee"
                                            name="zoneDediee"
                                            className={`form-control ${formik1.touched.zoneDediee && formik1.errors?.zoneDediee ? 'border-danger' : ''}`}
                                            value={formik1.values.zoneDediee}
                                            onChange={formik1.handleChange}
                                        >
                                            <option value="" defaultValue={true}>Sélectionnez un</option>
                                            <option value="boutique">Boutique</option>
                                            <option value="volume">Volume</option>
                                        </select>
                                        {formik1.touched.zoneDediee && formik1.errors.zoneDediee ? (
                                            <small className="text-danger">{formik1.errors.zoneDediee}</small>
                                        ) : null}
                                    </div>
                                    <div className="col">
                                        <label className="form-label">Statut</label>
                                        <select
                                            id="statutZone"
                                            name="statutZone"
                                            className={`form-control ${formik1.touched.statutZone && formik1.errors?.statutZone ? 'border-danger' : ''}`}
                                            value={formik1.values.statutZone}
                                            onChange={formik1.handleChange}
                                        >
                                            <option value="inactif" defaultValue={true}>Inactif</option>
                                            <option value="actif">Actif</option>
                                        </select>
                                        {formik1.touched.statutZone && formik1.errors.statutZone ? (
                                            <small className="text-danger">{formik1.errors.statutZone}</small>
                                        ) : null}
                                    </div>
                                </div>
                                <div className='row mt-0 mb-0'>
                                    <div className="col">
                                        <label className="form-label">La zone est composée de<span className='text-danger fs-6'>*</span></label>
                                        <div className="input-group mb-3">
                                            <input
                                                type="number"
                                                name="nbrBox"
                                                id="adresse"
                                                className={`form-control ${formik1.touched.nbrBox && formik1.errors?.nbrBox ? 'border-danger' : ''}`}
                                                placeholder=""
                                                value={formik1.values.nbrBox}
                                                onChange={formik1.handleChange}
                                                step={'1'}
                                            />
                                            <span className="input-group-text">
                                                Box
                                            </span>
                                        </div>
                                        {formik1.touched.nbrBox && formik1.errors.nbrBox ? (
                                            <small className="text-danger">{formik1.errors.nbrBox}</small>
                                        ) : null}
                                    </div>
                                </div>
                                <div className='row mt-0 mb-0'>
                                    <div className="col">
                                        <label className="form-label">Et chaque box est composée de<span className='text-danger fs-6'>*</span></label>
                                        <div className="input-group mb-3">
                                            <input
                                                type="number"
                                                name="nbrEtageres"
                                                id="nbrEtageres"
                                                className={`form-control ${formik1.touched.nbrEtageres && formik1.errors?.nbrEtageres ? 'border-danger' : ''}`}
                                                placeholder=""
                                                value={formik1.values.nbrEtageres}
                                                onChange={formik1.handleChange}
                                                step={'1'}
                                            />
                                            <span className="input-group-text">
                                                Étagères
                                            </span>
                                        </div>
                                        {formik1.touched.nbrEtageres && formik1.errors.nbrEtageres ? (
                                            <small className="text-danger">{formik1.errors.nbrEtageres}</small>
                                        ) : null}
                                    </div>
                                </div>
                                <div className='row mt-0 mb-0'>
                                    <div className="col">
                                        <label className="form-label">Et chaque étagère composée de<span className='text-danger fs-6'>*</span></label>
                                        <div className="input-group mb-3">
                                            <input
                                                type="number"
                                                name="nbrBoites"
                                                id="nbrBoites"
                                                className={`form-control ${formik1.touched.nbrBoites && formik1.errors?.nbrBoites ? 'border-danger' : ''}`}
                                                placeholder=""
                                                value={formik1.values.nbrBoites}
                                                onChange={formik1.handleChange}
                                                step={'1'}
                                            />
                                            <span className="input-group-text">
                                                Boites
                                            </span>
                                        </div>
                                        {formik1.touched.nbrBoites && formik1.errors.nbrBoites ? (
                                            <small className="text-danger">{formik1.errors.nbrBoites}</small>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => AjouterZone(false)}>Annuler</button>
                                <button type="submit" className="btn btn-success" data-bs-dismiss="modal">Enregistrer</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
                <div className="modal-backdrop show"></div></>
            }

            {updateZone && <><div className="modal" id="modifierZone" style={{ display: 'block' }} data-bs-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true" tabIndex={-1}>
                <form onSubmit={formik2.handleSubmit}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Modifier une zone</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => ModifierZone(false, '', '', '')}></button>
                            </div>
                            <div className="modal-body">
                                <div className='row mt-0 mb-0'>
                                    <div className="col">
                                        <label className="form-label">Libellé zone<span className='text-danger fs-6'>*</span></label>
                                        <input
                                            type="text"
                                            name="libelleUpdate"
                                            id="libelleUpdate"
                                            className={`form-control ${formik2.touched.libelleUpdate && formik2.errors?.libelleUpdate ? 'border-danger' : ''}`}
                                            placeholder=""
                                            value={formik2.values.libelleUpdate}
                                            onChange={formik2.handleChange}
                                        />
                                        {formik2.touched.libelleUpdate && formik2.errors.libelleUpdate ? (
                                            <small className="text-danger">{formik2.errors.libelleUpdate}</small>
                                        ) : null}
                                    </div>
                                    <div className="col">
                                        <label className="form-label">Statut</label>
                                        <select
                                            id="statutZoneUpdate"
                                            name="statutZoneUpdate"
                                            className={`form-control ${formik2.touched.statutZoneUpdate && formik2.errors?.statutZoneUpdate ? 'border-danger' : ''}`}
                                            value={formik2.values.statutZoneUpdate}
                                            onChange={formik2.handleChange}
                                        >
                                            <option value="" defaultValue={true}>Sélectionnez un</option>
                                            <option value="actif">Actif</option>
                                            <option value="inactif">Inactif</option>
                                        </select>
                                        {formik2.touched.statutZoneUpdate && formik2.errors.statutZoneUpdate ? (
                                            <small className="text-danger">{formik2.errors.statutZoneUpdate}</small>
                                        ) : null}
                                    </div>
                                </div>
                                <div className='row mt-0 mb-0'>
                                    <div className="col">
                                        <label className="form-label">Box supplémentaires<span className='text-danger fs-6'>*</span></label>
                                        <div className="input-group mb-3">
                                            <input
                                                type="number"
                                                name="nbrBoxUpdate"
                                                id="nbrBoxUpdate"
                                                className={`form-control ${formik2.touched.nbrBoxUpdate && formik2.errors?.nbrBoxUpdate ? 'border-danger' : ''}`}
                                                placeholder=""
                                                value={formik2.values.nbrBoxUpdate}
                                                onChange={formik2.handleChange}
                                                step={'1'}
                                            />
                                            <span className="input-group-text">
                                                Box
                                            </span>
                                        </div>
                                        {formik2.touched.nbrBoxUpdate && formik2.errors.nbrBoxUpdate ? (
                                            <small className="text-danger">{formik2.errors.nbrBoxUpdate}</small>
                                        ) : null}
                                    </div>
                                </div>
                                <div className='row mt-0 mb-0'>
                                    <div className="col">
                                        <label className="form-label">Et chaque box est composée de<span className='text-danger fs-6'>*</span></label>
                                        <div className="input-group mb-3">
                                            <input
                                                type="number"
                                                name="nbrEtageresUpdate"
                                                id="nbrEtageresUpdate"
                                                className={`form-control ${formik2.touched.nbrEtageresUpdate && formik2.errors?.nbrEtageresUpdate ? 'border-danger' : ''}`}
                                                placeholder=""
                                                value={formik2.values.nbrEtageresUpdate}
                                                onChange={formik2.handleChange}
                                                step={'1'}
                                            />
                                            <span className="input-group-text">
                                                Étagères
                                            </span>
                                        </div>
                                        {formik2.touched.nbrEtageresUpdate && formik2.errors.nbrEtageresUpdate ? (
                                            <small className="text-danger">{formik2.errors.nbrEtageresUpdate}</small>
                                        ) : null}
                                    </div>
                                </div>
                                <div className='row mt-0 mb-0'>
                                    <div className="col">
                                        <label className="form-label">Et chaque étagère composée de<span className='text-danger fs-6'>*</span></label>
                                        <div className="input-group mb-3">
                                            <input
                                                type="number"
                                                name="nbrBoitesUpdate"
                                                id="nbrBoitesUpdate"
                                                className={`form-control ${formik2.touched.nbrBoitesUpdate && formik2.errors?.nbrBoitesUpdate ? 'border-danger' : ''}`}
                                                placeholder=""
                                                value={formik2.values.nbrBoitesUpdate}
                                                onChange={formik2.handleChange}
                                                step={'1'}
                                            />
                                            <span className="input-group-text">
                                                Boites
                                            </span>
                                        </div>
                                        {formik2.touched.nbrBoitesUpdate && formik2.errors.nbrBoitesUpdate ? (
                                            <small className="text-danger">{formik2.errors.nbrBoitesUpdate}</small>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => ModifierZone(false, '', '', '')}>Annuler</button>
                                <button type="submit" className="btn btn-success" data-bs-dismiss="modal">Enregistrer</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
                <div className="modal-backdrop show"></div></>
            }
            {imprimerZone && <><div className="modal" id="ajouterZone" style={{ display: 'block' }} data-bs-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true" tabIndex={-1}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Imprimer</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => ImprimerZone(false, '', '', '')}></button>
                        </div>
                        <div className="modal-body">
                            <div className='row mt-0 mb-0'>
                                <div className="col">
                                    <label className="form-label">Zone</label>
                                    <div className="input-group mb-3">
                                        <input
                                            type="text"
                                            name="idZone"
                                            id="idZone"
                                            className={`form-control`}
                                            placeholder=""
                                            defaultValue={selectedZone}
                                            disabled
                                        />
                                        <span className="input-group-text text-danger" onClick={() => PrintZone()}>
                                            <i className="fa fa-print" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-0 mb-0'>
                                <div className="col">
                                    <label className="form-label">Box</label>
                                    <div className="input-group mb-3">
                                        <select
                                            id="idBox"
                                            name="idBox"
                                            className={`form-control`}
                                            value={selectedBox}
                                            onChange={handleBoxChange}
                                        >
                                            <option value="">Veuillez sélectionner</option>
                                            {boxPrint.map((box) => (
                                                <option key={uuidv4()} value={box.idBox}>{box.libelle}</option>
                                            ))}
                                        </select>
                                        <span className="input-group-text text-danger" onClick={() => PrintBox()}>
                                            <i className="fa fa-print" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-0 mb-0'>
                                <div className="col">
                                    <label className="form-label">Étagères</label>
                                    <div className="input-group mb-3">
                                        <select
                                            id="idEtagere"
                                            name="idEtagere"
                                            className={`form-control`}
                                            value={selectedEtagere}
                                            onChange={handleEtagereChange}
                                        >
                                            <option value="">Veuillez sélectionner</option>
                                            {etageresPrint.map((etagere) => (
                                                <option key={uuidv4()} value={etagere.idEtagere}>{etagere.libelleBox + '/' + etagere.libelle}</option>
                                            ))}
                                        </select>
                                        <span className="input-group-text text-danger" onClick={() => PrintEtagere()}>
                                            <i className="fa fa-print" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-0 mb-0'>
                                <div className="col">
                                    <label className="form-label">Boites</label>
                                    <div className="input-group mb-3">
                                        <select
                                            id="idBoite"
                                            name="idBoite"
                                            className={`form-control`}
                                            value={selectedBoite}
                                            onChange={handleBoiteChange}
                                        >
                                            <option value="">Veuillez sélectionner</option>
                                            {boitesPrint.map((boite) => (
                                                <option key={uuidv4()} value={boite.idBoite}>{boite.libelleBox + '/' + boite.libelleEtagere + '/' + boite.libelle}</option>
                                            ))}
                                        </select>
                                        <span className="input-group-text text-danger" onClick={() => PrintBoite()}>
                                            <i className="fa fa-print" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => ImprimerZone(false, '', '', '')}>Annuler</button>
                        </div>
                    </div>
                </div>
            </div>
                <div className="modal-backdrop show"></div></>
            }
            {supprimerZone && <><div className="modal" id="supprimerZone" style={{ display: 'block' }} data-bs-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true" tabIndex={-1}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header danger">
                            <h4 className="modal-title">Supprimer</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => SupprimerZone(false, '', '', '')}></button>
                        </div>
                        <div className="modal-body">
                            <div className='row mt-0 mb-0'>
                                <div className="col">
                                    <label className="form-label">Zone</label>
                                    <div className="input-group mb-3">
                                        <input
                                            type="text"
                                            name="idZone"
                                            id="idZone"
                                            className={`form-control`}
                                            placeholder=""
                                            defaultValue={selectedZoneDelete}
                                            disabled
                                        />
                                        <span className="input-group-text text-danger" onClick={() => DeleteZone()}>
                                            <i className="fa fa-trash" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-0 mb-0'>
                                <div className="col">
                                    <label className="form-label">Box</label>
                                    <div className="input-group mb-3">
                                        <select
                                            id="idBox"
                                            name="idBox"
                                            className={`form-control`}
                                            value={selectedBoxDelete}
                                            onChange={handleBoxChangeDelete}
                                        >
                                            <option value="">Veuillez sélectionner</option>
                                            {boxDelete.map((box) => (
                                                <option key={uuidv4()} value={box.idBox}>{box.libelle}</option>
                                            ))}
                                        </select>
                                        <span className="input-group-text text-danger" onClick={() => DeleteBox()}>
                                            <i className="fa fa-trash" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-0 mb-0'>
                                <div className="col">
                                    <label className="form-label">Étagères</label>
                                    <div className="input-group mb-3">
                                        <select
                                            id="idEtagere"
                                            name="idEtagere"
                                            className={`form-control`}
                                            value={selectedEtagereDelete}
                                            onChange={handleEtagereChangeDelete}
                                        >
                                            <option value="">Veuillez sélectionner</option>
                                            {etageresDelete.map((etagere) => (
                                                <option key={uuidv4()} value={etagere.idEtagere}>{etagere.libelleBox + '/' + etagere.libelle}</option>
                                            ))}
                                        </select>
                                        <span className="input-group-text text-danger" onClick={() => DeleteEtagere()}>
                                            <i className="fa fa-trash" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-0 mb-0'>
                                <div className="col">
                                    <label className="form-label">Boites</label>
                                    <div className="input-group mb-3">
                                        <select
                                            id="idBoite"
                                            name="idBoite"
                                            className={`form-control`}
                                            value={selectedBoiteDelete}
                                            onChange={handleBoiteChangeDelete}
                                        >
                                            <option value="">Veuillez sélectionner</option>
                                            {boitesDelete.map((boite) => (
                                                <option key={uuidv4()} value={boite.idBoite}>{boite.libelleBox + '/' + boite.libelleEtagere + '/' + boite.libelle}</option>
                                            ))}
                                        </select>
                                        <span className="input-group-text text-danger" onClick={() => DeleteBoite()}>
                                            <i className="fa fa-trash" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => ImprimerZone(false, '', '')}>Annuler</button>
                        </div>
                    </div>
                </div>
            </div>
                <div className="modal-backdrop show"></div></>
            }
        </>
    );
}

export default Zones;
