import React, { useEffect, useState, useContext, useRef } from 'react';
import { QrReader } from "react-qr-reader";
import BarcodeReader from 'react-barcode-reader';
import { CustomToast } from '../../components/Toast/CustomToast';
import { AuthContext } from '../../contexts/AuthContext';
import { axiosInstance, Affectation } from '../../services/api';
import { format, formatDistance, formatRelative, subDays } from 'date-fns'
import { degrees, grayscale, PDFDocument, PDFFont, rgb, StandardFonts } from 'pdf-lib';
import qrCode from 'qrcode';
//import bwipjs from 'bwip-js';
import JsBarcode from 'jsbarcode';
const Scan = () => {
    const { authData } = useContext(AuthContext);
    const [nouvelleBoite, setNouvelleBoite] = useState(false);
    const [showAutreDestination, setShowAutreDestination] = useState(false);
    const [zoneIntrouvable, setZoneIntrouvable] = useState(false);
    const [autreDestination, setAutreDestination] = useState(false);

    //Générer étiquette

    const createQRCodeLabel = async (libelleZone, libelleBox, libelleEtagere, libelle, tracking) => {//qrCodeText les données sont séparés par ','
        // Créer un nouvel objet PDFDocument
        const pdfDoc = await PDFDocument.create();
        const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman)

        // Générer le QR code
        const qrCodeOptions = {
            type: 'image/png',
            rendererOpts: {
                quality: 0.5,
            },
            color: {
                dark: '#000', // Blue dots
                light: '#0000', // Transparent background
            },
            errorCorrectionLevel: 'H',
            margin: 0,
        };

        const qrCodeDataUrl = await qrCode.toDataURL(tracking, qrCodeOptions);
        // Convertir l'image du QR code en bytes
        const qrCodeBytes = Uint8Array.from(atob(qrCodeDataUrl.split(',')[1]), (c) => c.charCodeAt(0));

        // Créer une nouvelle page au format 6,0 cm × 4,0 cm
        const page = pdfDoc.addPage([60, 40]);

        // Ajouter l'image du QR code à la page PDF
        const image = await pdfDoc.embedPng(qrCodeBytes);
        page.drawImage(image, {
            x: 5,
            y: 15,
            width: 20,
            height: 20,
        });

        page.drawText(tracking, {//Inserer un text
            x: 5,
            y: 10,
            size: 4,
            color: rgb(0, 0, 0),
            //rotate: degrees(-90),
        });

        page.drawText(libelleZone, {
            x: 30,
            y: 30,
            size: 7.5,
            color: rgb(0, 0, 0),
        });

        page.drawText(libelleBox, {
            x: 30,
            y: 22,
            size: 8.5,
            color: rgb(0, 0, 0),
        });

        page.drawText(libelleEtagere, {
            x: 30,
            y: 14,
            size: 8.5,
            color: rgb(0, 0, 0),
        });

        page.drawText(libelle, {
            x: 30,
            y: 6,
            size: 8.5,
            color: rgb(0, 0, 0),
        });

        // Enregistrez le PDF dans un nouveau tableau de bytes
        const modifiedPdfBytes = await pdfDoc.save();

        // Créer un objet Blob à partir des bytes du PDF
        const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });

        // Créer une URL de données pour le Blob
        const pdfUrl = URL.createObjectURL(blob);

        // Ouvrir le PDF dans une nouvelle fenêtre ou un nouvel onglet
        window.open(pdfUrl, '_blank');

    };

    const createBRCodeLabel = async (brCodeText, textSup = "") => {
        try {
            // Créer un nouvel objet PDFDocument
            const pdfDoc = await PDFDocument.create();
            const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);

            // Créer une nouvelle page au format 3,2 cm × 1,5 cm
            const page = pdfDoc.addPage([32, 15]);
            const { width, height } = page.getSize();

            // Utiliser JsBarcode pour générer le code-barres dans un canvas
            const canvas = document.createElement('canvas');
            JsBarcode(canvas, brCodeText, { format: 'CODE128' });

            // Convertir le canvas en image PNG
            const barcodeDataUrl = canvas.toDataURL('image/png');
            const barcodeBytes = Uint8Array.from(atob(barcodeDataUrl.split(',')[1]), (c) => c.charCodeAt(0));

            // Incorporer l'image du code-barres dans le PDF
            const barcodeImage = await pdfDoc.embedPng(barcodeBytes);
            const barcodeDims = barcodeImage.scaleToFit(63, 10);

            page.drawImage(barcodeImage, {
                x: 1,
                y: 1,
                width: 30,
                height: 11,
            });

            page.drawText(brCodeText, {//Inserer un text
                x: 10,
                y: 1,
                size: 1.5,
                color: rgb(0, 0, 0),
                //rotate: degrees(-90),
            });

            // Enregistrez le PDF dans un nouveau tableau de bytes
            const modifiedPdfBytes = await pdfDoc.save();

            // Créer un objet Blob à partir des bytes du PDF
            const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });

            // Créer une URL de données pour le Blob
            const pdfUrl = URL.createObjectURL(blob);

            // Ouvrir le PDF dans une nouvelle fenêtre ou un nouvel onglet
            window.open(pdfUrl, '_blank');
        } catch (error) {
            console.error('Erreur lors de la génération du code-barres :', error);
        }
    };

    //Code barre douchette
    const [brCodeDataDouchette, setBrCodeDataDouchette] = useState('');

    //Code barre scann
    const [barcode, setBarcode] = useState('');

    const handleScanBR = async (data) => {
        if (data) {
            setBarcode(data);
            const postData = {
                nouvelleBoite: nouvelleBoite,
                tracking: data,
                idCentre: authData.idCentre,
                libelleCentre: authData.libelleCentre,
                idUtilisateurCRUD: authData.idUtilisateur,
            };
            console.log(postData,authData);
            try {
                const response = await axiosInstance.post(`${Affectation}`, postData);
                if (response?.status === 200) {
                    //Imprimer eiquette
                    const qrCodeText = `${response?.data.libelleZone} / ${response?.data.libelleBox} / ${response?.data.libelleEtagere} / ${response?.data.libelle} / ${response?.data.tracking}`;
                    await createQRCodeLabel(response?.data.libelleZone, response?.data.libelleBox, response?.data.libelleEtagere, response?.data.libelle, response?.data.tracking);
                    //setBarcode('');
                }
                CustomToast("Colis affecté avec succès", 'success');
            } catch (error) {
                // Handle error
                // setScanner(false);
                // setNouvelleBoite(false);
                // setQrCodeData('');
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Qr code error: Refaire") {
                    CustomToast("Qr code invalide", 'error');
                } else if (error.response?.status === 400 && error.response?.data.message === "Colis existe") {
                    CustomToast("Colis déjà scanné", 'warning');
                } else if (error.response?.status === 400 && error.response?.data.message === "ECH n'existe pas dans la bdd local") {
                    //CustomToast("ECH n'existe pas dans la bdd local", 'warning');
                    setAutreDestination(error.response?.data.tracking);
                    setShowAutreDestination(true);
                    console.log(error.response?.data)
                } else if (error.response?.status === 400 && error.response?.data.message === "Autre destination") {
                    //CustomToast("Destination: "+ error.response?.data.affectation, 'warning');
                    setAutreDestination(error.response?.data);
                    setShowAutreDestination(true);
                } else if (error.response?.status === 400 && error.response?.data.message === "Aucune zone") {
                    setZoneIntrouvable(true);
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    };

    const handleErrorBR = (err) => {
        console.error(err);
    };

    const handleCheckboxChange = () => {
        setNouvelleBoite((prevValue) => !prevValue);
    };

    const nouvelleBoiteRef = useRef(nouvelleBoite);
    useEffect(() => {
        nouvelleBoiteRef.current = nouvelleBoite;
    }, [nouvelleBoite]);

    useEffect(() => {
        console.log(authData)
    }, [authData]);

    return (
        <>
            <div className='row justify-content-center align-items-center'>
                <div className='col-xs-12 col-md-6'>
                    <div className="card">
                        <div className="card-body">
                            <div className='row'>
                                <div className='col-xs-12 col-md-12'>
                                    <div className="vstack">
                                        <div className="hstack">
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input
                                                        ref={nouvelleBoiteRef}
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="nouvelleBoite"
                                                        name="nouvelleBoite"
                                                        checked={nouvelleBoite}
                                                        onChange={() => {
                                                            handleCheckboxChange();
                                                        }}
                                                    /> Ajouter une nouvelle boite
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-xs-12 col-md-12'>
                                    <BarcodeReader
                                        onError={handleErrorBR}
                                        onScan={handleScanBR}
                                        facingMode="environment"
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-xs-12 col-md-12'>
                                    <div className="table-responsive">
                                        <table className="table border table-striped">
                                            <tfoot>
                                                <tr className="text-right">
                                                    <td colSpan="2">Qr code</td>
                                                    <td id="resume_deliveryfee" className="text-danger font-weight-bold">{barcode}</td>
                                                </tr>

                                                {/* <tr className="text-right">
                                                    <td colSpan="2">Client </td>
                                                    <td className="text-danger font-weight-bold">{barcode?.split(',')[3]}</td>
                                                </tr>*/}

                                                <tr className="text-right">
                                                    <td colSpan="2">Tracking</td>
                                                    <td className="text-danger font-weight-bold">{barcode?.split(',')[1]}</td>
                                                </tr> 
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            {/* <div className='row'>
                            <div className='col-xs-12 col-md-12'>
                                <div className="input-group mb-3">
                                    <span className="input-group-text" id="basic-addon1">Qr code</span>
                                    <input
                                        //autoFocus={true}
                                        type="text"
                                        name="barcode"
                                        id="barcode"
                                        className={`form-control`}
                                        placeholder=""
                                        defaultValue={barcode}
                                        //onChange={formik2.handleChange}
                                        //disabled
                                        onChange={(e) => setBarcode(e.target.value)}
                                    //readOnly
                                    />
                                </div>
                            </div>
                        </div> */}
                        </div>
                    </div>
                </div>
            </div>
            {showAutreDestination && <><div className="modal" id="autreDestination" style={{ display: 'block' }} data-bs-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true" tabIndex={-1}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Colis n'existe pas dans la bdd local</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => setShowAutreDestination(false)}></button>
                        </div>

                        <div className="modal-body">
                            <h5>Tracking n'existe pas dans la bdd local </h5>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => setShowAutreDestination(false)}>Quitter</button>
                        </div>
                    </div>
                </div>
            </div>
                <div className="modal-backdrop show"></div></>
            }
            {zoneIntrouvable && <><div className="modal" id="zoneIntrouvable" style={{ display: 'block' }} data-bs-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true" tabIndex={-1}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            {/* <h4 className="modal-title">Aucune zone</h4> */}
                            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => setZoneIntrouvable(false)}></button>
                        </div>

                        <div className="modal-body">
                            <h5>Vérifiez vos paramètres de zones. Aucune zone n'est paramétrée ou aucune zone n'est active.</h5>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => setZoneIntrouvable(false)}>Quitter</button>
                        </div>
                    </div>
                </div>
            </div>
                <div className="modal-backdrop show"></div></>
            }
        </>
    );
}

export default Scan;