import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CustomToast } from '../../components/Toast/CustomToast';
import useDebounce from '../../hooks/useDebounce';
import { addDays, subDays, format } from 'date-fns';
import { axiosInstance, Affectation, Client, MyDashboard } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';

const Dashboard = () => {
    const { authData } = useContext(AuthContext);

    const [affectationsTotal, setAffectationsTotal] = useState([]);
    const [affectationsTotalToday, setAffectationsTotalToday] = useState([]);
    const [importation, setImportation] = useState([]);

    const GetAffectationTotalStatutsCounts = async () => {
        try {

            const response = await axiosInstance.get(`${MyDashboard}/GetAffectationTotalStatutsCounts`);
            const resp = await response.data;
            setAffectationsTotal(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const GetAffectationStatutsTodayCounts = async () => {
        try {
            // const currentDate = new Date();
            // const dateDebut = subDays(currentDate, 7);
            // const dateFin = addDays(currentDate, 7);

            // const queryParams = new URLSearchParams({
            //     dateDebut: format(dateDebut, 'yyyy-MM-dd'),
            //     dateFin: format(dateFin, 'yyyy-MM-dd')
            // });

            //const response = await axiosInstance.get(`${MyDashboard}/GetAffectationStatutsTodayCounts?${queryParams}`);
            const response = await axiosInstance.get(`${MyDashboard}/GetAffectationStatutsTodayCounts`);
            const resp = await response.data;
            setAffectationsTotalToday(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const GetColisImporterTotalCounts = async () => {
        try {
            const response = await axiosInstance.get(`${MyDashboard}/GetColisImporterTotalCounts`);
            const resp = await response.data;
            setImportation(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        //Load Data
        GetAffectationTotalStatutsCounts();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData]);

    useEffect(() => {
        //Load Data
        GetAffectationStatutsTodayCounts();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData]);

    useEffect(() => {
        //Load Data
        GetColisImporterTotalCounts();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData]);

    return (
        <div className='row mt-1 mb-1'>
            <div className='col-xs-12 col-md-12'>
                <div className="card">
                    <div className="card-header">Dashboard</div>
                    <div className="card-body">
                        <div className='row mt-1'>
                            <div className='col-xs-12 col-md-4 mb-1'>
                                <div className="card">
                                    <div className="card-header">Nombre de colis total</div>
                                    <div className="card-body">
                                        <div className='row'>
                                            <p>Affectés: {affectationsTotal.countAffectes?affectationsTotal.countAffectes:0}</p>
                                            <p>Retournés aux vendeurs: {affectationsTotal.countRetourneVendeur?affectationsTotal.countRetourneVendeur:0}</p>
                                            <p>Total: {affectationsTotal.countAffectes + affectationsTotal.countRetourneVendeur}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xs-12 col-md-4 mb-1'>
                                <div className="card">
                                    <div className="card-header">Nombre de colis aujourd'huit</div>
                                    <div className="card-body">
                                        <div className='row'>
                                            <p>Affectés: {affectationsTotalToday.countAffectes?affectationsTotalToday.countAffectes:0}</p>
                                            <p>Retournés aux vendeurs: {affectationsTotalToday.countRetourneVendeur?affectationsTotalToday.countRetourneVendeur:0}</p>
                                            <p>Total: {affectationsTotalToday.countAffectes + affectationsTotalToday.countRetourneVendeur}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xs-12 col-md-4'>
                                <div className="card">
                                    <div className="card-header">Nombre de colis injectés</div>
                                    <div className="card-body">
                                        <div className='row'>
                                            <p>Aujourd'huit: {importation.colisImportesAujourdhui?importation.colisImportesAujourdhui:0}</p>
                                            <p>Total: {importation.totalColisImportes?importation.totalColisImportes:0}</p>
                                            <p style={{ opacity: 0 }}>Total:</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
