import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
const Config = () => {
    const { authData } = useContext(AuthContext);
    return (
        <div className='row mt-1 mb-1 sticky-top'>
            <div className='col-xs-12 col-md-12'>
                <div className="card">
                    <div className="card-body">
                        <div className='row justify-content-center align-items-center'>
                        {authData?.role !== "visiteur" && <div className='col-xs-2 col-md-2'>
                                <Link to="/utilisateurs" className="btn btn-light form-control" replace>
                                    <i className="fa fa-users"> Utilisateurs</i>
                                </Link>
                            </div>}
                            <div className='col-xs-2 col-md-2'>
                                <Link to="/centres" className="btn btn-light form-control" replace>
                                    <i className="fa fa-home"> Centres</i>
                                </Link>
                            </div>
                            <div className='col-xs-2 col-md-2'>
                                <Link to="/zones" className="btn btn-light form-control" replace>
                                    <i className="fa fa-bullseye"> Zones</i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Config;
