import React, { useEffect, useState, useRef, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CustomToast } from '../../../components/Toast/CustomToast';
import useDebounce from '../../../hooks/useDebounce';
import { axiosInstance, Client, Zone } from '../../../services/api';
import { Pagination } from 'react-bootstrap';
import { AuthContext } from '../../../contexts/AuthContext';
import Form from 'react-bootstrap/Form';
import { Typeahead, AsyncTypeahead } from 'react-bootstrap-typeahead';
const ZoneBoutique = () => {
    const { authData } = useContext(AuthContext);
    const navigate = useNavigate();
    const { id } = useParams();//idZone  
    const [selectedZone, setSelectedZone] = useState('');
    const [boxs, setBoxs] = useState([]);
    const [etagers, setEtagers] = useState([]);
    const [etageresBoutiques, setEtageresBoutiques] = useState([]);
    const [boutiques, setBoutiques] = useState([]);
    const [selectedBox, setSelectedBox] = useState('');
    const [selectedEtagere, setSelectedEtagere] = useState('');

    const [enteredBoutiqueText, setEnteredBoutiqueText] = useState('');
    const debouncedText = useDebounce(enteredBoutiqueText, 300);

    //console.log(id)
    const GetBox = async () => {
        try {
            const queryParams = new URLSearchParams({});

            if (id != null) {
                queryParams.append("idZone", id);
            }

            const response = await axiosInstance.get(`${Zone}/GetBoxByIdZone?${queryParams}`);
            const resp = await response.data;
            setSelectedZone(resp[0].libelleZone);
            setBoxs(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const GetEtagere = async () => {
        try {
            const queryParams = new URLSearchParams({});

            if (selectedBox != null && selectedBox !== "") {
                queryParams.append("idBox", selectedBox);
            } else {
                return
            }


            const response = await axiosInstance.get(`${Zone}/GetEtageres?${queryParams}`);
            const resp = await response.data;
            setEtagers(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const handleBoxChange = (event) => {
        // GetEtagere(event.target.value)
        setBoxError(null);
        setSelectedBox(event.target.value);
    };

    const getBoutiques = async (nomBoutique) => {
        try {
            const queryParams = new URLSearchParams({
                pageNumber: 1,
                pageSize: 10
            });

            if (nomBoutique !== '') {
                queryParams.append("nom", nomBoutique);
            }
            const response = await axiosInstance.get(`${Client}/GetClientsAutocomplete?${queryParams}`);
            const resp = response.data;
            //console.log(resp.data);
            setBoutiques(resp.data);
            //console.log(vehicules);
        }
        catch (err) {
            console.log(err);
        }
    }

    const handleEtagereChange = (event) => {
        setEtagereError(null);
        setSelectedEtagere(event.target.value);
    };


    useEffect(() => {
        //Load Data
        //console.log("Effect is running");

        GetBox();

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
            //console.log("Cleanup");
        };
    }, [authData, id]);

    useEffect(() => {
        //Load Data
        GetEtagere();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
            //console.log("Cleanup");
        };
    }, [authData, selectedBox]);

    useEffect(() => {
        //if (debouncedText) {
        getBoutiques(debouncedText);
        //}
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, debouncedText]);

    const [selectedClients, setSelectedClients] = useState([]);
    const [boxError, setBoxError] = useState(null);
    const [etagereError, setEtagereError] = useState(null);
    const [clientsError, setClientsError] = useState(null);

    const handleClientSelection = (selected) => {
        setClientsError(null);
        setSelectedClients(selected);
        //console.log(selected)
    };

    // const handleSaveSelectedClients = () => {
    //     // Sauvegarder les clients sélectionnés
    //     console.log(selectedClients);

    //     // Récupérer les données de l'étagère sélectionnée
    //     const selectedEtagereData = etagers.find(etagere => etagere.idEtagere === selectedEtagere);

    //     // Créer une nouvelle entrée pour les étagères et boutiques affectées
    //     const newEntry = {
    //       libelleEtagere: selectedEtagereData.libelleBox + '/' + selectedEtagereData.libelle,
    //       Clients: selectedClients.map(client => client.nom).join(', '), // Mettez en forme les noms des clients
    //     };

    //     // Mettre à jour l'état avec la nouvelle entrée
    //     setEtageresBoutiques([...etageresBoutiques, newEntry]);
    //   };

    const handleSaveSelectedClients = () => {
        // Réinitialisez les messages d'erreur
        setBoxError(null);
        setEtagereError(null);
        setClientsError(null);

        // Vérifiez si les champs obligatoires sont vides
        if (!selectedBox) {
            setBoxError("Veuillez sélectionner une box.");
        }

        if (!selectedEtagere) {
            setEtagereError("Veuillez sélectionner une étagère.");
        }

        if (selectedClients.length === 0) {
            setClientsError("Veuillez sélectionner au moins un client.");
        }

        // Si un champ est vide, arrêtez la fonction
        if (!selectedBox || !selectedEtagere || selectedClients.length === 0) {
            return;
        }

        // Vérifiez si l'étagère est déjà présente dans le tableau
        const isEtagereAlreadyPresent = etageresBoutiques.some(item => item.libelleEtagere === selectedEtagere);

        if (isEtagereAlreadyPresent) {
            setEtagereError("L'étagère est déjà présente dans le tableau.");
            return;
        }

        // Récupérez les données de l'étagère sélectionnée
        const selectedEtagereData = etagers.find(etagere => etagere.idEtagere === selectedEtagere);

        // Créez une nouvelle entrée pour les étagères et boutiques affectées
        // const newEntry = {
        //     libelleEtagere: selectedEtagereData.libelleBox + '/' + selectedEtagereData.libelle,
        //     idEtagere: selectedEtagereData.idEtagere,
        //     Clients: selectedClients.map(client => client.displayNom).join(', '), // Mettez en forme les noms des clients
        // };

        const newEntry = {
            libelleEtagere: selectedEtagereData.libelleBox + '/' + selectedEtagereData.libelle,
            idEtagere: selectedEtagereData.idEtagere,
            displayClients: selectedClients.map(client => client.displayNom).join(', '),
            Clients: selectedClients.map(client => ({
                idClient: client.id,
            })),
        };

        //console.log(newEntry)

        // Mettez à jour l'état avec la nouvelle entrée
        setEtageresBoutiques([...etageresBoutiques, newEntry]);

        // Réinitialisez les champs après une validation réussie
        setSelectedBox('');
        setSelectedEtagere('');
        setSelectedClients([]);
    };

    const handleSearch = async (query) => {
        getBoutiques(query);
    };

    const deleteEntry = (index) => {
        const updatedEtageresBoutiques = [...etageresBoutiques];
        updatedEtageresBoutiques.splice(index, 1);
        setEtageresBoutiques(updatedEtageresBoutiques);
    };

    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async () => {

        try {
            setIsSubmitting(true);

            if (etageresBoutiques.length === 0) {
                CustomToast("Aucune boutique ", 'error');
                return
            }
            const postData = etageresBoutiques.map(item => ({
                idEtagere: item.idEtagere,
                boutiques: item.Clients.map(client => client.id),
                idUtilisateurCRUD: authData.idUtilisateur,
            }));
            const response = await axiosInstance.post(`${Zone}/CreateZoneBoutique`, postData);
            if (response?.status === 200) {
                
                // Réinitialisez l'état après la soumission réussie
                setEtageresBoutiques([]);
                setSelectedBox('');
                setSelectedEtagere('');
                setSelectedClients([]);
                CustomToast("Zone paramétrée avec succès", 'success');
                navigate(`/zones`);
            }


        } catch (error) {
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
        } finally {
            setIsSubmitting(false);
        }
    };



    return (
        <>
            <div className='row mt-1 mb-1'>
                <div className='col-xs-12 col-md-12'>
                    <div className="card">
                        <div className="card-body">
                            <div className='row justify-content-center align-items-center'>
                            {authData?.role !== "visiteur" && <div className='col-xs-2 col-md-2'>
                                    <Link to="/utilisateurs" className="btn btn-light form-control" replace>
                                        <i className="fa fa-users"> Utilisateurs</i>
                                    </Link>
                                </div>}
                                <div className='col-xs-2 col-md-2'>
                                    <Link to="/centres" className="btn btn-light form-control" replace>
                                        <i className="fa fa-home"> Centres</i>
                                    </Link>
                                </div>
                                <div className='col-xs-2 col-md-2'>
                                    <Link to="/zones" className="btn btn-light form-control" replace>
                                        <i className="fa fa-bullseye"> Zones</i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row mt-1 mb-1'>
                <div className='col-xs-12 col-md-12'>
                    <div className="card">
                        <form onSubmit={handleSubmit}>
                            <div className="card-header">Affectation boutiques</div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xs-2 col-md-2">
                                        <label className="form-label">Zone<span className='text-danger fs-6'>*</span></label>
                                        <input
                                            type="text"
                                            name="libelleZone"
                                            id="libelleZone"
                                            className={`form-control`}
                                            placeholder=""
                                            value={selectedZone}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-xs-2 col-md-2">
                                        <label className="form-label">Box<span className='text-danger fs-6'>*</span></label>
                                        <div className="input-group mb-3">
                                            <select
                                                id="idBox"
                                                name="idBox"
                                                className={`form-control ${boxError ? 'border-danger' : ''}`}
                                                value={selectedBox}
                                                onChange={handleBoxChange}
                                            >
                                                <option value="">Veuillez sélectionner</option>
                                                {boxs.map((box) => (
                                                    <option key={uuidv4()} value={box.idBox}>{box.libelle}</option>
                                                ))}
                                            </select>
                                        </div>
                                        {boxError && <div className="text-danger" role="alert">{boxError}</div>}
                                    </div>
                                    <div className="col-xs-2 col-md-2">
                                        <label className="form-label">Étagères<span className='text-danger fs-6'>*</span></label>
                                        <div className="input-group mb-3">
                                            <select
                                                id="idEtagere"
                                                name="idEtagere"
                                                className={`form-control ${etagereError ? 'border-danger' : ''}`}
                                                value={selectedEtagere}
                                                onChange={handleEtagereChange}
                                            >
                                                <option value="">Veuillez sélectionner</option>
                                                {etagers.map((etagere) => (
                                                    <option key={uuidv4()} value={etagere.idEtagere}>{etagere.libelleBox + '/' + etagere.libelle}</option>
                                                ))}
                                            </select>
                                        </div>
                                        {etagereError && <div className="text-danger" role="alert">{etagereError}</div>}
                                    </div>

                                    <div className="col-xs-5 col-md-5">
                                        <label className="form-label">Boutiques<span className='text-danger fs-6'>*</span></label>
                                        <div className="input-group mb-3">
                                            <AsyncTypeahead
                                                id="client-search"
                                                labelKey="displayNom"
                                                // isLoading={isLoading} // Vous pouvez décommenter cela si nécessaire
                                                multiple
                                                options={boutiques}
                                                onChange={handleClientSelection}
                                                onSearch={handleSearch}
                                                placeholder="Rechercher un ou plusieurs clients..."
                                                selected={selectedClients}
                                                className={`form-control ${clientsError ? 'border-danger' : ''}`}
                                            />
                                        </div>
                                        {clientsError && <div className="text-danger" role="alert">{clientsError}</div>}
                                    </div>
                                    <div className="col-xs-2 col-md-1">
                                        <label className="form-label" style={{ opacity: 0 }}>Étagères</label>
                                        <button className="btn btn-success form-control" type="button" onClick={handleSaveSelectedClients}>
                                            <i className="fa fa-plus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className='row justify-content-center align-items-center g-2'>
                                    <div className="col-xs-12 col-md-6">
                                        <div className="table-responsive">
                                            <table className="table table-hover table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Etagère</th>
                                                        <th>Boutiques</th>
                                                        <th className='text-center'>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {etageresBoutiques.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                {item.libelleEtagere}
                                                            </td>

                                                            <td>
                                                                {item.displayClients}
                                                            </td>

                                                            <td className='text-center'>
                                                                <Link to="#" style={{ textDecoration: "none" }} onClick={() => deleteEntry(index)}>
                                                                    <span className="fa fa-trash text-warning fa-lg"></span>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <div className='row justify-content-center align-items-center'>
                                    <div className='col-xs-2 col-md-2'>
                                        <button type="submit" className="btn btn-success form-control">
                                            <i className="fa fa-save"> Enregistrer</i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ZoneBoutique;
