import React, { useEffect, useState, useRef, useContext } from 'react';
import { useFormik } from 'formik';
import { CustomToast } from '../../components/Toast/CustomToast';
import ConfirmationDialog from '../../components/confirmation/ConfirmationDialog';
import useDebounce from '../../hooks/useDebounce';
import { axiosInstance, ImportColis, Client } from '../../services/api';
import { Pagination } from 'react-bootstrap';
import { AuthContext } from '../../contexts/AuthContext';
import { format } from 'date-fns'

const ImporterColis = () => {
    const { authData } = useContext(AuthContext);

    const [clients, setClients] = useState([]);
    const [upload, setUpload] = useState(false);
    const [uploadError, setUploadError] = useState(false);
    const [uploadRetirer, setUploadRetirer] = useState(false);
    const [uploadRenommer, setUploadRenommer] = useState(false);
    const [renommerTous, setRenommerTous] = useState(false);
    const [uploadRetirerError, setUploadRetirerError] = useState(false);
    const [uploadRenommerError, setUploadRenommerError] = useState(false);
    const [filtredUpload, setFiltredUpload] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState('');
    const debouncedSearchText = useDebounce(search, 300);

    const [pathImporterColis, setPathImporterColis] = useState(null);
    const pathImporterColisRef = useRef(null)
    const [pathRetirer, setPathRetirer] = useState(null);
    const [pathRenommer, setPathRenommer] = useState(null);
    const pathRefRetirer = useRef(null)
    const pathRefRenommer = useRef(null)

    const formik1 = useFormik({
        initialValues: {
            idUtilisateur: '',
        },
        onSubmit: async (values) => {
            try {
                //console.log(values);
                const formData = new FormData();
                formData.append('idCentre', authData.idCentre);
                formData.append('libelleCentre', authData.libelleCentre);
                formData.append('idUtilisateur', authData.idUtilisateur);
                formData.append('filteToUpload', pathImporterColis);

                const response = await axiosInstance.post(ImportColis, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                if (response?.status === 200 && response?.data === "New data") {
                    GetColis(1, pageSize)
                }
                CustomToast("Le fichier a été importé avec succès", 'success');
                setUpload(false);
                setUploadError(false);
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Aucun fichier") {
                    CustomToast("Séléctionnez un fichier", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Template error") {
                    CustomToast("Veuillez vérifier votre fichier", 'error');
                    setUploadError(true)
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    //Table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredUpload].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredUpload(sortedTable);
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
    };

    const getStartIndex = () => {
        return (pageNumber - 1) * pageSize + 1;
    };

    const getEndIndex = () => {
        const endIndex = pageNumber * pageSize;
        return endIndex > totalItems ? totalItems : endIndex;
    };

    const GetColis = async (page, newPerPage) => {
        try {
            setLoading(true);

            const queryParams = new URLSearchParams({
                pageNumber: page,
                pageSize: newPerPage
            });

            if (debouncedSearchText != null) {
                queryParams.append("text", debouncedSearchText);
            }

            if (authData?.role != null && authData?.role !== "super admin") {
                queryParams.append("idCentre", authData.idCentre);
            }

            const response = await axiosInstance.get(`${ImportColis}/GetColisImport?${queryParams}`);
            const resp = await response.data;
            setClients(resp.data);
            setFiltredUpload(resp.data);
            setPageNumber(resp.pageNumber);
            setPageSize(resp.pageSize);
            setTotalItems(resp.totalItems);
            setTotalPages(resp.totalPages);
            setLoading(false);
        }
        catch (err) {
            console.log(err);
            setLoading(false);
        }
    }

    const handlePathChange = (e) => {
        setPathImporterColis(e.target.files[0]);
    };

    const handlePathChangeRetirer = (e) => {
        setPathRetirer(e.target.files[0]);
    };

    const handlePathChangeRenommer = (e) => {
        setPathRenommer(e.target.files[0]);
    };

    const getImageFileName = (file) => {
        return file ? file.name : 'No file selected';
    };


    const correction = async () => {
        try {
            const response = await axiosInstance.post(`${Client}/correction`);
            CustomToast("Correction terminée avec succès", 'success');
        } catch (error) {
            console.error(error);
        }
    };

    const formik2 = useFormik({
        initialValues: {
            idUtilisateur: '',
        },
        onSubmit: async (values) => {
            try {
                //console.log(values);
                const formData = new FormData();
                formData.append('idCentre', authData.idCentre);
                formData.append('libelleCentre', authData.libelleCentre);
                formData.append('idUtilisateur', authData.idUtilisateur);
                formData.append('filteToUpload', pathRetirer);//retirerColis

                const response = await axiosInstance.post(`${ImportColis}/retirerColis`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                GetColis(1, pageSize)
                CustomToast("Le fichier a été importé avec succès", 'success');
                setUploadRetirer(false);
                setUploadRetirerError(false)
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Aucun fichier") {
                    CustomToast("Séléctionnez un fichier", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Template error") {
                    CustomToast("Veuillez vérifier votre fichier", 'error');
                    setUploadRetirerError(true)
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    const formik3 = useFormik({
        initialValues: {
            idUtilisateur: '',
        },
        onSubmit: async (values) => {
            try {
                //console.log(values);
                const formData = new FormData();
                formData.append('idCentre', authData.idCentre);
                formData.append('libelleCentre', authData.libelleCentre);
                formData.append('idUtilisateur', authData.idUtilisateur);
                formData.append('filteToUpload', pathRenommer);//retirerColis

                const response = await axiosInstance.post(`${Client}/correctionPartiel`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                GetColis(1, pageSize)
                CustomToast("Le fichier a été importé avec succès", 'success');
                setUploadRenommer(false);
                setUploadRenommerError(false)
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Aucun fichier") {
                    CustomToast("Séléctionnez un fichier", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Template error") {
                    CustomToast("Veuillez vérifier votre fichier", 'error');
                    setUploadRenommerError(true)
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });


    // useEffect(() => {
    //     //Load Data

    //     GetColis(pageNumber, pageSize);
    //     return () => {
    //         // Perform any necessary cleanup (e.g., cancel pending requests)
    //     };
    // }, [authData, pageNumber, pageSize]);

    // useEffect(() => {
    //     //Load Data
    //     GetColis(1, pageSize);
    //     return () => {
    //         // Perform any necessary cleanup (e.g., cancel pending requests)
    //     };
    // }, [debouncedSearchText]);

    // useEffect(() => {
    //     //Load Data
    //     GetColis(debouncedSearchText ? 1 : pageNumber, pageSize);

    //     return () => {
    //         // Perform any necessary cleanup (e.g., cancel pending requests)
    //     };
    // }, [authData, debouncedSearchText, pageNumber, pageSize]);

    const renderPaginationItems = () => {
        const startPage = Math.max(1, pageNumber - 5); // Limiter le nombre de pages à afficher à 5 avant la page actuelle
        const endPage = Math.min(totalPages, pageNumber + 5); // Limiter le nombre de pages à afficher à 5 après la page actuelle

        return Array.from({ length: endPage - startPage + 1 }).map((_, index) => {
            const page = startPage + index;
            return (
                <Pagination.Item
                    key={page}
                    active={page === pageNumber}
                    onClick={() => handlePageChange(page)}
                >
                    {page}
                </Pagination.Item>
            );
        });
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Annuler le comportement par défaut de la touche "Entrée"
        }
    };

    const handleCancelDelete = () => {
        setRenommerTous(false);
    };

    const handleImporterColis = (val) => {
        setUpload(val);
        setUploadError(false);
        setPathImporterColis(null);
    };

    const handleRetirerColis = (val) => {
        setUploadRetirer(val);
        setUploadRetirerError(false);
        setPathRetirer(null);
    };

    const handleRnommerColis = (val) => {
        setUploadRenommer(val);
        setUploadRenommerError(false);
        setPathRenommer(null);
    };

    useEffect(() => {
        //Load Data
        GetColis(pageNumber, pageSize);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, pageNumber, pageSize]);

    useEffect(() => {
        //Load Data
        GetColis(1, pageSize);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, debouncedSearchText]);

    return (
        <>
            <div className='row mt-1 mb-1'>
                <div className='col-xs-12 col-md-12'>
                    <div className="card">
                        <div className="card-header">Colis</div>
                        <div className="card-body">
                            <div className="d-flex bd-highlight mb-3">
                                <div className="me-auto p-2 bd-highlight">
                                    <div className="search">
                                        <form className="form-inline">
                                            <input
                                                className="form-control mr-sm-2"
                                                type="search" placeholder="Search"
                                                aria-label="Rechercher"
                                                value={search}
                                                onChange={(e) => setSearch(e.target.value)}
                                                autoFocus={true}
                                                onKeyDown={handleKeyPress}
                                            />
                                        </form>
                                    </div>
                                </div>
                                {authData?.role !== "visiteur" && <div className="p-2 bd-highlight" title='Retirer des colis'>
                                    <button className="btn btn-warning btn-lg form-control" type="button" onClick={() => handleRetirerColis(true)}>
                                        <i className="fa fa-trash"> Retirer des colis</i>
                                    </button>
                                </div>}
                                {authData?.role !== "visiteur" && <div className="p-2 bd-highlight" title='Corriger le nom de toutes les boutiques'>
                                    <button className="btn btn-primary btn-lg form-control" type="button" onClick={() => setRenommerTous(true)}>
                                        <i className="fa fa-pencil"> Toutes les boutiques</i>
                                    </button>
                                </div>}
                                {authData?.role !== "visiteur" && <div className="p-2 bd-highlight" title='Corriger partiel des noms de boutiques'>
                                    <button className="btn btn-info btn-lg form-control" type="button" onClick={() => handleRnommerColis(true)}>
                                        <i className="fa fa-pencil"> Correction partiel</i>
                                    </button>
                                </div>}
                                {authData?.role !== "visiteur" && <div className="p-2 bd-highlight" title='Importer les colis'>
                                    <button className="btn btn-success btn-lg form-control" type="button" onClick={() => handleImporterColis(true)}>
                                        <i className="fa fa-upload"> Importer les colis</i>
                                    </button>
                                </div>}
                            </div>
                            <div className='row justify-content-center align-items-center g-2'>
                                <div className="col-xs-12 col-md-12">
                                    <div className="table-responsive">
                                        <table className="table table-hover table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>ID boutique</th>
                                                    <th>Boutique</th>
                                                    <th>Tracking</th>
                                                    <th>Date injection</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {clients.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            {item.idClientFromFile}
                                                        </td>
                                                        <td>
                                                            {item.nom}
                                                        </td>
                                                        <td>
                                                            {item.tracking}
                                                        </td>
                                                        <td>
                                                            {format(item.dateCreation, "dd/MM/yyyy HH:mm")}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="d-flex justify-content-between mt-3">
                                        <div>
                                            {totalPages >= 1 && (
                                                <Pagination className="pagination-sm">
                                                    <Pagination.Prev
                                                        onClick={() => handlePageChange(pageNumber - 1)}
                                                        disabled={pageNumber === 1}
                                                    >
                                                        <span aria-hidden="true">&laquo;</span>
                                                    </Pagination.Prev>
                                                    {renderPaginationItems()}
                                                    <Pagination.Next
                                                        onClick={() => handlePageChange(pageNumber + 1)}
                                                        disabled={pageNumber === totalPages}
                                                    >
                                                        <span aria-hidden="true">&raquo;</span>
                                                    </Pagination.Next>
                                                </Pagination>
                                            )}
                                        </div>

                                        <div className="mb-3">
                                            <p>
                                                Affichage de {getStartIndex()} à {getEndIndex()} sur {totalItems} entrées
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {upload && <><div className="modal" id="uploadColis" style={{ display: 'block' }} data-bs-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true" tabIndex={-1}>
                <form onSubmit={formik1.handleSubmit}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Upload colis</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => handleImporterColis(false)}></button>
                            </div>

                            <div className="modal-body">
                                <div className='row mt-0 mb-0'>
                                    <div className="col">
                                        <label className="form-label">Upload<span className='text-danger fs-6'>*</span></label>
                                        <input
                                            ref={pathImporterColisRef}
                                            id="pathImporterColis"
                                            name="pathImporterColis"
                                            className={`form-control`}
                                            type="file"
                                            onChange={handlePathChange}
                                        />
                                        {pathImporterColis && <span style={{ fontSize: "10px" }}>{getImageFileName(pathImporterColis)}
                                            <span
                                                className="fa fa-trash"
                                                onClick={(e) => { setPathImporterColis(null); if (pathImporterColisRef.current) { pathImporterColisRef.current.value = ''; } }}></span>
                                        </span>}
                                    </div>
                                </div>
                                <div className='row mt-5 mb-0'>
                                    <p>
                                        Le fichier doit être composé de :
                                        <ol>
                                            <li>
                                                Tracking
                                            </li>
                                            <li>
                                                Id boutique
                                            </li>
                                            <li>
                                                Boutique
                                            </li>
                                        </ol>
                                    </p>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => handleImporterColis(false)}>Annuler</button>
                                <button type="submit" className="btn btn-success" data-bs-dismiss="modal">Enregistrer</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
                <div className="modal-backdrop show"></div></>
            }

            {uploadRetirer && <><div className="modal" id="retirerColis" style={{ display: 'block' }} data-bs-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true" tabIndex={-1}>
                <form onSubmit={formik2.handleSubmit}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Retirer des colis</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => handleRetirerColis(false)}></button>
                            </div>

                            <div className="modal-body">
                                <div className='row mt-0 mb-0'>
                                    <div className="col">
                                        <label className="form-label">Upload<span className='text-danger fs-6'>*</span></label>
                                        <input
                                            ref={pathRefRetirer}
                                            id="pathRetirer"
                                            name="pathRetirer"
                                            className={`form-control`}
                                            type="file"
                                            onChange={handlePathChangeRetirer}
                                        />
                                        {pathRetirer && <span style={{ fontSize: "10px" }}>{getImageFileName(pathRetirer)}
                                            <span
                                                className="fa fa-trash"
                                                onClick={(e) => { setPathRetirer(null); if (pathRefRetirer.current) { pathRefRetirer.current.value = ''; } }}></span>
                                        </span>}
                                    </div>
                                </div>
                                <div className='row mt-5 mb-0'>
                                    <p>
                                        Le fichier doit être composé de :
                                        <ol>
                                            <li>
                                                Tracking
                                            </li>
                                        </ol>
                                    </p>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => handleRetirerColis(false)}>Annuler</button>
                                <button type="submit" className="btn btn-success" data-bs-dismiss="modal">Enregistrer</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
                <div className="modal-backdrop show"></div></>
            }

            {uploadRenommer && <><div className="modal" id="correctionNomsBoutiques" style={{ display: 'block' }} data-bs-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true" tabIndex={-1}>
                <form onSubmit={formik3.handleSubmit}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Renommer les boutiques</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => handleRnommerColis(false)}></button>
                            </div>

                            <div className="modal-body">
                                <div className='row mt-0 mb-0'>
                                    <div className="col">
                                        <label className="form-label">Upload<span className='text-danger fs-6'>*</span></label>
                                        <input
                                            ref={pathRefRenommer}
                                            id="pathRenommer"
                                            name="pathRenommer"
                                            className={`form-control`}
                                            type="file"
                                            onChange={handlePathChangeRenommer}
                                        />
                                        {pathRenommer && <span style={{ fontSize: "10px" }}>{getImageFileName(pathRenommer)}
                                            <span
                                                className="fa fa-trash"
                                                onClick={(e) => { setPathRenommer(null); if (pathRefRenommer.current) { pathRefRenommer.current.value = ''; } }}></span>
                                        </span>}
                                    </div>
                                </div>
                                <div className='row mt-5 mb-0'>
                                    <p>
                                        Le fichier doit être composé de :
                                        <ol>
                                            <li>
                                                Tracking
                                            </li>
                                            <li>
                                                Id boutique
                                            </li>
                                            <li>
                                                Boutique
                                            </li>
                                        </ol>
                                    </p>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => handleRnommerColis(false)}>Annuler</button>
                                <button type="submit" className="btn btn-success" data-bs-dismiss="modal">Enregistrer</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
                <div className="modal-backdrop show"></div></>
            }

            <ConfirmationDialog
                open={renommerTous}
                onClose={handleCancelDelete}
                onConfirm={correction}
                titleMessage="Corriger le nom de toutes les boutiques"
                bodyMessage={"Voulez-vous vraiment corriger toutes les noms de boutiques"}
                btnCancelText="Annuler"
                btnConfirmText="Corriger"
            />

        </>
    );
}

export default ImporterColis;
