import XLSX from "sheetjs-style";

const ExportToExcel = (dataJson, fileName, fileExtension, sheetName, header1) => {
  //Had to create a new workbook and then add the header
  const wb = XLSX.utils.book_new();
  const ws = XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
  XLSX.utils.sheet_add_aoa(ws, header1);

  //Starting in the second row to avoid overriding and skipping headers
  XLSX.utils.sheet_add_json(ws, dataJson, { origin: 'A2', skipHeader: true });

  XLSX.utils.book_append_sheet(wb, ws, sheetName);

  XLSX.writeFile(wb, fileName + fileExtension);

  // Generate the XLSX file as an ArrayBuffer
  const xlsxBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

  // Convert the ArrayBuffer to a Blob
  const blob = new Blob([xlsxBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

  return(blob)
};

export { ExportToExcel };