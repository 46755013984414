import React, { useState, useContext, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CustomToast } from '../../components/Toast/CustomToast';
import { axiosInstance, UserAuthentication } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';
import './Login.css'

const Login = () => {
    const { login } = useContext(AuthContext);

    const [isAuthLoading, setAuthLoading] = useState(false);
    const navigate = useNavigate();
    const [isHoveredLoginButton, setIsHoveredLoginButton] = useState(false);

    const handleMouseEnterFormLogin = () => {
        setIsHoveredLoginButton(true);
    };

    const handleMouseLeaveFormLogin = () => {
        setIsHoveredLoginButton(false);
    };

    const { handleChange, values, handleSubmit, touched, errors } = useFormik({
        initialValues: {
            username: '',
            password: ''
        },
        validationSchema: Yup.object({
            username: Yup.string()
                .required("Username obligatoire"),
            password: Yup.string()
                .required("Mot de passe obligatoire")
        }),
        onSubmit: async (values) => {
            try {
                const response = await axiosInstance.post(`${UserAuthentication}/authenticate`, values);

                let authInfo = JSON.stringify(response.data);
                localStorage.setItem('authData', authInfo);

                login(JSON.parse(authInfo));
                setAuthLoading(true);
                //console.log( authInfo);
                CustomToast(`Bienvenue, ${values.username}!`, 'success');
                //console.log(response.data.role)
                if (response.data.role === "operateur") {
                    navigate('/scan', { replace: true });
                } else {
                    navigate('/empty', { replace: true });
                }
            } catch (error) {
                //setAuthLoading(false);
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data.statutResponse === "User not found - Incorrect username") {
                    CustomToast("Username ou mot de passe incorrectes", 'error');
                } else if (error.response?.status === 400 && error.response?.data.statutResponse === "User not found - Incorrect password") {
                    CustomToast("Username ou mot de passe incorrectes", 'error');
                } else if (error.response?.status === 400 && error.response?.data.statutResponse === "User is not authorized") {
                    CustomToast("L'utilisateur n'est pas autorisé à se connecter", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });
    return (
        <div className="container-fluid pt-3 pb-3">
            <div className="row justify-content-center align-items-center mt-5">
                <form onSubmit={handleSubmit}>
                    <div className="col-xs-8 col-sm-8 col-md-3 offset-xs-2 offset-sm-2 offset-md-4">
                        <div className={`card rounded-4 ${isHoveredLoginButton ? 'loginHover' : 'login-wrap'}`}>
                            {/* <img src={logo} className="card-img-top" width="100px" alt="speedmail logo" /> */}
                            {/* <div className="card-header"></div> */}
                            <div className="card-body">
                                <div className="row mt-5">
                                    <div className="col">
                                        <div className="form-group">
                                            <input
                                                id="username"
                                                name="username"
                                                type="text"
                                                className="form-control rounded-left"
                                                placeholder="Nom d'utilisateur"
                                                defaultValue={values.username}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-2 mb-5">
                                    <div className="col">
                                        <div className="form-group">
                                            <input
                                                id="password"
                                                name="password"
                                                type="password"
                                                className="form-control rounded-left"
                                                placeholder="Mot de passe"
                                                defaultValue={values.password}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3 justify-content-center align-items-center">
                                    <div className="col-xs-12 col-md-4">
                                        <button type="submit"
                                            className="btn seConnecter rounded submit"
                                            onMouseEnter={handleMouseEnterFormLogin}
                                            onMouseLeave={handleMouseLeaveFormLogin}
                                        >Se connecter</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Login;
