import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import useDebounce from '../../hooks/useDebounce';
import { axiosInstance, Affectation } from '../../services/api';
import { Pagination } from 'react-bootstrap';
import { AuthContext } from '../../contexts/AuthContext';
import { CustomToast } from '../../components/Toast/CustomToast';
import { format} from 'date-fns'
import {PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import qrCode from 'qrcode';
import fontkit from '@pdf-lib/fontkit';
//import AmiriFont from '../../fonts/Amiri/Amiri-Regular.ttf';
import AmiriFont from '../../fonts/El_Messiri/ElMessiri-VariableFont_wght.ttf';

const Colis = () => {
    const { authData } = useContext(AuthContext);

    const [affectations, setAffectations] = useState([]);
    const [filtredAffectations, setFiltredAffectations] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState('');
    const debouncedSearchText = useDebounce(search, 300);

    //Table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredAffectations].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredAffectations(sortedTable);
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
        console.log("pageNumber",page)
    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
    };

    const getStartIndex = () => {
        return (pageNumber - 1) * pageSize + 1;
    };

    const getEndIndex = () => {
        const endIndex = pageNumber * pageSize;
        return endIndex > totalItems ? totalItems : endIndex;
    };

    const GetClients = async (page, newPerPage) => {
        try {
            setLoading(true);

            const queryParams = new URLSearchParams({
                pageNumber: page,
                pageSize: newPerPage
            });

            if (debouncedSearchText != null) {
                queryParams.append("text", debouncedSearchText);
            }

            queryParams.append("statut", "Affecté");

            if (authData?.role != null && authData?.role !== "super admin") {
                if (authData?.idCentre != null && authData?.idCentre !== "") {
                    queryParams.append("idCentre", authData.idCentre);
                } else { return }
            }

            const response = await axiosInstance.get(`${Affectation}/GetAffectations?${queryParams}`);
            const resp = await response.data;
            setAffectations(resp.data);
            setFiltredAffectations(resp.data);
            setPageNumber(resp.pageNumber);
            setPageSize(resp.pageSize);
            setTotalItems(resp.totalItems);
            setTotalPages(resp.totalPages);
            setLoading(false);
        }
        catch (err) {
            console.log(err);
            setLoading(false);
        }
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Annuler le comportement par défaut de la touche "Entrée"
        }
    };

    //Générer étiquette

    const createQRCodeLabel = async (libelleZone, libelleBox, libelleEtagere, libelle, tracking) => {//qrCodeText les données sont séparés par ','
        // Créer un nouvel objet PDFDocument
        let qrCodeText = tracking;// `${libelleZone} / ${libelleBox} / ${libelleEtagere} / ${libelle} / ${tracking}`;
        const pdfDoc = await PDFDocument.create();
        const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman)

        // Générer le QR code
        const qrCodeOptions = {
            type: 'image/png',
            rendererOpts: {
                quality: 0.5,
            },
            color: {
                dark: '#000', // Blue dots
                light: '#0000', // Transparent background
            },
            errorCorrectionLevel: 'H',
            margin: 0,
        };

        const qrCodeDataUrl = await qrCode.toDataURL(tracking, qrCodeOptions);
        // Convertir l'image du QR code en bytes
        const qrCodeBytes = Uint8Array.from(atob(qrCodeDataUrl.split(',')[1]), (c) => c.charCodeAt(0));

        // Créer une nouvelle page au format 6,0 cm × 4,0 cm
        const page = pdfDoc.addPage([60, 40]);

        // Ajouter l'image du QR code à la page PDF
        const image = await pdfDoc.embedPng(qrCodeBytes);

        page.drawImage(image, {
            x: 5,
            y: 15,
            width: 20,
            height: 20,
        });

        page.drawText(tracking, {//Inserer un text
            x: 5,
            y: 10,
            size: 4,
            color: rgb(0, 0, 0),
            //rotate: degrees(-90),
        });

        page.drawText(libelleZone, {
            x: 30,
            y: 30,
            size: 7.5,
            color: rgb(0, 0, 0),
        });

        page.drawText(libelleBox, {
            x: 30,
            y: 22,
            size: 8.5,
            color: rgb(0, 0, 0),
        });

        page.drawText(libelleEtagere, {
            x: 30,
            y: 14,
            size: 8.5,
            color: rgb(0, 0, 0),
        });

        page.drawText(libelle, {
            x: 30,
            y: 6,
            size: 8.5,
            color: rgb(0, 0, 0),
        });

        // Enregistrez le PDF dans un nouveau tableau de bytes
        const modifiedPdfBytes = await pdfDoc.save();

        // Créer un objet Blob à partir des bytes du PDF
        const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });

        // Créer une URL de données pour le Blob
        const pdfUrl = URL.createObjectURL(blob);

        // Ouvrir le PDF dans une nouvelle fenêtre ou un nouvel onglet
        window.open(pdfUrl, '_blank');

    };
    const renderPaginationItems = () => {
        const startPage = Math.max(1, pageNumber - 5); // Limiter le nombre de pages à afficher à 5 avant la page actuelle
        const endPage = Math.min(totalPages, pageNumber + 5); // Limiter le nombre de pages à afficher à 5 après la page actuelle
//console.log(startPage,endPage,pageNumber,totalPages)
        return Array.from({ length: endPage - startPage + 1 }).map((_, index) => {
            const page = startPage + index;
            return (
                <Pagination.Item
                    key={page}
                    active={page === pageNumber}
                    onClick={() => handlePageChange(page)}
                >
                    {page}
                </Pagination.Item>
            );
        });
    };

     //Imprimer liste colis
     const listeColis = async () => {
        if(affectations.length===0) {
            CustomToast("Liste vide", 'warning');
            return;
        }
        const queryParams = new URLSearchParams({
        });

        if (debouncedSearchText != null) {
            queryParams.append("text", debouncedSearchText);
        }

            queryParams.append("statut", "Affecté");

        if (authData?.role != null && authData?.role !== "super admin") {
            if (authData?.idCentre != null && authData?.idCentre !== "") {
                queryParams.append("idCentre", authData.idCentre);
            } else { return }
        }
        const response = await axiosInstance.get(`${Affectation}/GetAffectationsPrint?${queryParams}`);
        const resp = await response.data;

        if (resp) {
            createPDFListeColis(resp, authData?.nom_Prenom)
        }

    };

    function groupBy(arr, key) {
        return arr.reduce((acc, obj) => {
            const groupKey = obj[key];
            acc[groupKey] = acc[groupKey] || [];
            acc[groupKey].push(obj);
            return acc;
        }, {});
    }

    const createPDFListeColis = async (colis, utilisateur) => {
        const groupedByClient = groupBy(colis, 'nom');

        const fontBytes = await fetch(AmiriFont).then((res) => res.arrayBuffer());
        const pdfDoc = await PDFDocument.create();
        pdfDoc.registerFontkit(fontkit);
        const customFont = await pdfDoc.embedFont(fontBytes);
        // Créer une nouvelle page
        let page = pdfDoc.addPage([210, 297]);

        const maxLinesPerPage = 40; // Choisissez un nombre maximal de lignes par page
        let linesOnCurrentPage = 0;
        let pageNumber = 1; // Numéro de la page actuelle

        page.drawText(`Page ${pageNumber}`, {
            x: 180,
            y: 10,
            size: 3,
            font: customFont,
            color: rgb(0, 0, 0),
        });

        page.drawText("Utilisateur: ", {
            x: 5,
            y: 280,
            size: 4,
            font: customFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(utilisateur, {
            x: 25,
            y: 280,
            size: 4,
            font: customFont,
            color: rgb(0, 0, 0),
        });

        linesOnCurrentPage++;

        page.drawText(format(new Date(), "dd/MM/yyyy HH:mm"), {
            x: 5,
            y: 275,
            size: 4,
            font: customFont,
            color: rgb(0, 0, 0),
        });

        linesOnCurrentPage++;

        let offsetY = 265; // Initial y-coordinate for client sections
        for (const clientName in groupedByClient) {
            if (groupedByClient.hasOwnProperty(clientName)) {
                const clientData = groupedByClient[clientName];
                const groupedByZone = groupBy(clientData, 'libelleZone');

                page.drawText(`${clientName}`, {
                    x: 10,
                    y: offsetY,
                    size: 7,
                    font: customFont,
                    color: rgb(0, 0, 0),
                    direction: 'rtl',
                });

                linesOnCurrentPage++;
                offsetY -= 5;

                if (linesOnCurrentPage > maxLinesPerPage) {
                    // Crée une nouvelle page si nécessaire
                    page = pdfDoc.addPage([210, 297]);
                    linesOnCurrentPage = 0;
                    offsetY = 265;
                    pageNumber++;
                    page.drawText(`Page ${pageNumber}`, {
                        x: 180,
                        y: 10,
                        size: 3,
                        font: customFont,
                        color: rgb(0, 0, 0),
                    });
                }

                for (const zoneKey in groupedByZone) {
                    if (groupedByZone.hasOwnProperty(zoneKey)) {
                        const zoneData = groupedByZone[zoneKey];
                        const groupedByBox = groupBy(zoneData, 'libelleBox');

                        for (const boxKey in groupedByBox) {
                            if (groupedByBox.hasOwnProperty(boxKey)) {
                                const boxData = groupedByBox[boxKey];
                                const groupedByEtagere = groupBy(boxData, 'libelleEtagere');

                                for (const etagereKey in groupedByEtagere) {
                                    if (groupedByEtagere.hasOwnProperty(etagereKey)) {
                                        const etagereData = groupedByEtagere[etagereKey];
                                        const groupedByLibelle = groupBy(etagereData, 'libelle');

                                        for (const libelleKey in groupedByLibelle) {
                                            if (groupedByLibelle.hasOwnProperty(libelleKey)) {
                                                const libelleData = groupedByLibelle[libelleKey];

                                                page.drawText(`${zoneKey}/${boxKey}/${etagereKey}/${libelleKey}`, {
                                                    x: 20,
                                                    y: offsetY,
                                                    size: 5,
                                                    font: customFont,
                                                    color: rgb(0, 0, 0),
                                                    wrap: 180,
                                                });

                                                linesOnCurrentPage++;
                                                offsetY -= 5;
                                                if (linesOnCurrentPage > maxLinesPerPage) {
                                                    // Crée une nouvelle page si nécessaire
                                                    page = pdfDoc.addPage([210, 297]);
                                                    linesOnCurrentPage = 0;
                                                    offsetY = 265;
                                                    pageNumber++;
                                                    page.drawText(`Page ${pageNumber}`, {
                                                        x: 180,
                                                        y: 10,
                                                        size: 3,
                                                        font: customFont,
                                                        color: rgb(0, 0, 0),
                                                    });
                                                }

                                                for (const colisItem of libelleData) {
                                                    // Ajoutez votre logique d'affichage ici, par exemple :
                                                    //console.log(`Client: ${clientName}, Zone: ${zoneKey}, Box: ${boxKey}, Etagere: ${etagereKey}, Libelle: ${libelleKey}, Tracking: ${colisItem.tracking}`);
                                                    //offsetY -= 5;
                                                    page.drawText(`${colisItem.tracking}`, {
                                                        x: 50,
                                                        y: offsetY,
                                                        size: 4,
                                                        font: customFont,
                                                        color: rgb(0, 0, 0),
                                                        wrap: 180,
                                                    });

                                                    linesOnCurrentPage++;
                                                    offsetY -= 5;
                                                    if (linesOnCurrentPage > maxLinesPerPage) {
                                                        // Crée une nouvelle page si nécessaire
                                                        page = pdfDoc.addPage([210, 297]);
                                                        linesOnCurrentPage = 0;
                                                        offsetY = 265;
                                                        pageNumber++;
                                                        page.drawText(`Page ${pageNumber}`, {
                                                            x: 180,
                                                            y: 10,
                                                            size: 3,
                                                            font: customFont,
                                                            color: rgb(0, 0, 0),
                                                        });
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        // Save the PDF to a new byte array
        const modifiedPdfBytes = await pdfDoc.save();

        // Create a Blob object from the PDF bytes
        const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });

        // Create a data URL for the Blob
        const pdfUrl = URL.createObjectURL(blob);

        // Open the PDF in a new window or tab
        window.open(pdfUrl, '_blank');
    };

    // useEffect(() => {
    //     //Load Data
    //     GetClients(debouncedSearchText ? 1 : pageNumber, pageSize);

    //     return () => {
    //         // Perform any necessary cleanup (e.g., cancel pending requests)
    //     };
    // }, [authData, debouncedSearchText, pageNumber, pageSize]);

    useEffect(() => {
        //Load Data
        GetClients(pageNumber, pageSize);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
            //console.log("use1")
        };
    }, [authData, pageNumber, pageSize]);

    useEffect(() => {
        //Load Data
        GetClients(1, pageSize);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
           // console.log("use2")
        };
    }, [authData, debouncedSearchText]);

    return (
        <div className='row mt-1 mb-1'>
            <div className='col-xs-12 col-md-12'>
                <div className="card">
                    <div className="card-header">Colis</div>
                    <div className="card-body">
                        {/* <div className="d-flex bd-highlight mb-3">
                            <div className="me-auto p-2 bd-highlight">
                                <div className="search">
                                    <form className="form-inline">
                                        <input
                                            className="form-control mr-sm-2"
                                            type="search" placeholder="Search"
                                            aria-label="Rechercher"
                                            value={search}
                                            onChange={(e) => setSearch(e.target.value)}
                                            autoFocus={true}
                                        />
                                    </form>
                                </div>
                            </div>
                        </div> */}
                        <div className='row mb-3 justify-content-center align-items-center g-2'>
                            <div className={`${debouncedSearchText ? 'col-xs-12 col-md-10' : 'col-xs-12 col-md-12'}`}>
                                <div className="search">
                                    <form className="form-inline">
                                        <input
                                            className="form-control mr-sm-2"
                                            type="search" placeholder="Search"
                                            aria-label="Rechercher"
                                            value={search}
                                            onChange={(e) => setSearch(e.target.value)}
                                            autoFocus={true}
                                            onKeyDown={handleKeyPress}
                                        />
                                    </form>
                                </div>
                            </div>
                            {debouncedSearchText && <div className="col-xs-12 col-md-2">
                                <div className="search">
                                    <form className="form-inline">
                                        <button className="btn btn-warning form-control" type="button" onClick={() => listeColis()}>
                                            <i className="fa fa-print fa-1x"></i>
                                        </button>
                                    </form>
                                </div>
                            </div>}
                        </div>
                        <div className='row justify-content-center align-items-center g-2'>
                            <div className="col-xs-12 col-md-12">
                                <div className="table-responsive">
                                    <table className="table table-hover table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Client</th>
                                                <th>Emplacement (Zone / Box / Étagère / Boite)</th>
                                                <th>Tracking</th>
                                                <th>Statut</th>
                                                <th>Date statut</th>
                                                <th>Imprimer</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {affectations.map((colis, index) => (
                                                <tr key={index}>
                                                    <td>{colis.nom}</td>
                                                    <td>{colis.libelleZone + ' / ' + colis.libelleBox + ' / ' + colis.libelleEtagere + ' / ' + colis.libelle}</td>
                                                    <td>{colis.tracking}</td>
                                                    <td>{colis.lastStatut}</td>
                                                    <td>{format(colis.dateCRUD, "dd/MM/yyyy HH:mm")}</td>
                                                    <td className='text-center'>
                                                        <Link to="#" style={{ textDecoration: "none" }} onClick={() => createQRCodeLabel(colis.libelleZone, colis.libelleBox, colis.libelleEtagere, colis.libelle, colis.tracking)}>
                                                            <span className="fa fa-print text-warning fa-lg"></span>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="d-flex justify-content-between mt-3">
                                    <div>
                                        {/* {totalPages >= 1 && (
                                            <Pagination className="pagination-sm">
                                                <Pagination.Prev
                                                    onClick={() => handlePageChange(pageNumber - 1)}
                                                    disabled={pageNumber === 1}
                                                >
                                                    <span aria-hidden="true">&laquo;</span>
                                                </Pagination.Prev>
                                                {Array.from({ length: totalPages }).map((_, index) => (
                                                    <Pagination.Item
                                                        key={index}
                                                        active={index + 1 === pageNumber}
                                                        onClick={() => handlePageChange(index + 1)}
                                                    >
                                                        {index + 1}
                                                    </Pagination.Item>
                                                ))}
                                                <Pagination.Next
                                                    onClick={() => handlePageChange(pageNumber + 1)}
                                                    disabled={pageNumber === totalPages}
                                                >
                                                    <span aria-hidden="true">&raquo;</span>
                                                </Pagination.Next>
                                            </Pagination>
                                        )} */}
                                        {totalPages >= 1 && (
                                            <Pagination className="pagination-sm">
                                                <Pagination.Prev
                                                    onClick={() => handlePageChange(pageNumber - 1)}
                                                    disabled={pageNumber === 1}
                                                >
                                                    <span aria-hidden="true">&laquo;</span>
                                                </Pagination.Prev>
                                                {renderPaginationItems()}
                                                <Pagination.Next
                                                    onClick={() => handlePageChange(pageNumber + 1)}
                                                    disabled={pageNumber === totalPages}
                                                >
                                                    <span aria-hidden="true">&raquo;</span>
                                                </Pagination.Next>
                                            </Pagination>
                                        )}
                                    </div>

                                    <div className="mb-3">
                                        <p>
                                            Affichage de {getStartIndex()} à {getEndIndex()} sur {totalItems} entrées
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Colis;
