import React, { useEffect, useState, useRef, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Link, useNavigate } from 'react-router-dom';
import ConfirmationDialog from '../../components/confirmation/ConfirmationDialog';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CustomToast } from '../../components/Toast/CustomToast';
import useDebounce from '../../hooks/useDebounce';
import { axiosInstance, ImportColis, Client } from '../../services/api';
import { Pagination } from 'react-bootstrap';
import { AuthContext } from '../../contexts/AuthContext';
import { format, formatDistance, formatRelative, subDays } from 'date-fns'
import { degrees, grayscale, PDFDocument, PDFFont, rgb, StandardFonts } from 'pdf-lib';
import qrCode from 'qrcode';
//import bwipjs from 'bwip-js';
import JsBarcode from 'jsbarcode';

const Clients = () => {
    const { authData } = useContext(AuthContext);

    const [clients, setClients] = useState([]);
    const [upload, setUpload] = useState(false);
    const [filtredUpload, setFiltredUpload] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState('');
    const debouncedSearchText = useDebounce(search, 300);
    const [renommerTous, setRenommerTous] = useState(false);
    const [uploadRenommer, setUploadRenommer] = useState(false);
    const [uploadRenommerError, setUploadRenommerError] = useState(false);
    const [pathRenommer, setPathRenommer] = useState(null);
    const pathRefRenommer = useRef(null)

    const [modifierClient, setModifierCLient] = useState(false);
    const [idModifierClient, setIdModifierClient] = useState('');

    //Table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredUpload].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredUpload(sortedTable);
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
    };

    const getStartIndex = () => {
        return (pageNumber - 1) * pageSize + 1;
    };

    const getEndIndex = () => {
        const endIndex = pageNumber * pageSize;
        return endIndex > totalItems ? totalItems : endIndex;
    };

    const handlePathChangeRenommer = (e) => {
        setPathRenommer(e.target.files[0]);
    };

    const getImageFileName = (file) => {
        return file ? file.name : 'No file selected';
    };

    const GetClients = async (page, newPerPage) => {
        try {
            setLoading(true);

            const queryParams = new URLSearchParams({
                pageNumber: page,
                pageSize: newPerPage
            });

            if (debouncedSearchText != null) {
                queryParams.append("text", debouncedSearchText);
            }

            if (authData?.role != null && authData?.role !== "super admin") {
                queryParams.append("idCentre", authData.idCentre);
            }

            const response = await axiosInstance.get(`${Client}/GetClients?${queryParams}`);
            const resp = await response.data;
            setClients(resp.data);
            setFiltredUpload(resp.data);
            setPageNumber(resp.pageNumber);
            setPageSize(resp.pageSize);
            setTotalItems(resp.totalItems);
            setTotalPages(resp.totalPages);
            setLoading(false);
        }
        catch (err) {
            console.log(err);
            setLoading(false);
        }
    }

    const formik2 = useFormik({
        initialValues: {
            nom: '',
        },
        validationSchema: Yup.object({
            nom: Yup.string()
                .required("Champ obligatoire"),
        }),
        onSubmit: async (values) => {
            try {
                //console.log(values);

                const postData = {
                    idClient: idModifierClient,
                    nom: values.nom,
                    idUtilisateurCRUD: authData.idUtilisateur,
                };
                const response = await axiosInstance.put(`${Client}`, postData);
                CustomToast("Le client a été modifié avec succès", 'success');
                setModifierCLient(false);
                setIdModifierClient('');
                GetClients(1, pageSize);
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Client existe") {
                    CustomToast("Un autre client existe avec le même nom", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    const ModifierClient = (value, idClient, nom) => {
        try {
            setModifierCLient(value);
            setIdModifierClient(idClient);
            formik2.resetForm();
            if (value) {
                formik2.setValues((prevValues) => ({
                    ...prevValues,
                    nom: nom || ''
                }));
            }

        }
        catch (error) {
            console.log(error);
        }
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Annuler le comportement par défaut de la touche "Entrée"
        }
    };

    const handleCancelDelete = () => {
        setRenommerTous(false);
    };

    const correction = async () => {
        try {
            const response = await axiosInstance.post(`${Client}/boutiques`);
            CustomToast("Correction terminée avec succès", 'success');
            GetClients(1, pageSize);
        } catch (error) {
            console.error(error);
        }
    };

    const formik3 = useFormik({
        initialValues: {
            idUtilisateur: '',
        },
        onSubmit: async (values) => {
            try {
                //console.log(values);
                const formData = new FormData();
                formData.append('idCentre', authData.idCentre);
                formData.append('libelleCentre', authData.libelleCentre);
                formData.append('idUtilisateur', authData.idUtilisateur);
                formData.append('filteToUpload', pathRenommer);

                const response = await axiosInstance.post(`${Client}/correctionPartielEtendu`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                GetClients(1, pageSize);
                CustomToast("Le fichier a été importé avec succès", 'success');
                setUploadRenommer(false);
                setUploadRenommerError(false)
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Aucun fichier") {
                    CustomToast("Séléctionnez un fichier", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Template error") {
                    CustomToast("Veuillez vérifier votre fichier", 'error');
                    setUploadRenommerError(true)
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    const handleRenommerPartiel = (val) => {
        setUploadRenommer(val);
        setUploadRenommerError(false);
        setPathRenommer(null);
    };

    // useEffect(() => {
    //     //Load Data
    //     GetClients(debouncedSearchText ? 1 : pageNumber, pageSize);

    //     return () => {
    //         // Perform any necessary cleanup (e.g., cancel pending requests)
    //     };
    // }, [authData, debouncedSearchText, pageNumber, pageSize]);

    const renderPaginationItems = () => {
        const startPage = Math.max(1, pageNumber - 5); // Limiter le nombre de pages à afficher à 5 avant la page actuelle
        const endPage = Math.min(totalPages, pageNumber + 5); // Limiter le nombre de pages à afficher à 5 après la page actuelle

        return Array.from({ length: endPage - startPage + 1 }).map((_, index) => {
            const page = startPage + index;
            return (
                <Pagination.Item
                    key={page}
                    active={page === pageNumber}
                    onClick={() => handlePageChange(page)}
                >
                    {page}
                </Pagination.Item>
            );
        });
    };

    useEffect(() => {
        //Load Data
        GetClients(pageNumber, pageSize);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, pageNumber, pageSize]);

    useEffect(() => {
        //Load Data
        GetClients(1, pageSize);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, debouncedSearchText]);

    return (
        <>
            <div className='row mt-1 mb-1'>
                <div className='col-xs-12 col-md-12'>
                    <div className="card">
                        <div className="card-header">Clients</div>
                        <div className="card-body">
                            <div className="d-flex bd-highlight mb-3">
                                <div className="me-auto p-2 bd-highlight">
                                    <div className="search">
                                        <form className="form-inline">
                                            <input
                                                className="form-control mr-sm-2"
                                                type="search" placeholder="Search"
                                                aria-label="Rechercher"
                                                value={search}
                                                onChange={(e) => setSearch(e.target.value)}
                                                autoFocus={true}
                                                onKeyDown={handleKeyPress}
                                            />
                                        </form>
                                    </div>
                                </div>
                                {authData?.role !== "visiteur" && <div className="p-2 bd-highlight" title='Corriger le nom de toutes les boutiques'>
                                    <button className="btn btn-primary btn-lg form-control" type="button" onClick={() => setRenommerTous(true)}>
                                        <i className="fa fa-pencil"> Toutes les boutiques</i>
                                    </button>
                                </div>}
                                {authData?.role !== "visiteur" && <div className="p-2 bd-highlight" title='Corriger partiel des noms de boutiques'>
                                    <button className="btn btn-info btn-lg form-control" type="button" onClick={() => handleRenommerPartiel(true)}>
                                        <i className="fa fa-pencil"> Correction partiel</i>
                                    </button>
                                </div>}
                            </div>
                            <div className='row justify-content-center align-items-center g-2'>
                                <div className="col-xs-12 col-md-12">
                                    <div className="table-responsive">
                                        <table className="table table-hover table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Boutique</th>

                                                    <th className='text-center'>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {clients.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            {item.idClientFromFile}
                                                        </td>

                                                        <td>
                                                            {item.nom}
                                                        </td>

                                                        <td className='text-center'>
                                                            <Link to="#" style={{ textDecoration: "none" }} onClick={() => ModifierClient(true, item.idClient, item.nom)}>
                                                                <span className="fa fa-pencil text-warning fa-lg"></span>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="d-flex justify-content-between mt-3">
                                        <div>
                                            {totalPages >= 1 && (
                                                <Pagination className="pagination-sm">
                                                    <Pagination.Prev
                                                        onClick={() => handlePageChange(pageNumber - 1)}
                                                        disabled={pageNumber === 1}
                                                    >
                                                        <span aria-hidden="true">&laquo;</span>
                                                    </Pagination.Prev>
                                                    {renderPaginationItems()}
                                                    <Pagination.Next
                                                        onClick={() => handlePageChange(pageNumber + 1)}
                                                        disabled={pageNumber === totalPages}
                                                    >
                                                        <span aria-hidden="true">&raquo;</span>
                                                    </Pagination.Next>
                                                </Pagination>
                                            )}
                                        </div>

                                        <div className="mb-3">
                                            <p>
                                                Affichage de {getStartIndex()} à {getEndIndex()} sur {totalItems} entrées
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {modifierClient && <><div className="modal" id="correctionNomBoutiques" style={{ display: 'block' }} data-bs-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true" tabIndex={-1}>
                <form onSubmit={formik2.handleSubmit}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Modifier client</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => ModifierClient(false, '', '')}></button>
                            </div>
                            <div className="modal-body">
                                <div className='row mt-0 mb-0'>
                                    <div className="col">
                                        <label className="form-label">Nom<span className='text-danger fs-6'>*</span></label>
                                        <input
                                            type="text"
                                            name="nom"
                                            id="nom"
                                            className={`form-control ${formik2.touched.nom && formik2.errors?.nom ? 'border-danger' : ''}`}
                                            placeholder=""
                                            value={formik2.values.nom}
                                            onChange={formik2.handleChange}
                                        />
                                        {formik2.touched.nom && formik2.errors.nom ? (
                                            <small className="text-danger">{formik2.errors.nom}</small>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => ModifierClient(false, '', '')}>Annuler</button>
                                <button type="submit" className="btn btn-success" data-bs-dismiss="modal">Enregistrer</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
                <div className="modal-backdrop show"></div></>
            }

            {uploadRenommer && <><div className="modal" id="correctionNomBoutiquesPartiels" style={{ display: 'block' }} data-bs-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true" tabIndex={-1}>
                <form onSubmit={formik3.handleSubmit}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Renommer les boutiques</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => handleRenommerPartiel(false)}></button>
                            </div>

                            <div className="modal-body">
                                <div className='row mt-0 mb-0'>
                                    <div className="col">
                                        <label className="form-label">Upload<span className='text-danger fs-6'>*</span></label>
                                        <input
                                            ref={pathRefRenommer}
                                            id="pathRenommer"
                                            name="pathRenommer"
                                            className={`form-control`}
                                            type="file"
                                            onChange={handlePathChangeRenommer}
                                        />
                                        {pathRenommer && <span style={{ fontSize: "10px" }}>{getImageFileName(pathRenommer)}
                                            <span
                                                className="fa fa-trash"
                                                onClick={(e) => { setPathRenommer(null); if (pathRefRenommer.current) { pathRefRenommer.current.value = ''; } }}></span>
                                        </span>}
                                    </div>
                                </div>
                                <div className='row mt-5 mb-0'>
                                    <p>
                                        Le fichier doit être composé de : 
                                        <ol>
                                        <li>
                                            Id boutique
                                        </li>
                                        <li>
                                            Boutique
                                        </li>
                                    </ol>
                                    </p>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => handleRenommerPartiel(false)}>Annuler</button>
                                <button type="submit" className="btn btn-success" data-bs-dismiss="modal">Enregistrer</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
                <div className="modal-backdrop show"></div></>
            }

            <ConfirmationDialog
                open={renommerTous}
                onClose={handleCancelDelete}
                onConfirm={correction}
                titleMessage="Corriger le nom de toutes les boutiques"
                bodyMessage={"Voulez-vous vraiment corriger toutes les noms de boutiques"}
                btnCancelText="Annuler"
                btnConfirmText="Corriger"
            />
        </>
    );
}

export default Clients;
