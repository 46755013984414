import axios from 'axios';

// Base URL for API RETOUR
//const BASE_URL = 'http://192.168.0.101:5038/';//MOB
//const BASE_URL = 'http://192.168.100.49:5038/';
//const BASE_URL = 'http://localhost:5038/';
const BASE_URL = process.env.REACT_APP_BASE_URL;

//const API_KEY = process.env.REACT_APP_API_KEY;

const BASE_URL_API = BASE_URL+'api/';

const IMAGE_URL = BASE_URL+'images/';

// Create an instance of Axios with the base URL
export const axiosInstance = axios.create({
    baseURL: BASE_URL_API,
  });

// Add request interceptor
axiosInstance.interceptors.request.use((config) => {
  // Add JWT token to the request header from localStorage
  const authData = JSON.parse(localStorage.getItem('authData'));
  const jwtToken = authData ? authData.token : null;
  if (jwtToken) {
    config.headers['Authorization'] = `Bearer ${jwtToken}`;
  }

  // Add API key to the request header
  //config.headers['X-Api-Key'] = API_KEY;
  //Implement api key expiration

  return config;
});

export {BASE_URL};

//API endpoints

export const UserAuthentication=BASE_URL_API+'UserAuthentication';
export const Client=BASE_URL_API+'Client';
export const ImportColis=BASE_URL_API+'ImportColis';
export const Centre=BASE_URL_API+'Centre';
export const Zone=BASE_URL_API+'Zone';
export const Affectation=BASE_URL_API+'Affectation';
export const MyDashboard=BASE_URL_API+'Dashboard';

//To display file ressources
export const ClientsFile=IMAGE_URL+'Clients/';

//WebSockets-SignalR
export const getJwtToken=()=> {
  // Implement your logic to retrieve the JWT token
  const authData = JSON.parse(localStorage.getItem('authData'));
  return (authData ? authData.token : null);
}

// export const DashboardHub=BASE_URL+'dashboardHub';