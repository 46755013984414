import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from './contexts/AuthContext';
import Layout from './components/layout/Layout.js';
import Login from './pages/login/Login.js';
import Empty from "./pages/empty/Empty";
import Config from "./pages/config/Config";
import Utilisateurs from "./pages/config/utilisateurs/Utilisateurs";
import Centres from "./pages/config/centres/Centres";
import Zones from "./pages/config/zones/Zones";
import ZoneBoutique from "./pages/config/zones/ZoneBoutique";
import ImporterColis from "./pages/colis/ImporterColis";
import Colis from "./pages/colis/Colis";
import Historique from "./pages/colis/Historique";
import Clients from "./pages/clients/Clients";
import Scan from "./pages/accueil/Scan";
import ScanVolume from "./pages/accueil/ScanVolume";
import Dashboard from "./pages/dashboard/Dashboard";
import Audit from "./pages/audit/Audit";

import Unauthorized from './pages/Errors/Unauthorized';
import NotFound from './pages/Errors/NotFound';

import ProtectedRoute from './services/Securite/ProtectedRoute';
import ProtectedRouteSuperAdmin from './services/Securite/ProtectedRouteSuperAdmin';

function App() {
  return (
    <>
      {/* basename="/retourApp" */}
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />

            <Route element={<Layout />} >              
                                         
              <Route path="/" element={<ProtectedRoute allowedRoles={['admin', 'super admin', 'visiteur']} />}>
                <Route path="/config" element={<Config />} />
                {/* <Route path="/utilisateurs" element={<Utilisateurs />} /> */}
                <Route path="/centres" element={<Centres />} />
                <Route path="/zones" element={<Zones />} />
                <Route path="/zoneboutique/:id" element={<ZoneBoutique />} />
                <Route path="/clients" element={<Clients />} />
                <Route path="/importercolis" element={<ImporterColis />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/audit" element={<Audit />} />
                {/* <Route path="/compte" element={<Compte />} /> */}
              </Route>
              <Route path="/" element={<ProtectedRoute allowedRoles={['admin', 'super admin']} />}>
                <Route path="/utilisateurs" element={<Utilisateurs />} />
              </Route>
              <Route path="/" element={<ProtectedRoute allowedRoles={['admin', 'operateur','super admin', 'visiteur']} />}>
                <Route path="/scan" element={<Scan />} />
                <Route path="/scanvolume" element={<ScanVolume />} />
                <Route path="/colis" element={<Colis />} />
                <Route path="/historique" element={<Historique />} />
                <Route path="/empty" element={<Empty />} />

                {/* <Route path="/compte" element={<Compte />} /> */}
              </Route>
            </Route>

            <Route path="unauthorized" element={<Unauthorized />} />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
      <ToastContainer style={{ fontSize: '1rem' }} />
    </>
  );
}

export default App;
