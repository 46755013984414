import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Outlet } from "react-router-dom"
import { CustomToast } from '../Toast/CustomToast';
import { AuthContext } from '../../contexts/AuthContext';
const Layout = () => {
    const { authData, logout } = useContext(AuthContext);
    const [role, setRole] = useState(true);
    const handleLogOut = () => {
        localStorage.removeItem('authData');
        //localStorage.removeItem('menuShow');
        CustomToast(`Déconnexion réussie`, 'success');
    };

    useEffect(() => {
        setRole(authData?.role);
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData]);

    return (
        <div className="container-fluid pt-3 pb-3">
            <div className='row mt-1 mb-1 sticky-top'>
                <div className='col-xs-12 col-md-12'>
                    <div className="card">
                        <div className="card-body">
                            <div className='row justify-content-center align-items-center'>
                                {authData?.role !== "operateur" && <div className='col-xs-2 col-md-2 mt-2'>
                                    <Link to="/config" className="btn btn-light form-control" replace>
                                        <i className="fa fa-cog"> Configuration</i>
                                    </Link>
                                </div>}
                                <div className='col-xs-2 col-md-2 mt-2'>
                                    <Link to="/scan" className="btn btn-light form-control" replace>
                                        <i className="fa fa-qrcode"> Scanner</i>
                                    </Link>
                                </div>
                                <div className='col-xs-2 col-md-2 mt-2'>
                                    <Link to="/scanvolume" className="btn btn-light form-control" replace>
                                        <i className="fa fa-qrcode"> Scanner volume</i>
                                    </Link>
                                </div>
                                {/* <div className='col-xs-2 col-md-2'>
                                    <Link to="#" className="btn btn-light form-control" replace>
                                        <i className="fa fa-user"> Scanner dédiée</i>
                                    </Link>
                                </div>
                                <div className='col-xs-2 col-md-2'>
                                    <Link to="#" className="btn btn-light form-control" replace>
                                        <i className="fa fa-user"> Scanner volume</i>
                                    </Link>
                                </div> */}
                                {authData?.role !== "operateur" && <div className='col-xs-2 col-md-2 mt-2'>
                                    <Link to="/importercolis" className="btn btn-light form-control" replace>
                                        <i className="fa fa-upload"> Importer données boutiques</i>
                                    </Link>
                                </div>}
                                {authData?.role !== "operateur" && <div className='col-xs-2 col-md-2 mt-2'>
                                    <Link to="/clients" className="btn btn-light form-control" replace>
                                        <i className="fa fa-home"> Boutiques</i>
                                    </Link>
                                </div>}
                                <div className='col-xs-2 col-md-2 mt-2'>
                                    <Link to="/colis" className="btn btn-light form-control" replace>
                                        <i className="fa fa-archive"> Colis</i>
                                    </Link>
                                </div>
                                {authData?.role !== "operateur" && <div className='col-xs-2 col-md-2 mt-2'>
                                    <Link to="/historique" className="btn btn-light form-control" replace>
                                        <i className="fa fa-history"> Historique</i>
                                    </Link>
                                </div>}
                                {authData?.role !== "operateur" && <div className='col-xs-2 col-md-2 mt-2'>
                                    <Link to="/dashboard" className="btn btn-light form-control" replace>
                                        <i className="fa fa-tachometer"> Dashboard</i>
                                    </Link>
                                </div>}
                                {authData?.role !== "operateur" && <div className='col-xs-2 col-md-2 mt-2'>
                                    <Link to="/audit" className="btn btn-light form-control" replace>
                                        <i className="fa fa-check"> Audit</i>
                                    </Link>
                                </div>}
                                <div className='col-xs-2 col-md-2 mt-2'>
                                    <Link to="/login" className="btn btn-light form-control" replace onClick={() => handleLogOut()}>
                                        <i className="fa fa-sign-out"> Se déconnecter</i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Outlet />
        </div>
    );
}

export default Layout;
