import React, { useEffect, useState, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useDebounce from '../../hooks/useDebounce';
import { ExportToExcel } from '../../components/ExportToExcel/ExportToExcel';
import { axiosInstance, Affectation } from '../../services/api';
import { Pagination } from 'react-bootstrap';
import { CustomToast } from '../../components/Toast/CustomToast';
import { AuthContext } from '../../contexts/AuthContext';
import { format } from 'date-fns'
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
//import AmiriFont from '../../fonts/Amiri/Amiri-Regular.ttf';
import AmiriFont from '../../fonts/El_Messiri/ElMessiri-VariableFont_wght.ttf';
import qrCode from 'qrcode';

const Historique = () => {
    const { authData } = useContext(AuthContext);

    const [affectations, setAffectations] = useState([]);
    const [affectationsPrint, setAffectationsPrint] = useState([]);
    const [filtredAffectations, setFiltredAffectations] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState('');
    const [statut, setStatut] = useState('Affecté');
    const debouncedSearchText = useDebounce(search, 300);

    const [modifierColis, setModifierColis] = useState(false);
    const [retirerColis, setRetirerColis] = useState(false);
    const [idModifierColis, setIdModifierColis] = useState('');

    //Table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredAffectations].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredAffectations(sortedTable);
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
    };

    const getStartIndex = () => {
        return (pageNumber - 1) * pageSize + 1;
    };

    const getEndIndex = () => {
        const endIndex = pageNumber * pageSize;
        return endIndex > totalItems ? totalItems : endIndex;
    };

    const GetClients = async (page, newPerPage) => {
        try {
            setLoading(true);

            const queryParams = new URLSearchParams({
                pageNumber: page,
                pageSize: newPerPage
            });

            if (debouncedSearchText != null) {
                queryParams.append("text", debouncedSearchText);
            }

            if (statut != null) {
                queryParams.append("statut", statut);
            }
            //queryParams.append("statut", "Affecté");


            // if (authData?.idCentre != null && authData?.idCentre !== "") {
            //     queryParams.append("idCentre", authData.idCentre);
            // } else { return }

            if (authData?.role != null && authData?.role !== "super admin") {
                if (authData?.idCentre != null && authData?.idCentre !== "") {
                    queryParams.append("idCentre", authData.idCentre);
                } else { return }
            }

            // if (authData?.idCentre != null && authData?.idCentre !== "") {
            //     if (authData?.role != null && authData?.role !== "super admin") {
            //         queryParams.append("idCentre", authData.idCentre);
            //     }
            // } else { return }

            const response = await axiosInstance.get(`${Affectation}/GetAffectations?${queryParams}`);
            const resp = await response.data;
            setAffectations(resp.data);
            setFiltredAffectations(resp.data);
            setPageNumber(resp.pageNumber);
            setPageSize(resp.pageSize);
            setTotalItems(resp.totalItems);
            setTotalPages(resp.totalPages);
            setLoading(false);
        }
        catch (err) {
            console.log(err);
            setLoading(false);
        }
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Annuler le comportement par défaut de la touche "Entrée"
        }
    };

    //Générer étiquette

    const createQRCodeLabel = async (libelleZone, libelleBox, libelleEtagere, libelle, tracking) => {//qrCodeText les données sont séparés par ','
        // Créer un nouvel objet PDFDocument
        let qrCodeText = tracking;// `${libelleZone} / ${libelleBox} / ${libelleEtagere} / ${libelle} / ${tracking}`;
        const pdfDoc = await PDFDocument.create();
        const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman)

        // Générer le QR code
        const qrCodeOptions = {
            type: 'image/png',
            rendererOpts: {
                quality: 0.5,
            },
            color: {
                dark: '#000', // Blue dots
                light: '#0000', // Transparent background
            },
            errorCorrectionLevel: 'H',
            margin: 0,
        };

        const qrCodeDataUrl = await qrCode.toDataURL(tracking, qrCodeOptions);
        // Convertir l'image du QR code en bytes
        const qrCodeBytes = Uint8Array.from(atob(qrCodeDataUrl.split(',')[1]), (c) => c.charCodeAt(0));

        // Créer une nouvelle page au format 6,0 cm × 4,0 cm
        const page = pdfDoc.addPage([60, 40]);

        // Ajouter l'image du QR code à la page PDF
        const image = await pdfDoc.embedPng(qrCodeBytes);

        page.drawImage(image, {
            x: 5,
            y: 15,
            width: 20,
            height: 20,
        });

        page.drawText(tracking, {//Inserer un text
            x: 5,
            y: 10,
            size: 4,
            color: rgb(0, 0, 0),
            //rotate: degrees(-90),
        });

        page.drawText(libelleZone, {
            x: 30,
            y: 30,
            size: 7.5,
            color: rgb(0, 0, 0),
        });

        page.drawText(libelleBox, {
            x: 30,
            y: 22,
            size: 8.5,
            color: rgb(0, 0, 0),
        });

        page.drawText(libelleEtagere, {
            x: 30,
            y: 14,
            size: 8.5,
            color: rgb(0, 0, 0),
        });

        page.drawText(libelle, {
            x: 30,
            y: 6,
            size: 8.5,
            color: rgb(0, 0, 0),
        });

        // Enregistrez le PDF dans un nouveau tableau de bytes
        const modifiedPdfBytes = await pdfDoc.save();

        // Créer un objet Blob à partir des bytes du PDF
        const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });

        // Créer une URL de données pour le Blob
        const pdfUrl = URL.createObjectURL(blob);

        // Ouvrir le PDF dans une nouvelle fenêtre ou un nouvel onglet
        window.open(pdfUrl, '_blank');

    };

    //Imprimer liste colis
    const listeColis = async () => {

        if (affectations.length === 0) {
            CustomToast("Liste vide", 'warning');
            return;
        }

        const queryParams = new URLSearchParams({
        });

        if (debouncedSearchText != null) {
            queryParams.append("text", debouncedSearchText);
        }

        if (statut != null) {
            queryParams.append("statut", statut);
        }

        if (authData?.role != null && authData?.role !== "super admin") {
            if (authData?.idCentre != null && authData?.idCentre !== "") {
                queryParams.append("idCentre", authData.idCentre);
            } else { return }
        }
        const response = await axiosInstance.get(`${Affectation}/GetAffectationsPrint?${queryParams}`);
        const resp = await response.data;
        setAffectationsPrint(resp.data);

        if (resp) {
            createPDFListeColis(resp, authData?.nom_Prenom)
        }

    };

    //Exporter la lise des colis
    const exportExcel = async () => {
        try {
            const queryParams = new URLSearchParams({
            });

            if (debouncedSearchText != null) {
                queryParams.append("text", debouncedSearchText);
            }

            if (statut != null) {
                queryParams.append("statut", statut);
            }

            // if (authData?.idCentre != null && authData?.idCentre !== "") {
            //     queryParams.append("idCentre", authData.idCentre);
            // } else { return }

            if (authData?.role != null && authData?.role !== "super admin") {
                if (authData?.idCentre != null && authData?.idCentre !== "") {
                    queryParams.append("idCentre", authData.idCentre);
                } else { return }
            }

            const response = await axiosInstance.get(`${Affectation}/GetAffectationsExcel?${queryParams}`);

            const resp = await response.data;

            const Heading = [
                [
                    "Boutique",
                    "Emplacement",
                    "Centre",
                    "Tracking",
                    "Statut",
                    "Date statut"
                ]
            ];

            let fileName = "Colis-" + format(new Date(), "yyyyMMddHHmmss");
            const fileExtension = ".xlsx";
            const blob = ExportToExcel(resp, fileName, fileExtension, "Colis", Heading);

            // Create a URL for the Blob
            const url = window.URL.createObjectURL(blob);

            CustomToast(<><strong>Terminé : Exportation xlsx de liste des colis</strong><br></br><a href={url} download={fileName + fileExtension}>Cliquez ici si le téléchargement ne démarre pas</a></>, 'success', 60000);

            // Clean up the URL object after the download
            //window.URL.revokeObjectURL(url);         

        }
        catch (error) {
            console.log(error);
        }
    }

    function groupBy(arr, key) {
        return arr.reduce((acc, obj) => {
            const groupKey = obj[key];
            acc[groupKey] = acc[groupKey] || [];
            acc[groupKey].push(obj);
            return acc;
        }, {});
    }

    const createPDFListeColis = async (colis, utilisateur) => {
        const groupedByClient = groupBy(colis, 'nom');

        const fontBytes = await fetch(AmiriFont).then((res) => res.arrayBuffer());
        const pdfDoc = await PDFDocument.create();
        pdfDoc.registerFontkit(fontkit);
        const customFont = await pdfDoc.embedFont(fontBytes);
        // Créer une nouvelle page
        let page = pdfDoc.addPage([210, 297]);

        const maxLinesPerPage = 40; // Choisissez un nombre maximal de lignes par page
        let linesOnCurrentPage = 0;
        let pageNumber = 1; // Numéro de la page actuelle

        page.drawText(`Page ${pageNumber}`, {
            x: 180,
            y: 10,
            size: 3,
            font: customFont,
            color: rgb(0, 0, 0),
        });

        page.drawText("Utilisateur: ", {
            x: 5,
            y: 280,
            size: 4,
            font: customFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(utilisateur, {
            x: 25,
            y: 280,
            size: 4,
            font: customFont,
            color: rgb(0, 0, 0),
        });

        linesOnCurrentPage++;

        page.drawText(format(new Date(), "dd/MM/yyyy HH:mm"), {
            x: 5,
            y: 275,
            size: 4,
            font: customFont,
            color: rgb(0, 0, 0),
        });

        linesOnCurrentPage++;

        let offsetY = 265; // Initial y-coordinate for client sections
        for (const clientName in groupedByClient) {
            if (groupedByClient.hasOwnProperty(clientName)) {
                const clientData = groupedByClient[clientName];
                const groupedByZone = groupBy(clientData, 'libelleZone');

                page.drawText(`${clientName}`, {
                    x: 10,
                    y: offsetY,
                    size: 7,
                    font: customFont,
                    color: rgb(0, 0, 0),
                    direction: 'rtl',
                });

                linesOnCurrentPage++;
                offsetY -= 5;

                if (linesOnCurrentPage > maxLinesPerPage) {
                    // Crée une nouvelle page si nécessaire
                    page = pdfDoc.addPage([210, 297]);
                    linesOnCurrentPage = 0;
                    offsetY = 265;
                    pageNumber++;
                    page.drawText(`Page ${pageNumber}`, {
                        x: 180,
                        y: 10,
                        size: 3,
                        font: customFont,
                        color: rgb(0, 0, 0),
                    });
                }

                for (const zoneKey in groupedByZone) {
                    if (groupedByZone.hasOwnProperty(zoneKey)) {
                        const zoneData = groupedByZone[zoneKey];
                        const groupedByBox = groupBy(zoneData, 'libelleBox');

                        for (const boxKey in groupedByBox) {
                            if (groupedByBox.hasOwnProperty(boxKey)) {
                                const boxData = groupedByBox[boxKey];
                                const groupedByEtagere = groupBy(boxData, 'libelleEtagere');

                                for (const etagereKey in groupedByEtagere) {
                                    if (groupedByEtagere.hasOwnProperty(etagereKey)) {
                                        const etagereData = groupedByEtagere[etagereKey];
                                        const groupedByLibelle = groupBy(etagereData, 'libelle');

                                        for (const libelleKey in groupedByLibelle) {
                                            if (groupedByLibelle.hasOwnProperty(libelleKey)) {
                                                const libelleData = groupedByLibelle[libelleKey];

                                                page.drawText(`${zoneKey}/${boxKey}/${etagereKey}/${libelleKey}`, {
                                                    x: 20,
                                                    y: offsetY,
                                                    size: 5,
                                                    font: customFont,
                                                    color: rgb(0, 0, 0),
                                                    wrap: 180,
                                                });

                                                linesOnCurrentPage++;
                                                offsetY -= 5;
                                                if (linesOnCurrentPage > maxLinesPerPage) {
                                                    // Crée une nouvelle page si nécessaire
                                                    page = pdfDoc.addPage([210, 297]);
                                                    linesOnCurrentPage = 0;
                                                    offsetY = 265;
                                                    pageNumber++;
                                                    page.drawText(`Page ${pageNumber}`, {
                                                        x: 180,
                                                        y: 10,
                                                        size: 3,
                                                        font: customFont,
                                                        color: rgb(0, 0, 0),
                                                    });
                                                }

                                                for (const colisItem of libelleData) {
                                                    // Ajoutez votre logique d'affichage ici, par exemple :
                                                    //console.log(`Client: ${clientName}, Zone: ${zoneKey}, Box: ${boxKey}, Etagere: ${etagereKey}, Libelle: ${libelleKey}, Tracking: ${colisItem.tracking}`);
                                                    //offsetY -= 5;
                                                    page.drawText(`${colisItem.tracking}`, {
                                                        x: 50,
                                                        y: offsetY,
                                                        size: 4,
                                                        font: customFont,
                                                        color: rgb(0, 0, 0),
                                                        wrap: 180,
                                                    });

                                                    linesOnCurrentPage++;
                                                    offsetY -= 5;
                                                    if (linesOnCurrentPage > maxLinesPerPage) {
                                                        // Crée une nouvelle page si nécessaire
                                                        page = pdfDoc.addPage([210, 297]);
                                                        linesOnCurrentPage = 0;
                                                        offsetY = 265;
                                                        pageNumber++;
                                                        page.drawText(`Page ${pageNumber}`, {
                                                            x: 180,
                                                            y: 10,
                                                            size: 3,
                                                            font: customFont,
                                                            color: rgb(0, 0, 0),
                                                        });
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        // Save the PDF to a new byte array
        const modifiedPdfBytes = await pdfDoc.save();

        // Create a Blob object from the PDF bytes
        const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });

        // Create a data URL for the Blob
        const pdfUrl = URL.createObjectURL(blob);

        // Open the PDF in a new window or tab
        window.open(pdfUrl, '_blank');
    };

    const renderPaginationItems = () => {
        const startPage = Math.max(1, pageNumber - 5); // Limiter le nombre de pages à afficher à 5 avant la page actuelle
        const endPage = Math.min(totalPages, pageNumber + 5); // Limiter le nombre de pages à afficher à 5 après la page actuelle

        return Array.from({ length: endPage - startPage + 1 }).map((_, index) => {
            const page = startPage + index;
            return (
                <Pagination.Item
                    key={page}
                    active={page === pageNumber}
                    onClick={() => handlePageChange(page)}
                >
                    {page}
                </Pagination.Item>
            );
        });
    };

    //Modifier colis
    const ModifierColis = (value, idColis, nom, tracking, idClientFromFile) => {
        try {
            setModifierColis(value);
            formik2.resetForm();
            if (value) {
                formik2.setValues((prevValues) => ({
                    ...prevValues,
                    idColis: idColis || '',
                    nom: nom || '',
                    tracking: tracking || '',
                    trackingOld: tracking || '',
                    idClientFromFile: idClientFromFile || '',
                }));
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    //Retirer colis
    const RetirerColis = (value, idColis) => {
        try {
            setRetirerColis(value);
            formik3.resetForm();
            if (value) {
                formik3.setValues((prevValues) => ({
                    ...prevValues,
                    idColis: idColis
                }));
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const formik2 = useFormik({
        initialValues: {
            idColis: '',
            nom: '',
            idClientFromFile: '',
            tracking: '',
            trackingOld: ''
        },
        validationSchema: Yup.object({
            nom: Yup.string()
                .required("Champ obligatoire"),
            idClientFromFile: Yup.string()
                .required("Champ obligatoire"),
            tracking: Yup.string()
                .required("Champ obligatoire"),
        }),
        onSubmit: async (values) => {
            try {
                //console.log(values);

                const postData = {
                    idColis: values.idColis,
                    nom: values.nom,
                    idClientFromFile: values.idClientFromFile,
                    tracking: values.tracking,
                    idUtilisateurCRUD: authData.idUtilisateur,
                };
                const response = await axiosInstance.put(`${Affectation}`, postData);

                CustomToast("Le colis a été modifié avec succès", 'success');
                setModifierColis(false);
                GetClients(pageNumber, pageSize);
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Tracking existe") {
                    CustomToast("Un autre colis existe avec le même tracking", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    const formik3 = useFormik({
        initialValues: {
            idColis: ''
        },
        onSubmit: async (values) => {
            try {
                //console.log(values);

                const postData = {
                    idColis: values.idColis,
                    idUtilisateurCRUD: authData.idUtilisateur,
                };
                const response = await axiosInstance.post(`${Affectation}/retirerColisManuellement`, postData);

                CustomToast("Le colis a été retiré avec succès", 'success');
                setRetirerColis(false);
                GetClients(pageNumber, pageSize);
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    // useEffect(() => {
    //     //Load Data
    //     GetClients(debouncedSearchText ? 1 : pageNumber, pageSize);

    //     return () => {
    //         // Perform any necessary cleanup (e.g., cancel pending requests)
    //     };
    // }, [authData, debouncedSearchText,statut, pageNumber, pageSize]);

    useEffect(() => {
        //Load Data
        GetClients(pageNumber, pageSize);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, pageNumber, pageSize]);

    useEffect(() => {
        //Load Data
        GetClients(1, pageSize);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, statut, debouncedSearchText]);

    return (
        <>
            <div className='row mt-1 mb-1'>
                <div className='col-xs-12 col-md-12'>
                    <div className="card">
                        <div className="card-header">Colis</div>
                        <div className="card-body">
                            <div className='row mb-3 justify-content-center align-items-center g-2'>
                                <div className={`${debouncedSearchText ? 'col-xs-12 col-md-6' : 'col-xs-12 col-md-7'}`}>
                                    <div className="search">
                                        <form className="form-inline">
                                            <input
                                                className="form-control mr-sm-2"
                                                type="search"
                                                placeholder="Search"
                                                aria-label="Rechercher"
                                                value={search}
                                                onChange={(e) => setSearch(e.target.value)}
                                                autoFocus={true}
                                                onKeyDown={handleKeyPress}
                                            />
                                        </form>
                                    </div>
                                </div>
                                <div className={`${debouncedSearchText ? 'col-xs-12 col-md-2' : 'col-xs-12 col-md-3'}`}>
                                    <div className="search">
                                        <form className="form-inline">
                                            <select
                                                id="statut"
                                                name="statut"
                                                className={`form-control`}
                                                value={statut}
                                                onChange={(e) => setStatut(e.target.value)}
                                                onKeyDown={handleKeyPress}
                                            >
                                                <option value="">Sélectionnez un statut</option>
                                                <option value="Affecté" defaultValue={true}>Affecté</option>
                                                <option value="Retourné au vendeur">Retourné au vendeur</option>
                                            </select>
                                        </form>
                                    </div>
                                </div>
                                {debouncedSearchText && <div className="col-xs-12 col-md-2">
                                    <div className="search">
                                        <form className="form-inline">
                                            <button className="btn btn-warning form-control" type="button" onClick={() => listeColis()}>
                                                <i className="fa fa-print fa-1x"></i>
                                            </button>
                                        </form>
                                    </div>
                                </div>}
                                {authData?.role !== "visiteur" && <div className="col-xs-12 col-md-2">
                                    <div className="search">
                                        <form className="form-inline">
                                            <button className="btn btn-success form-control" type="button" onClick={() => exportExcel()}>
                                                <i className="fa fa-file-excel-o fa-1x"></i>
                                            </button>
                                        </form>
                                    </div>
                                </div>}
                            </div>
                            <div className='row justify-content-center align-items-center g-2'>
                                <div className="col-xs-12 col-md-12">
                                    <div className="table-responsive">
                                        <table className="table table-hover table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Client</th>
                                                    <th>Emplacement (Zone / Box / Étagère / Boite)</th>
                                                    {authData?.role === "super admin" && <th>Centre</th>}
                                                    <th>Tracking</th>
                                                    <th>Statut</th>
                                                    <th>Date statut</th>
                                                    <th colSpan={3} className='text-center'>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {affectations.map((colis, index) => (
                                                    <tr key={index}>
                                                        <td>{colis.nom}</td>
                                                        <td>{colis.libelleZone + ' / ' + colis.libelleBox + ' / ' + colis.libelleEtagere + ' / ' + colis.libelle}</td>
                                                        {authData?.role === "super admin" && <td>{colis.libelleCentre}</td>}
                                                        <td>{colis.tracking}</td>
                                                        <td>{colis.lastStatut}</td>
                                                        <td>{format(colis.dateCRUD, "dd/MM/yyyy HH:mm")}</td>
                                                        <td className='text-center'>
                                                            <Link to="#" style={{ textDecoration: "none" }} onClick={() => ModifierColis(true, colis.idColis, colis.nom, colis.tracking, colis.idClientFromFile)}>
                                                                <span className="fa fa-pencil text-primary fa-lg"></span>
                                                            </Link>
                                                        </td>
                                                        <td className='text-center'>
                                                            <Link to="#" style={{ textDecoration: "none" }} onClick={() => createQRCodeLabel(colis.libelleZone, colis.libelleBox, colis.libelleEtagere, colis.libelle, colis.tracking)}>
                                                                <span className="fa fa-print text-warning fa-lg"></span>
                                                            </Link>
                                                        </td>
                                                        <td className='text-center' title='Retirer colis'>
                                                            {colis.lastStatut ==="Affecté" && <Link to="#" style={{ textDecoration: "none" }} onClick={() => RetirerColis(true, colis.idColis)}>
                                                                <span className="fa fa-home text-danger fa-lg"></span>
                                                            </Link>}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="d-flex justify-content-between mt-3">
                                        <div>
                                            {/* {totalPages >= 1 && (
                                            <Pagination className="pagination-sm">
                                                <Pagination.Prev
                                                    onClick={() => handlePageChange(pageNumber - 1)}
                                                    disabled={pageNumber === 1}
                                                >
                                                    <span aria-hidden="true">&laquo;</span>
                                                </Pagination.Prev>
                                                {Array.from({ length: totalPages }).map((_, index) => (
                                                    <Pagination.Item
                                                        key={index}
                                                        active={index + 1 === pageNumber}
                                                        onClick={() => handlePageChange(index + 1)}
                                                    >
                                                        {index + 1}
                                                    </Pagination.Item>
                                                ))}
                                                <Pagination.Next
                                                    onClick={() => handlePageChange(pageNumber + 1)}
                                                    disabled={pageNumber === totalPages}
                                                >
                                                    <span aria-hidden="true">&raquo;</span>
                                                </Pagination.Next>
                                            </Pagination>
                                        )} */}
                                            {totalPages >= 1 && (
                                                <Pagination className="pagination-sm">
                                                    <Pagination.Prev
                                                        onClick={() => handlePageChange(pageNumber - 1)}
                                                        disabled={pageNumber === 1}
                                                    >
                                                        <span aria-hidden="true">&laquo;</span>
                                                    </Pagination.Prev>
                                                    {renderPaginationItems()}
                                                    <Pagination.Next
                                                        onClick={() => handlePageChange(pageNumber + 1)}
                                                        disabled={pageNumber === totalPages}
                                                    >
                                                        <span aria-hidden="true">&raquo;</span>
                                                    </Pagination.Next>
                                                </Pagination>
                                            )}
                                        </div>

                                        <div className="mb-3">
                                            <p>
                                                Affichage de {getStartIndex()} à {getEndIndex()} sur {totalItems} entrées
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {modifierColis && <><div className="modal" id="modifierColis" style={{ display: 'block' }} data-bs-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true" tabIndex={-1}>
                <form onSubmit={formik2.handleSubmit}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Modifier un colis</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => ModifierColis(false, '', '', '', '')}></button>
                            </div>

                            <div className="modal-body">
                                <div className='row mt-0 mb-0'>
                                    <div className="col">
                                        <label className="form-label">Boutique<span className='text-danger fs-6'>*</span></label>
                                        <input
                                            type="nom"
                                            name="nom"
                                            id="nom"
                                            className={`form-control ${formik2.touched.nom && formik2.errors?.nom ? 'border-danger' : ''}`}
                                            placeholder=""
                                            value={formik2.values.nom}
                                            onChange={formik2.handleChange}
                                        />
                                        {formik2.touched.nom && formik2.errors.nom ? (
                                            <small className="text-danger">{formik2.errors.nom}</small>
                                        ) : null}
                                    </div>
                                    <div className="col">
                                        <label className="form-label">Id boutique<span className='text-danger fs-6'>*</span></label>
                                        <input
                                            type="text"
                                            name="idClientFromFile"
                                            id="idClientFromFile"
                                            className={`form-control ${formik2.touched.idClientFromFile && formik2.errors?.idClientFromFile ? 'border-danger' : ''}`}
                                            placeholder=""
                                            value={formik2.values.idClientFromFile}
                                            onChange={formik2.handleChange}
                                        />
                                        {formik2.touched.idClientFromFile && formik2.errors.idClientFromFile ? (
                                            <small className="text-danger">{formik2.errors.idClientFromFile}</small>
                                        ) : null}
                                    </div>
                                    <div className="col">
                                        <label className="form-label">Tracking<span className='text-danger fs-6'>*</span></label>
                                        <input
                                            type="text"
                                            name="tracking"
                                            id="tracking"
                                            className={`form-control ${formik2.touched.tracking && formik2.errors?.tracking ? 'border-danger' : ''}`}
                                            placeholder=""
                                            value={formik2.values.tracking}
                                            onChange={formik2.handleChange}
                                        />
                                        {formik2.touched.tracking && formik2.errors.tracking ? (
                                            <small className="text-danger">{formik2.errors.tracking}</small>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => ModifierColis(false, '', '', '', '')}>Annuler</button>
                                <button type="submit" className="btn btn-success" data-bs-dismiss="modal">Enregistrer</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
                <div className="modal-backdrop show"></div></>
            }

            {retirerColis && <><div className="modal" id="retirerColis" style={{ display: 'block' }} data-bs-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true" tabIndex={-1}>
                <form onSubmit={formik3.handleSubmit}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Retirer un colis</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => RetirerColis(false, '')}></button>
                            </div>

                            <div className="modal-body">
                                <div className='row mt-0 mb-0'>
                                    <div className="col">
                                    Voulez-vous vraiment retirer ce colis ?
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => RetirerColis(false, '')}>Annuler</button>
                                <button type="submit" className="btn btn-success" data-bs-dismiss="modal">Enregistrer</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
                <div className="modal-backdrop show"></div></>
            }
            
        </>
    );
}

export default Historique;
