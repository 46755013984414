import React, { useEffect, useState, useRef, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { format } from 'date-fns'
import { CustomToast } from '../../../components/Toast/CustomToast';
import { ExportToExcel } from '../../../components/ExportToExcel/ExportToExcel';
import useDebounce from '../../../hooks/useDebounce';
import { axiosInstance, UserAuthentication, Centre } from '../../../services/api';
import { Pagination } from 'react-bootstrap';
import { AuthContext } from '../../../contexts/AuthContext';
const Centres = () => {
    const { authData } = useContext(AuthContext);

    const [centres, setCentres] = useState([]);
    const [filtredCentres, setFiltredCentres] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(15);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState('');
    const debouncedSearchText = useDebounce(search, 300);


    //Table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredCentres].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredCentres(sortedTable);
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
    };

    const getStartIndex = () => {
        return (pageNumber - 1) * pageSize + 1;
    };

    const getEndIndex = () => {
        const endIndex = pageNumber * pageSize;
        return endIndex > totalItems ? totalItems : endIndex;
    };


    const SupprimerCentre = async (idCentre) => {
        try {
            await axiosInstance.delete(`${Centre}/${idCentre}`);
            CustomToast("Le centre a été supprimé avec succès", 'success');
            getCentres(1, pageSize);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }

            console.log(error);
        }
    }


    const getCentres = async (page, newPerPage) => {
        try {
            setLoading(true);

            const queryParams = new URLSearchParams({
                pageNumber: page,
                pageSize: newPerPage
            });

            if (debouncedSearchText != null) {
                queryParams.append("text", debouncedSearchText);
            }

            const response = await axiosInstance.get(`${Centre}/GetCentres?${queryParams}`);
            const resp = await response.data;
            setCentres(resp.data);
            setFiltredCentres(resp.data);
            setPageNumber(resp.pageNumber);
            setPageSize(resp.pageSize);
            setTotalItems(resp.totalItems);
            setTotalPages(resp.totalPages);
            setLoading(false);
        }
        catch (err) {
            console.log(err);
            setLoading(false);
        }
    }

    const GetCentresFromApi = async () => {
        try {
            const response = await axiosInstance.get(`${Centre}/GetCentresFromApi/${authData.idUtilisateur}`);
           // console.log(response)
            if(response?.status === 200 && response?.data === "New data"){
                getCentres(1, pageSize)
            }    
            CustomToast("Les centres ont été importés avec succès", 'success');     
        }
        catch (err) {
            console.log(err);
        }
    }

     //Exporter la lise des agences
     const exportExcel = async () => {
        try {
            const queryParams = new URLSearchParams({
            });

            if (debouncedSearchText != null) {
                queryParams.append("text", debouncedSearchText);
            }

            const response = await axiosInstance.get(`${Centre}/GetCentresExcel?${queryParams}`);
            const resp = await response.data;

            const Heading = [
                [
                    "Hub id",
                    "Nom",
                    "Manager",
                    "Adresse",
                    "Wilaya",
                    "Commune"
                ]
            ];

            let fileName = "Centres-" + format(new Date(), "yyyyMMddHHmmss");
            const fileExtension = ".xlsx";
            const blob = ExportToExcel(resp, fileName, fileExtension, "Centres", Heading);

            // Create a URL for the Blob
            const url = window.URL.createObjectURL(blob);

            CustomToast(<><strong>Terminé : Exportation xlsx de liste des centres</strong><br></br><a href={url} download={fileName + fileExtension}>Cliquez ici si le téléchargement ne démarre pas</a></>, 'success', 60000);

            // Clean up the URL object after the download
            //window.URL.revokeObjectURL(url);         

        }
        catch (error) {
            console.log(error);
        }
    }

    // useEffect(() => {
    //     //Load Data

    //     getCentres(pageNumber, pageSize);
    //     return () => {
    //         // Perform any necessary cleanup (e.g., cancel pending requests)
    //     };
    // }, [authData,pageNumber, pageSize]);

    // useEffect(() => {
    //     //Load Data

    //     getCentres(1, pageSize);
    //     return () => {
    //         // Perform any necessary cleanup (e.g., cancel pending requests)
    //     };
    // }, [debouncedSearchText]);

    useEffect(() => {
        //Load Data
        getCentres(debouncedSearchText ? 1 : pageNumber, pageSize);
        
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, debouncedSearchText, pageNumber, pageSize]);
    return (
        <>
            <div className='row mt-1 mb-1'>
                <div className='col-xs-12 col-md-12'>
                    <div className="card">
                        <div className="card-body">
                            <div className='row justify-content-center align-items-center'>
                            {authData?.role !== "visiteur" && <div className='col-xs-2 col-md-2'>
                                    <Link to="/utilisateurs" className="btn btn-light form-control" replace>
                                        <i className="fa fa-users"> Utilisateurs</i>
                                    </Link>
                                </div>}
                                <div className='col-xs-2 col-md-2'>
                                    <Link to="/accueil" className="btn btn-light form-control" replace>
                                        <i className="fa fa-home"> Centres</i>
                                    </Link>
                                </div>
                                <div className='col-xs-2 col-md-2'>
                                    <Link to="/zones" className="btn btn-light form-control" replace>
                                        <i className="fa fa-bullseye"> Zones</i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row mt-1 mb-1'>
                <div className='col-xs-12 col-md-12'>
                    <div className="card">
                        <div className="card-header">Centres</div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-xs-2 col-md-2 mt-5 mb-4 text-gred">
                                    <div className="search">
                                        <form className="form-inline">
                                            <input
                                                className="form-control mr-sm-2"
                                                type="search" placeholder="Search"
                                                aria-label="Rechercher"
                                                value={search}
                                                onChange={(e) => setSearch(e.target.value)}
                                            />
                                        </form>
                                    </div>
                                </div>
                                <div className="col-xs-1 col-md-1 offset-xs-7 offset-md-7 mt-5 mb-4 text-gred">
                                    <button className="btn btn-success form-control" type="button" onClick={() => GetCentresFromApi()}>
                                        <i className="fa fa-upload"></i>
                                    </button>
                                </div>
                                <div className="col-xs-1 col-md-1 mt-5 mb-4 text-gred">
                                    <div className="search">
                                        <form className="form-inline">
                                            <button className="btn btn-success form-control" type="button" onClick={() => exportExcel()}>
                                                <i className="fa fa-file-excel-o fa-1x"></i>
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className='row justify-content-center align-items-center g-2'>
                                <div className="col-xs-12 col-md-12">
                                <div className="table-responsive">
                                    <table className="table table-hover table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Hub id</th>
                                                <th>Nom</th>
                                                <th>Manager</th>
                                                <th>Adresse</th>
                                                <th>Wilaya</th>
                                                <th>Commune</th>
                                                {/* <th colSpan={2} className='text-center'>Actions</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {centres.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        {item.hub_id}
                                                    </td>
                                                    <td>
                                                        {item.libelle}
                                                    </td>
                                                    <td>
                                                        {item.manager}
                                                    </td>
                                                    <td>
                                                        {item.adresse}
                                                    </td>
                                                    <td>
                                                        {item.wilaya_name}
                                                    </td>
                                                    <td>
                                                        {item.commune_name}
                                                    </td>
                                                    {/* <td className='text-center'>
                                                        <Link to={`/modifiercentre/${item.idCentre}`} style={{ textDecoration: "none" }}>
                                                            <span className="fa fa-pencil text-warning fa-lg"></span>
                                                        </Link>
                                                    </td>
                                                    <td className='text-center'>
                                                    <Link to="#" style={{ textDecoration: "none" }} onClick={() => SupprimerCentre(item.idCentre)}>
                                                            <span className="fa fa-trash text-danger fa-lg"></span>
                                                        </Link>
                                                    </td> */}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    </div>
                                    <div className="d-flex justify-content-between mt-3">
                                        <div>
                                            {totalPages >= 1 && (
                                                <Pagination className="pagination-sm">
                                                    <Pagination.Prev
                                                        onClick={() => handlePageChange(pageNumber - 1)}
                                                        disabled={pageNumber === 1}
                                                    >
                                                        <span aria-hidden="true">&laquo;</span>
                                                    </Pagination.Prev>
                                                    {Array.from({ length: totalPages }).map((_, index) => (
                                                        <Pagination.Item
                                                            key={index}
                                                            active={index + 1 === pageNumber}
                                                            onClick={() => handlePageChange(index + 1)}
                                                        >
                                                            {index + 1}
                                                        </Pagination.Item>
                                                    ))}
                                                    <Pagination.Next
                                                        onClick={() => handlePageChange(pageNumber + 1)}
                                                        disabled={pageNumber === totalPages}
                                                    >
                                                        <span aria-hidden="true">&raquo;</span>
                                                    </Pagination.Next>
                                                </Pagination>
                                            )}
                                        </div>

                                        <div className="mb-3">
                                            <p>
                                                Affichage de {getStartIndex()} à {getEndIndex()} sur {totalItems} entrées
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Centres;
