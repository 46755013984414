import React, { useEffect, useState, useContext } from 'react';
import { CustomToast } from '../../components/Toast/CustomToast';
import useDebounce from '../../hooks/useDebounce';
import { axiosInstance, Affectation } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';

const Audit = () => {
    const { authData } = useContext(AuthContext);

    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState('');
    const [searchTracking, setSearchTracking] = useState('');
    const debouncedSearchText = useDebounce(search, 300);
    const debouncedSearchTracking = useDebounce(searchTracking, 300);
    const [colis, setColis] = useState([]);
    const [colisPass, setColisPass] = useState([]);
    const [colisFailed, setColisFailed] = useState([]);
    // const [showVerification, setShowVerification] = useState(false);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Annuler le comportement par défaut de la touche "Entrée"
        }
    };

    const GetColis = async () => {
        try {
            setLoading(true);

            const queryParams = new URLSearchParams({
            });

            if (debouncedSearchText != null && debouncedSearchText !== "") {
                queryParams.append("text", debouncedSearchText);
            } else { setColis([]); setColisPass([]); setColisFailed([]); return }

            if (authData?.idCentre != null && authData?.idCentre !== "") {
                queryParams.append("idCentre", authData.idCentre);
            } else { setColis([]); setColisPass([]); setColisFailed([]); return }

            const response = await axiosInstance.get(`${Affectation}/Audit?${queryParams}`);
            const resp = await response.data;
            setColis(resp.data);
            setLoading(false);
            setColisPass([]);
            setColisFailed([]);
        }
        catch (error) {
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "qr code incorrecte") {
                CustomToast("Emplacement introuvale", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
            setLoading(false);
            setColisPass([]);
            setColisFailed([]);
        }
    }

    const handleTextTrackingChange = (e) => {
        setSearchTracking(e.target.value);
        handleTrackingSubmit(e.target.value)
    };

    const handleTrackingSubmit = (value) => {
        // Vérifiez si le tracking existe dans colis
        // Vérifiez si le tracking existe dans colis
        const foundColis = colis.find(colisItem => colisItem.tracking === value);

        // Vérifiez si le tracking existe déjà dans colisPass
        const isAlreadyInColisPass = colisPass.some(passedItem => passedItem.tracking === value);

        // Vérifiez si le tracking existe déjà dans colisFailed
        const isAlreadyInColisFailed = colisFailed.some(failedItem => failedItem.tracking === value);

        // Copiez les tableaux existants pour les modifier
        const newColisPass = [...colisPass];
        const newColisFailed = [...colisFailed];

        if (foundColis) {
            // Ajoutez à colisPass si le tracking existe et n'est pas déjà dans colisPass
            if (!isAlreadyInColisPass) {
                newColisPass.push(foundColis);
                CustomToast("Tracking " + value + " existe", 'success');
            } else {
                CustomToast("Tracking " + value + " existe", 'success');
            }
        } else {
            // Ajoutez à colisFailed si le tracking n'existe pas et n'est pas déjà dans colisFailed
            if (!isAlreadyInColisFailed) {
                newColisFailed.push({ tracking: value });
                CustomToast("Tracking " + value + " n'existe pas", 'error');
            } else {
                CustomToast("Tracking " + value + " n'existe pas", 'error');
            }
        }

        // Mettez à jour les états avec les nouveaux tableaux
        setColisPass(newColisPass);
        setColisFailed(newColisFailed);

        // Réinitialisez le champ de suivi
        setSearchTracking('');
    };

    useEffect(() => {
        //Load Data
        GetColis();

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, debouncedSearchText]);



    return (
        <>
            <div className='row mt-1 mb-1'>
                <div className='col-xs-12 col-md-12'>
                    <div className="card">
                        <div className="card-header">Audit</div>
                        <div className="card-body">
                            <div className='row mt-1 mb-1'>
                                <div className='col-xs-12 col-md-6 mb-1'>
                                    <div className="card">
                                        <div className="card-header">Boite</div>
                                        <div className="card-body">
                                            <div className='row mb-3 justify-content-center align-items-center g-2'>
                                                <div className="col-xs-12 col-md-12">
                                                    <div className="search">
                                                        <form className="form-inline">
                                                            <div className="input-group mb-3">
                                                                <input
                                                                    className="form-control mr-sm-2"
                                                                    type="search"
                                                                    placeholder="Qr code boite"
                                                                    aria-label="Rechercher"
                                                                    value={search}
                                                                    onChange={(e) => setSearch(e.target.value)}
                                                                    autoFocus={true}
                                                                    onKeyDown={handleKeyPress}
                                                                />
                                                                <span className="input-group-text">
                                                                    <i className="fa fa-qrcode fa-2x" aria-hidden="true"></i>
                                                                </span>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            {colis?.length > 0 && <div className='row mt-1'>
                                                <div className="col-xs-12 col-md-12">
                                                    <div className="table-responsive">
                                                        <table className="table table-hover table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th>Boutique</th>
                                                                    <th>Tracking</th>
                                                                    {/* <th>Statut</th>
                                                                <th>Date statut</th> */}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {colis.map((row, index) => (
                                                                    <tr key={index}>
                                                                        <td>{row.nom}</td>
                                                                        <td>{row.tracking}</td>
                                                                        {/* <td>{row.lastStatut}</td>
                                                                    <td>{format(row.dateCRUD, "dd/MM/yyyy HH:mm")}</td> */}
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xs-12 col-md-6'>
                                    <div className="card">
                                        <div className="card-header">Colis</div>
                                        <div className="card-body">
                                            <div className='row mb-3 justify-content-center align-items-center g-2'>
                                                <div className="col-xs-12 col-md-12">
                                                    <div className="search">
                                                        <form className="form-inline">
                                                            <div className="input-group mb-3">
                                                                <input
                                                                    className="form-control mr-sm-2"
                                                                    //ref={serchTrackingRef}
                                                                    id="tracking"
                                                                    name="tracking"
                                                                    type="search"
                                                                    placeholder="Tracking"
                                                                    aria-label="Rechercher"
                                                                    value={searchTracking}
                                                                    onChange={(e) => handleTextTrackingChange(e)}
                                                                    //autoFocus={true}
                                                                    onKeyDown={handleKeyPress}
                                                                    disabled={colis?.length === 0}
                                                                />
                                                                <span className="input-group-text">
                                                                    <i className="fa fa-barcode fa-2x" aria-hidden="true"></i>
                                                                </span>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            {(colis?.length > 0 && (colisPass?.length > 0 || colisFailed?.length > 0)) && <div className='row mt-1'>
                                                <div className="col-xs-12 col-md-6">
                                                    <div className="table-responsive">
                                                        <table className="table table-hover table-bordered table-success">
                                                            <thead>
                                                                <tr>
                                                                    <th>Boutique</th>
                                                                    <th>Tracking</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {colisPass.map((row, index) => (
                                                                    <tr key={index}>
                                                                        <td>{row.nom}</td>
                                                                        <td>{row.tracking}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="col-xs-12 col-md-6">
                                                    <div className="table-responsive">
                                                        <table className="table table-hover table-bordered table-danger">
                                                            <thead>
                                                                <tr>
                                                                    <th>Boutique</th>
                                                                    <th>Tracking</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {colisFailed.map((row, index) => (
                                                                    <tr key={index}>
                                                                        <td>{row.nom}</td>
                                                                        <td>{row.tracking}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Audit;
