import React from 'react';
import DOMPurify from 'dompurify';

const ConfirmationDialog = ({ open, onClose, onConfirm, titleMessage, bodyMessage, btnCancelText, btnConfirmText }) => {

    if (!open) return null;

    return (
        <><div className="modal" id="dialog" style={{ display: 'block' }} data-bs-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true" tabIndex={-1}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">{titleMessage}</h4>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={onClose}></button>
                    </div>

                    <div className="modal-body">
                        <div className='row mt-0 mb-0'>
                            <div className="col" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(bodyMessage) }}>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="submit" className="btn btn-danger" data-bs-dismiss="modal" onClick={onClose}>{btnCancelText}</button>
                        <button type="submit" className="btn btn-success" data-bs-dismiss="modal" onClick={onConfirm} >{btnConfirmText}</button>
                    </div>
                </div>
            </div>
        </div>
            <div className="modal-backdrop show"></div></>
    );
}

export default ConfirmationDialog;
